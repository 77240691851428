import {
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonSegment,
  IonSegmentButton,
  IonSkeletonText,
  IonText,
  IonThumbnail,
} from '@ionic/react'
import type React from 'react'
import { useReportControllerWorkVsBreakInsight } from '@/api'
import { Duration } from 'luxon'

export const TodayStatistics: React.FC<{
  filter: { from: string; to: string }
}> = ({ filter }) => {
  // @ts-expect-error
  const { data: insight } = useReportControllerWorkVsBreakInsight(filter)

  if (insight === undefined) {
    return (
      <IonList>
        <IonListHeader>
          <IonSkeletonText
            animated={true}
            style={{ width: '80px' }}
          ></IonSkeletonText>
        </IonListHeader>
        <IonItem>
          <IonThumbnail slot="start">
            <IonSkeletonText animated={true}></IonSkeletonText>
          </IonThumbnail>
          <IonLabel>
            <h3>
              <IonSkeletonText
                animated={true}
                style={{ width: '80%' }}
              ></IonSkeletonText>
            </h3>
            <p>
              <IonSkeletonText
                animated={true}
                style={{ width: '60%' }}
              ></IonSkeletonText>
            </p>
            <p>
              <IonSkeletonText
                animated={true}
                style={{ width: '30%' }}
              ></IonSkeletonText>
            </p>
          </IonLabel>
        </IonItem>
      </IonList>
    )
  }
  return (
    <>
      <IonSegment className={'segment-display'} disabled={true}>
        <IonSegmentButton>
          <h2>
            <IonText>
              {Duration.fromObject(insight.totalDuration ?? {})
                .shiftToAll()
                .toFormat('hh:mm:ss')}
            </IonText>
          </h2>
          <p>
            <IonText>Day Total</IonText>
          </p>
        </IonSegmentButton>
        <IonSegmentButton>
          <h2>
            <IonText color={'custom-01'}>
              {Duration.fromObject(insight.workDuration ?? {})
                .shiftToAll()
                .toFormat('hh:mm:ss')}
            </IonText>
          </h2>
          <p>
            <IonText color={'custom-01'}>Work Total</IonText>
          </p>
        </IonSegmentButton>
        <IonSegmentButton>
          <h2>
            <IonText color={'custom-02'}>
              {Duration.fromObject(insight.breakDuration ?? {})
                .shiftToAll()
                .toFormat('hh:mm:ss')}
            </IonText>
          </h2>
          <p>
            <IonText color={'custom-02'}>Break Total</IonText>
          </p>
        </IonSegmentButton>
      </IonSegment>
    </>
  )
}
