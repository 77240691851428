import type React from 'react'
import {
  IonCol,
  IonContent,
  IonGrid,
  IonImg,
  IonPage,
  IonRow,
} from '@ionic/react'

export const NotFound: React.FC = () => {
  return (
    <IonPage>
      <IonContent scrollY={false} scrollX={false}>
        <IonGrid className="ion-padding">
          <IonRow className="ion-justify-content-center ion-align-items-center">
            <IonCol size="auto">
              <IonImg
                src="assets/img/trium-novem-logo.svg"
                style={{ width: 187, height: 89 }}
              />
            </IonCol>
          </IonRow>
          <IonRow className="ion-justify-content-center ion-align-items-center">
            <IonCol size="auto">Page Not found</IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  )
}
