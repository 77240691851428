import { WorkType } from '@/api'

const workOptions = {
  [WorkType.home_work]: {
    description: 'Home Office',
    title: 'Home Office',
    value: WorkType.home_work,
  },
  [WorkType.office_work]: {
    description: 'Main Office',
    title: 'Main Office',
    value: WorkType.office_work,
  },
  [WorkType.site_work]: {
    description: 'Project Site',
    title: 'Project Site',
    value: WorkType.site_work,
  },
  [WorkType.transit]: {
    description: 'In Transit',
    title: 'In Transit',
    value: WorkType.transit,
  },
}

export function useWorkOptions() {
  const findWorkOption = (workType: WorkType = WorkType.home_work) => {
    return workOptions[workType]
  }
  return { workOptions: Object.values(workOptions), findWorkOption }
}
