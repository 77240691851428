import { useMemo, useState } from 'react'
import type { CheckboxCustomEvent, RadioGroupCustomEvent } from '@ionic/react'
import {
  IonButton,
  IonButtons,
  IonCheckbox,
  IonContent,
  IonHeader,
  IonItem,
  IonList,
  IonRadio,
  IonRadioGroup,
  IonSearchbar,
  IonTitle,
  IonToolbar,
} from '@ionic/react'
import type { TypeheadItem } from '@/contracts/typehead-item'
import type { RadioGroupCompareFn } from '@ionic/core'

interface TypeaheadProps<T extends boolean = false, K = any> {
  isMulti?: T
  items: TypeheadItem<K>[]
  selectedItems?: T extends true ? Array<K> : K
  title?: string
  compareWith: K extends string | number | boolean
    ? string | RadioGroupCompareFn | null
    : RadioGroupCompareFn
  onSelectionCancel?: () => void
  onSelectionChange?: (items: T extends true ? Array<K> : K) => void
}

function AppTypeahead<T extends boolean = false, K = any>(
  props: TypeaheadProps<T, K>
) {
  const [filteredItems, setFilteredItems] = useState<TypeheadItem[]>([
    ...props.items,
  ])
  let data: K[] = []
  if (props.isMulti && Array.isArray(props.selectedItems)) {
    data = props.selectedItems
  } else if (props.selectedItems && !Array.isArray(props.selectedItems)) {
    data = [props.selectedItems as K]
  }

  const [workingSelectedValues, setWorkingSelectedValues] = useState<K[]>(data)

  const isChecked = (value: string) => {
    if (props.isMulti && Array.isArray(workingSelectedValues)) {
      return workingSelectedValues?.find((item) => item === value) !== undefined
    }
    return false
  }

  const cancelChanges = () => {
    const { onSelectionCancel } = props
    if (onSelectionCancel !== undefined) {
      onSelectionCancel()
    }
  }

  const confirmChanges = () => {
    const { onSelectionChange } = props
    if (onSelectionChange !== undefined) {
      if (props.isMulti) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        onSelectionChange(workingSelectedValues)
      } else if (workingSelectedValues.length > 0) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        onSelectionChange(workingSelectedValues[0])
      }
    }
  }

  const searchbarInput = (ev: any) => {
    filterList(ev.target.value)
  }

  /**
   * Update the rendered view with
   * the provided search query. If no
   * query is provided, all data
   * will be rendered.
   */
  const filterList = (searchQuery: string | null | undefined) => {
    /**
     * If no search query is defined,
     * return all options.
     */
    if (searchQuery === undefined || searchQuery === null) {
      setFilteredItems([...props.items])
    } else {
      /**
       * Otherwise, normalize the search
       * query and check to see which items
       * contain the search query as a substring.
       */
      const normalizedQuery = searchQuery.toLowerCase()
      setFilteredItems(
        props.items.filter((item) => {
          return item.title.toLowerCase().includes(normalizedQuery)
        })
      )
    }
  }

  const checkboxChange = (ev: CheckboxCustomEvent) => {
    const { checked, value } = ev.detail

    if (props.isMulti) {
      if (checked) {
        setWorkingSelectedValues((prevValue) => [...prevValue, value])
      } else {
        setWorkingSelectedValues(
          workingSelectedValues.filter((item) => item !== value)
        )
      }
    } else {
      setWorkingSelectedValues([value])
    }
  }

  const radioChange = (ev: RadioGroupCustomEvent) => {
    const { value } = ev.detail
    if (!props.isMulti) {
      setWorkingSelectedValues([value])
    }
  }
  const selectItems = useMemo(() => {
    if (props.isMulti) {
      return filteredItems.map((item) => (
        <IonItem key={item.title}>
          <IonCheckbox
            value={item.value}
            checked={isChecked(item.value)}
            onIonChange={checkboxChange}
          >
            {item.title}
          </IonCheckbox>
        </IonItem>
      ))
    }
    return (
      <IonRadioGroup
        compareWith={props.compareWith}
        value={workingSelectedValues[0] ?? null}
        onIonChange={radioChange}
      >
        {filteredItems.map((item) => (
          <IonItem key={item.title}>
            <IonRadio value={item.value}>{item.title}</IonRadio>
          </IonItem>
        ))}
      </IonRadioGroup>
    )
  }, [filteredItems, props.isMulti, workingSelectedValues])
  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={cancelChanges}>Cancel</IonButton>
          </IonButtons>
          <IonTitle>{props.title}</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={confirmChanges}>Done</IonButton>
          </IonButtons>
        </IonToolbar>
        <IonToolbar>
          <IonSearchbar onIonInput={searchbarInput}></IonSearchbar>
        </IonToolbar>
      </IonHeader>

      <IonContent color="light" class="ion-padding">
        <IonList id="modal-list" inset={true}>
          {selectItems}
        </IonList>
      </IonContent>
    </>
  )
}

export default AppTypeahead
