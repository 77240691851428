import { useCallback, useEffect, useState } from 'react'
import { usePreference } from '@mortezakarimi/preference-react'

export function useStorage<T>(key: string, initialValue: T): [T, any] {
  const { get, set } = usePreference()
  const [state, setState] = useState<T>(initialValue)
  const loadData = useCallback(async () => {
    const storedValue = await get(key)
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    setState(storedValue !== null ? JSON.parse(storedValue) : null)
  }, [get])

  useEffect(() => {
    void loadData()
  }, [key])

  const updateState = useCallback(
    async (newValue: T) => {
      setState(newValue)
      await set(key, JSON.stringify(newValue))
    },
    [set]
  )

  return [state, updateState]
}
