import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
} from '@ionic/react'
import type { ChartData, ChartOptions } from 'chart.js'
import {
  Chart as ChartJS,
  Legend,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  Tooltip,
} from 'chart.js'
import { useReportControllerGetWorkHistory } from '@/api'
import { DateTime, Interval } from 'luxon'
import type React from 'react'
import { useMemo } from 'react'
import { Scatter } from 'react-chartjs-2'

ChartJS.register(
  LinearScale,
  CategoryScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend
)

const options: ChartOptions<'scatter'> = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: 'top' as const,
    },
    title: {
      display: true,
      text: 'Daily Average Start and End Times',
    },
    tooltip: {
      callbacks: {
        label: function (context) {
          return DateTime.fromMillis(+context.parsed.y).toFormat('H:mm:ss')
        },
      },
    },
  },
  scales: {
    x: {
      type: 'category',
    },
    y: {
      alignToPixels: true,
      min: DateTime.fromSQL('1970-01-01 00:00:00').toMillis(),
      max: DateTime.fromSQL('1970-01-02 00:00:00').toMillis(),
      beginAtZero: true,
      ticks: {
        stepSize: 3600 * 1000,
        callback: (v) => {
          return DateTime.fromMillis(+v).toFormat('H:mm:ss')
        },
      },
    },
  },
}
export const ClockInClockOutDistribution: React.FC<{
  filter: { from: string; to: string }
  selectedMode: 'start-time' | 'end-time'
}> = ({ filter, selectedMode }) => {
  // @ts-ignore
  const { data } = useReportControllerGetWorkHistory(filter)

  const chart = useMemo((): ChartData<
    'scatter',
    Array<{ x: string; y: number }>,
    unknown
  > => {
    if (!data) {
      return { labels: [], datasets: [] }
    }
    const xLabels = Interval.fromDateTimes(
      DateTime.fromISO(filter.from),
      DateTime.fromISO(filter.to).plus({ day: 1 })
    )
      .splitBy({ day: 1 })
      .map((d) => d.start?.toISODate() ?? '')

    const chart: ChartData<
      'scatter',
      Array<{ x: string; y: number }>,
      unknown
    > = {
      labels: xLabels,
      datasets: [],
    }
    if (selectedMode === 'start-time') {
      chart.datasets.push({
        label: 'Start time',
        data: xLabels.map((x) => {
          const workRecord = data.find(
            (i) => DateTime.fromJSDate(i.date).toISODate() == x
          )
          if (!workRecord) {
            return { x, y: NaN }
          }
          return {
            x,
            y: DateTime.fromJSDate(workRecord.startAt)
              .set({ year: 1970, month: 1, day: 1 })
              .toLocal()
              .toMillis(),
          }
        }),
        borderColor: 'rgb(12, 253, 141)',
        backgroundColor: 'rgba(12, 253, 141, 0.8)',
      })
      return chart
    }
    chart.datasets.push({
      label: 'End time',
      data: xLabels.map((x) => {
        const workRecord = data.find(
          (i) => DateTime.fromJSDate(i.date).toISODate() == x
        )
        if (!workRecord || workRecord.endAt === null) {
          return { x, y: NaN }
        }
        return {
          x,
          y: DateTime.fromJSDate(workRecord.endAt)
            .set({ year: 1970, month: 1, day: 1 })
            .toLocal()
            .toMillis(),
        }
      }),
      borderColor: 'rgb(250, 198, 2)',
      backgroundColor: 'rgba(250, 198, 2, 0.8)',
    })
    return chart
  }, [data])
  if (data === undefined) {
    return
  }
  return (
    <IonCard style={{ width: '100%', height: '100%' }}>
      <IonCardHeader>
        <IonCardTitle>
          {selectedMode === 'start-time' ? 'Login' : 'Logout'} Trends
        </IonCardTitle>
      </IonCardHeader>
      <IonCardContent style={{ height: 300 }}>
        {data.length > 0 && (
          <Scatter options={options} data={chart} height="100%" />
        )}
        {data.length === 0 && (
          <div style={{ textAlign: 'center', minHeight: '200px' }}>
            Nothing to show
          </div>
        )}
      </IonCardContent>
    </IonCard>
  )
}
