import type React from 'react'
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonModal,
  IonText,
  IonTitle,
  IonToolbar,
} from '@ionic/react'

import './profile.module.css'
import { type ResetPasswordDto } from '@/api'

// eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
type resetPasswordForm = ResetPasswordDto & { confirmPassword: string }

type ProfileChangePasswordProps = {
  triggerId: string
  modalRef: React.MutableRefObject<any>
}

const ProfileChangePassword: React.FC<ProfileChangePasswordProps> = ({
  triggerId,
  modalRef,
}) => {
  /** @see https://ionicframework.com/docs/api/modal#inline-modals-recommended */
  const confirm = () => {
    // form submission logic here
  }
  return (
    <IonModal ref={modalRef} trigger={triggerId}>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot={'start'}>
            <IonButton
              onClick={() => modalRef.current?.dismiss()}
              color={'primary'}
              shape={'round'}
              fill={'outline'}
            >
              Cancel
            </IonButton>
          </IonButtons>
          <IonTitle size={'small'} className={'ion-text-center'}>
            Change Password
          </IonTitle>
          <IonButtons slot={'end'}>
            <IonButton
              onClick={confirm}
              color={'primary'}
              fill={'solid'}
              shape={'round'}
            >
              Confirm
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent scrollY={false} className={'ion-padding-horizontal'}>
        <IonText color="primary">
          <h1>Work in progress</h1>
        </IonText>
      </IonContent>
    </IonModal>
  )
}

export default ProfileChangePassword
