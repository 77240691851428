import type React from 'react'
import { Redirect, Route } from 'react-router-dom'
import { IonApp, IonRouterOutlet, setupIonicReact } from '@ionic/react'
import { IonReactRouter } from '@ionic/react-router'
import { createBrowserHistory } from 'history'

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css'

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css'
import '@ionic/react/css/structure.css'
import '@ionic/react/css/typography.css'

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css'
import '@ionic/react/css/float-elements.css'
import '@ionic/react/css/text-alignment.css'
import '@ionic/react/css/text-transformation.css'
import '@ionic/react/css/flex-utils.css'
import '@ionic/react/css/display.css'
import 'swiper/css'
import '@ionic/react/css/ionic-swiper.css'

/* Theme variables */
import './theme/variables.css'

/* Custom theme */
import './main.css'

import Login from './components/login'
import { AuthProvider, useIsAuthenticated } from './contexts/storage.context'

import * as Sentry from '@sentry/capacitor'
import * as SentryReact from '@sentry/react'
import { AppLoading } from '@/components/app-loading'
import { NotFound } from '@/components/not-found'
import { Tabs } from '@/components/tabs'
import { SetPassword } from '@/components/set-password'
import AppUrlListener from '@/AppUrlListener'
import { LocalNotifications } from '@capacitor/local-notifications'
import { Capacitor } from '@capacitor/core'

// Create Custom Sentry Route component
export const SentryRoute = SentryReact.withSentryRouting(Route)
const history = createBrowserHistory()
Sentry.init(
  {
    dsn: import.meta.env.VITE_SENTRY_DSN ?? '',
    enabled: import.meta.env.MODE === 'production',
    // Set your release version, such as "getsentry@1.0.0"
    release: 'trium-novem-mobile-app@' + import.meta.env.npm_package_version,
    // Set your dist version, such as "1"
    dist: '1',
    // This sets the sample rate at 10%. You may want this to be 100% while
    // in development, then sample at a lower rate in production.
    replaysSessionSampleRate: 0.1,

    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
    integrations: [
      // Registers and configures the Tracing integration,
      // which automatically instruments your application to monitor its
      // performance, including custom Angular routing instrumentation
      SentryReact.browserTracingIntegration(),
      SentryReact.replayIntegration({
        // Additional SDK configuration goes in here, for example:
        maskAllText: false,
        blockAllMedia: false,
        networkCaptureBodies: true,
      }),
      SentryReact.browserProfilingIntegration(),
      SentryReact.captureConsoleIntegration(),
      SentryReact.contextLinesIntegration(),
      SentryReact.extraErrorDataIntegration(),
      SentryReact.httpClientIntegration(),
      SentryReact.moduleMetadataIntegration(),
      SentryReact.browserApiErrorsIntegration(),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    tracePropagationTargets: ['localhost', import.meta.env.VITE_API_URL],
  },
  // Forward the init method from @sentry/react
  SentryReact.init
)

setupIonicReact()
if (Capacitor.isPluginAvailable('LocalNotifications')) {
  LocalNotifications.checkPermissions().then((permission) => {
    if (permission.display !== 'granted') {
      void LocalNotifications.requestPermissions()
    }
  })
}
const Navigator: React.FC = () => {
  const isAuthenticated = useIsAuthenticated()
  if (isAuthenticated === undefined) {
    return <AppLoading />
  }
  return (
    <IonReactRouter history={history}>
      <AppUrlListener></AppUrlListener>
      <IonRouterOutlet>
        <SentryRoute
          path="/login"
          render={() =>
            !isAuthenticated ? <Login /> : <Redirect to="/tabs/timer" />
          }
        />
        <SentryRoute path="/set-password/:token" component={SetPassword} />
        <SentryRoute
          path="/tabs"
          render={(props) => {
            return isAuthenticated ? (
              <Tabs {...props} />
            ) : (
              <Redirect to="/login" />
            )
          }}
        />
        <SentryRoute component={NotFound} />
        <Redirect exact path="/" to="/login" />
      </IonRouterOutlet>
    </IonReactRouter>
  )
}
const App: React.FC = () => {
  return (
    <IonApp>
      <AuthProvider>
        <Navigator />
      </AuthProvider>
    </IonApp>
  )
}
export default App
