// use-custom-instance.ts

import { AxiosError } from 'axios'
import type { ErrorDto, LoginResponse } from '@/api'
import { DateTime, Duration } from 'luxon'
import * as Sentry from '@sentry/capacitor'
import { Preferences } from '@capacitor/preferences'
import type { HttpHeaders, HttpParams } from '@capacitor/core'
import { CapacitorHttp } from '@capacitor/core'
import type {
  HttpOptions,
  HttpResponseType,
} from '@capacitor/core/types/core-plugins'

const baseURL = import.meta.env.VITE_API_URL || 'https://localhost:4000'

const refreshTokenFn = async () => {
  try {
    const response = await CapacitorHttp.post({
      url: `${baseURL}/auth/refresh`,
      headers: {
        'Content-Type': 'application/json',
      },
      webFetchExtra: {
        credentials: 'include',
      },
    })
    const responseData = response.data as LoginResponse
    if (!responseData.accessToken) {
      await Preferences.set({
        key: 'token',
        value: JSON.stringify(null),
      })
      await Preferences.set({
        key: 'user',
        value: JSON.stringify(null),
      })
    }

    await Preferences.set({
      key: 'token',
      value: JSON.stringify(responseData.accessToken),
    })

    return responseData
  } catch (error) {
    await Preferences.set({ key: 'token', value: JSON.stringify(null) })
    await Preferences.set({ key: 'user', value: JSON.stringify(null) })
  }
}

function toString(o?: { [key: string]: any }): HttpParams | undefined {
  if (o === undefined) {
    return o
  }
  Object.keys(o).forEach((k) => {
    if (typeof o[k] === 'object') {
      return toString(o[k])
    }

    o[k] = o[k].toString()
  })
  return o
}

type CustomInstance<T> = (config: {
  url: string
  method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH'
  params?: {
    [key: string]: any
  }
  data?: any
  responseType?: HttpResponseType
  headers?: HttpHeaders
}) => Promise<T>

export const useCustomInstance = <T>(): CustomInstance<T> => {
  return async ({ url, method, params, headers, data }) => {
    const { value: tokenData } = await Preferences.get({ key: 'token' })
    const token: LoginResponse = tokenData ? JSON.parse(tokenData) : null
    const config: HttpOptions = {
      url: `${baseURL}${url}`,
      method,
      params: toString(params),
      headers,
      data,
    }
    config.headers = {
      ...config.headers,
      'x-time-zone': DateTime.now().zoneName,
      authorization: token ? `Bearer ${token}` : '',
    }

    try {
      const response = await CapacitorHttp.request(config)
      if (response.status >= 400) {
        const { error, message, statusCode } = response.data as ErrorDto
        throw new AxiosError(
          error ?? message.toString(),
          statusCode.toString(),
          undefined,
          config,
          {
            data: response.data,
            headers: response.headers,
            status: response.status,
            statusText: '',
            request: undefined,
            config: {
              headers: headers as any,
            },
          }
        )
      }
      handleDates(response.data)
      return response.data
    } catch (error: any) {
      Sentry.captureException(error)
      if (error.status == 401) {
        const newToken = await refreshTokenFn()
        if (newToken) {
          config.headers.authorization = `Bearer ${newToken.accessToken}`
          return useCustomInstance()({ url, method, params, data })
        }
      }

      throw error // Rethrow for potential handling elsewhere
    }
  }
}

function isIsoDateString(value: any): boolean {
  return (
    value &&
    typeof value === 'string' &&
    (value.includes('-') || value.includes(':')) && // Check string numbers should not parse as datetime
    DateTime.fromISO(value).isValid &&
    !Duration.fromISOTime(value).isValid
  )
}

export function handleDates(body: any) {
  if (body === null || body === undefined || typeof body !== 'object')
    return body

  for (const key of Object.keys(body)) {
    const value = body[key]
    if (Duration.fromISO(value).isValid) {
      body[key] = value // Luxon conversion
    } else if (isIsoDateString(value)) {
      // body[key] = new Date(value) // default JS conversion
      // body[key] = parseISO(value); // date-fns conversion
      body[key] = DateTime.fromISO(value).toJSDate() // Luxon conversion
      // body[key] = moment(value).toDate(); // Moment.js conversion
    } else if (typeof value === 'object') {
      handleDates(value)
    }
  }
}

export default useCustomInstance

export type ErrorType<Error> = AxiosError<Error>
