import { useAuthDispatch, useUserDispatch } from '@/contexts/storage.context'
import { useAuthControllerLogoutHook } from '@/api'
import { AxiosError } from 'axios'
import { useIonRouter } from '@ionic/react'
import * as Sentry from '@sentry/capacitor'

export const useLogout = () => {
  const authDispatch = useAuthDispatch()
  const userDispatch = useUserDispatch()
  const router = useIonRouter()
  const logoutHook = useAuthControllerLogoutHook()
  return () => {
    logoutHook()
      .then(() => {
        userDispatch(null)
        authDispatch(null)
        router.push('/login')
      })
      .catch((e) => {
        if (e instanceof AxiosError) {
          if (e.response?.status === 401 || e.response?.status === 403) {
            Sentry.setUser(null)
            userDispatch(null)
            authDispatch(null)
            router.push('/login')
          }
        } else {
          // eslint-disable-next-line no-console
          console.error(
            `Got error on logout: ${JSON.stringify(e, undefined, 2)}`
          )
        }
      })
  }
}
