import { z as zod } from 'zod'

/**
 * @summary Login user into the platform
 */
export const authControllerSignInBody = zod.object({
  email: zod.coerce.string().email(),
  password: zod.coerce.string(),
})

export const authControllerSignInResponse = zod.object({
  accessToken: zod.coerce.string(),
  refreshToken: zod.coerce.string().uuid(),
})

/**
 * @summary Get current logged-in user profile information
 */
export const authControllerGetProfileResponseFirstNameMax = 100
export const authControllerGetProfileResponseLastNameMax = 100
export const authControllerGetProfileResponseAddressLatMin = -90

export const authControllerGetProfileResponseAddressLatMax = 90
export const authControllerGetProfileResponseAddressLonMin = -180

export const authControllerGetProfileResponseAddressLonMax = 180
export const authControllerGetProfileResponseLeaveTypesItemNameMax = 255
export const authControllerGetProfileResponseLeaveTypesItemDescriptionMax = 1024
export const authControllerGetProfileResponseLeaveTypesItemColorMin = 7

export const authControllerGetProfileResponseLeaveTypesItemColorMax = 7

export const authControllerGetProfileResponse = zod.object({
  uuid: zod.coerce.string().uuid(),
  email: zod.coerce.string().email(),
  firstName: zod.coerce
    .string()
    .max(authControllerGetProfileResponseFirstNameMax),
  lastName: zod.coerce
    .string()
    .max(authControllerGetProfileResponseLastNameMax),
  dateOfBirth: zod.coerce.date().optional(),
  phoneNumber: zod.coerce.string().optional(),
  status: zod.enum(['Active', 'Terminated']),
  permissions: zod.array(
    zod.enum(['admin', 'human-resource', 'user']).optional()
  ),
  role: zod
    .object({
      uuid: zod.coerce.string().uuid(),
      name: zod.coerce.string(),
    })
    .optional(),
  department: zod
    .object({
      uuid: zod.coerce.string().uuid(),
      name: zod.coerce.string(),
    })
    .optional(),
  createdAt: zod.coerce.string().datetime(),
  updatedAt: zod.coerce.string().datetime(),
  address: zod
    .object({
      geoLocation: zod
        .object({
          x: zod.coerce.number().optional(),
          y: zod.coerce.number().optional(),
        })
        .nullish(),
      lat: zod.coerce
        .number()
        .min(authControllerGetProfileResponseAddressLatMin)
        .max(authControllerGetProfileResponseAddressLatMax)
        .optional(),
      lon: zod.coerce
        .number()
        .min(authControllerGetProfileResponseAddressLonMin)
        .max(authControllerGetProfileResponseAddressLonMax)
        .optional(),
      uuid: zod.coerce.string(),
      country: zod.coerce.string().optional(),
      city: zod.coerce.string().optional(),
      street: zod.coerce.string().optional(),
      postcode: zod.coerce.string().optional(),
    })
    .optional(),
  profilePicture: zod.coerce.string(),
  fullName: zod.coerce.string(),
  leaveTypes: zod.array(
    zod.object({
      uuid: zod.coerce.string().uuid(),
      name: zod.coerce
        .string()
        .min(1)
        .max(authControllerGetProfileResponseLeaveTypesItemNameMax),
      description: zod.coerce
        .string()
        .max(authControllerGetProfileResponseLeaveTypesItemDescriptionMax)
        .optional(),
      color: zod.coerce
        .string()
        .min(authControllerGetProfileResponseLeaveTypesItemColorMin)
        .max(authControllerGetProfileResponseLeaveTypesItemColorMax)
        .optional(),
      allowedPerYear: zod.object({
        days: zod.coerce.number(),
        hours: zod.coerce.number(),
        minutes: zod.coerce.number(),
        seconds: zod.coerce.number(),
        milliseconds: zod.coerce.number(),
      }),
      isFreebie: zod.coerce.boolean().optional(),
      isWithAttachment: zod.coerce.boolean().optional(),
    })
  ),
  layouts: zod.array(
    zod.object({
      uuid: zod.coerce.string(),
      user: zod.any(),
      layouts: zod.array(
        zod.object({
          i: zod.coerce.string(),
          x: zod.coerce.number(),
          y: zod.coerce.number(),
          w: zod.coerce.number(),
          h: zod.coerce.number(),
          minW: zod.coerce.number().optional(),
          maxW: zod.coerce.number().optional(),
          minH: zod.coerce.number().optional(),
          maxH: zod.coerce.number().optional(),
          static: zod.coerce.boolean().optional(),
          isBounded: zod.coerce.boolean().optional(),
          isDraggable: zod.coerce.boolean().optional(),
          isResizable: zod.coerce.boolean().optional(),
        })
      ),
    })
  ),
})

/**
 * @summary Update current logged-in user profile information
 */
export const authControllerUpdateBodyPasswordMin = 6

export const authControllerUpdateBody = zod.object({
  password: zod.coerce
    .string()
    .min(authControllerUpdateBodyPasswordMin)
    .optional(),
})

export const authControllerUpdateResponseFirstNameMax = 100
export const authControllerUpdateResponseLastNameMax = 100
export const authControllerUpdateResponseAddressLatMin = -90

export const authControllerUpdateResponseAddressLatMax = 90
export const authControllerUpdateResponseAddressLonMin = -180

export const authControllerUpdateResponseAddressLonMax = 180
export const authControllerUpdateResponseLeaveTypesItemNameMax = 255
export const authControllerUpdateResponseLeaveTypesItemDescriptionMax = 1024
export const authControllerUpdateResponseLeaveTypesItemColorMin = 7

export const authControllerUpdateResponseLeaveTypesItemColorMax = 7

export const authControllerUpdateResponse = zod.object({
  uuid: zod.coerce.string().uuid(),
  email: zod.coerce.string().email(),
  firstName: zod.coerce.string().max(authControllerUpdateResponseFirstNameMax),
  lastName: zod.coerce.string().max(authControllerUpdateResponseLastNameMax),
  dateOfBirth: zod.coerce.date().optional(),
  phoneNumber: zod.coerce.string().optional(),
  status: zod.enum(['Active', 'Terminated']),
  permissions: zod.array(
    zod.enum(['admin', 'human-resource', 'user']).optional()
  ),
  role: zod
    .object({
      uuid: zod.coerce.string().uuid(),
      name: zod.coerce.string(),
    })
    .optional(),
  department: zod
    .object({
      uuid: zod.coerce.string().uuid(),
      name: zod.coerce.string(),
    })
    .optional(),
  createdAt: zod.coerce.string().datetime(),
  updatedAt: zod.coerce.string().datetime(),
  address: zod
    .object({
      geoLocation: zod
        .object({
          x: zod.coerce.number().optional(),
          y: zod.coerce.number().optional(),
        })
        .nullish(),
      lat: zod.coerce
        .number()
        .min(authControllerUpdateResponseAddressLatMin)
        .max(authControllerUpdateResponseAddressLatMax)
        .optional(),
      lon: zod.coerce
        .number()
        .min(authControllerUpdateResponseAddressLonMin)
        .max(authControllerUpdateResponseAddressLonMax)
        .optional(),
      uuid: zod.coerce.string(),
      country: zod.coerce.string().optional(),
      city: zod.coerce.string().optional(),
      street: zod.coerce.string().optional(),
      postcode: zod.coerce.string().optional(),
    })
    .optional(),
  profilePicture: zod.coerce.string(),
  fullName: zod.coerce.string(),
  leaveTypes: zod.array(
    zod.object({
      uuid: zod.coerce.string().uuid(),
      name: zod.coerce
        .string()
        .min(1)
        .max(authControllerUpdateResponseLeaveTypesItemNameMax),
      description: zod.coerce
        .string()
        .max(authControllerUpdateResponseLeaveTypesItemDescriptionMax)
        .optional(),
      color: zod.coerce
        .string()
        .min(authControllerUpdateResponseLeaveTypesItemColorMin)
        .max(authControllerUpdateResponseLeaveTypesItemColorMax)
        .optional(),
      allowedPerYear: zod.object({
        days: zod.coerce.number(),
        hours: zod.coerce.number(),
        minutes: zod.coerce.number(),
        seconds: zod.coerce.number(),
        milliseconds: zod.coerce.number(),
      }),
      isFreebie: zod.coerce.boolean().optional(),
      isWithAttachment: zod.coerce.boolean().optional(),
    })
  ),
  layouts: zod.array(
    zod.object({
      uuid: zod.coerce.string(),
      user: zod.any(),
      layouts: zod.array(
        zod.object({
          i: zod.coerce.string(),
          x: zod.coerce.number(),
          y: zod.coerce.number(),
          w: zod.coerce.number(),
          h: zod.coerce.number(),
          minW: zod.coerce.number().optional(),
          maxW: zod.coerce.number().optional(),
          minH: zod.coerce.number().optional(),
          maxH: zod.coerce.number().optional(),
          static: zod.coerce.boolean().optional(),
          isBounded: zod.coerce.boolean().optional(),
          isDraggable: zod.coerce.boolean().optional(),
          isResizable: zod.coerce.boolean().optional(),
        })
      ),
    })
  ),
})

/**
 * @summary Refresh user access token using cookie
 */
export const authControllerRefreshTokensResponse = zod.object({
  accessToken: zod.coerce.string(),
  refreshToken: zod.coerce.string().uuid(),
})

/**
 * @summary Update current logged-in user profile picture image
 */
export const authControllerChangeProfilePictureResponseFirstNameMax = 100
export const authControllerChangeProfilePictureResponseLastNameMax = 100
export const authControllerChangeProfilePictureResponseAddressLatMin = -90

export const authControllerChangeProfilePictureResponseAddressLatMax = 90
export const authControllerChangeProfilePictureResponseAddressLonMin = -180

export const authControllerChangeProfilePictureResponseAddressLonMax = 180
export const authControllerChangeProfilePictureResponseLeaveTypesItemNameMax = 255
export const authControllerChangeProfilePictureResponseLeaveTypesItemDescriptionMax = 1024
export const authControllerChangeProfilePictureResponseLeaveTypesItemColorMin = 7

export const authControllerChangeProfilePictureResponseLeaveTypesItemColorMax = 7

export const authControllerChangeProfilePictureResponse = zod.object({
  uuid: zod.coerce.string().uuid(),
  email: zod.coerce.string().email(),
  firstName: zod.coerce
    .string()
    .max(authControllerChangeProfilePictureResponseFirstNameMax),
  lastName: zod.coerce
    .string()
    .max(authControllerChangeProfilePictureResponseLastNameMax),
  dateOfBirth: zod.coerce.date().optional(),
  phoneNumber: zod.coerce.string().optional(),
  status: zod.enum(['Active', 'Terminated']),
  permissions: zod.array(
    zod.enum(['admin', 'human-resource', 'user']).optional()
  ),
  role: zod
    .object({
      uuid: zod.coerce.string().uuid(),
      name: zod.coerce.string(),
    })
    .optional(),
  department: zod
    .object({
      uuid: zod.coerce.string().uuid(),
      name: zod.coerce.string(),
    })
    .optional(),
  createdAt: zod.coerce.string().datetime(),
  updatedAt: zod.coerce.string().datetime(),
  address: zod
    .object({
      geoLocation: zod
        .object({
          x: zod.coerce.number().optional(),
          y: zod.coerce.number().optional(),
        })
        .nullish(),
      lat: zod.coerce
        .number()
        .min(authControllerChangeProfilePictureResponseAddressLatMin)
        .max(authControllerChangeProfilePictureResponseAddressLatMax)
        .optional(),
      lon: zod.coerce
        .number()
        .min(authControllerChangeProfilePictureResponseAddressLonMin)
        .max(authControllerChangeProfilePictureResponseAddressLonMax)
        .optional(),
      uuid: zod.coerce.string(),
      country: zod.coerce.string().optional(),
      city: zod.coerce.string().optional(),
      street: zod.coerce.string().optional(),
      postcode: zod.coerce.string().optional(),
    })
    .optional(),
  profilePicture: zod.coerce.string(),
  fullName: zod.coerce.string(),
  leaveTypes: zod.array(
    zod.object({
      uuid: zod.coerce.string().uuid(),
      name: zod.coerce
        .string()
        .min(1)
        .max(authControllerChangeProfilePictureResponseLeaveTypesItemNameMax),
      description: zod.coerce
        .string()
        .max(
          authControllerChangeProfilePictureResponseLeaveTypesItemDescriptionMax
        )
        .optional(),
      color: zod.coerce
        .string()
        .min(authControllerChangeProfilePictureResponseLeaveTypesItemColorMin)
        .max(authControllerChangeProfilePictureResponseLeaveTypesItemColorMax)
        .optional(),
      allowedPerYear: zod.object({
        days: zod.coerce.number(),
        hours: zod.coerce.number(),
        minutes: zod.coerce.number(),
        seconds: zod.coerce.number(),
        milliseconds: zod.coerce.number(),
      }),
      isFreebie: zod.coerce.boolean().optional(),
      isWithAttachment: zod.coerce.boolean().optional(),
    })
  ),
  layouts: zod.array(
    zod.object({
      uuid: zod.coerce.string(),
      user: zod.any(),
      layouts: zod.array(
        zod.object({
          i: zod.coerce.string(),
          x: zod.coerce.number(),
          y: zod.coerce.number(),
          w: zod.coerce.number(),
          h: zod.coerce.number(),
          minW: zod.coerce.number().optional(),
          maxW: zod.coerce.number().optional(),
          minH: zod.coerce.number().optional(),
          maxH: zod.coerce.number().optional(),
          static: zod.coerce.boolean().optional(),
          isBounded: zod.coerce.boolean().optional(),
          isDraggable: zod.coerce.boolean().optional(),
          isResizable: zod.coerce.boolean().optional(),
        })
      ),
    })
  ),
})

/**
 * @summary Set new password using reset password token
 */
export const authControllerResetPasswordParams = zod.object({
  token: zod.coerce.string().uuid(),
})

export const authControllerResetPasswordBodyPasswordMin = 6

export const authControllerResetPasswordBody = zod.object({
  password: zod.coerce.string().min(authControllerResetPasswordBodyPasswordMin),
})

/**
 * @summary Validate reset password token is valid
 */
export const authControllerValidateResetPasswordParams = zod.object({
  token: zod.coerce.string().uuid(),
})

/**
 * @summary Redirect to reset password page in the mobile app to support email clients url
 */
export const authControllerResetPasswordPageParams = zod.object({
  token: zod.coerce.string().uuid(),
})
