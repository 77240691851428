import { z as zod } from 'zod'

/**
 * @summary Get list of time tracking records for current logged-in user
 */
export const projectsControllerFindAllQueryParams = zod.object({
  page: zod.coerce.number().optional(),
  limit: zod.coerce.number().optional(),
  'filter.name': zod.array(zod.coerce.string()).optional(),
  sortBy: zod
    .array(
      zod.enum(['name:ASC', 'name:DESC', 'createdAt:ASC', 'createdAt:DESC'])
    )
    .optional(),
  select: zod.coerce.string().optional(),
})

export const projectsControllerFindAllResponseDataItemSerialMax = 50
export const projectsControllerFindAllResponseDataItemCompletionPercentageMin = 0

export const projectsControllerFindAllResponseDataItemCompletionPercentageMax = 100
export const projectsControllerFindAllResponseDataItemRoadmapsItemTagNameMax = 50
export const projectsControllerFindAllResponseDataItemRoadmapsItemColorMin = 7

export const projectsControllerFindAllResponseDataItemRoadmapsItemColorMax = 7
export const projectsControllerFindAllResponseDataItemRoadmapsItemItemsItemColorMin = 7

export const projectsControllerFindAllResponseDataItemRoadmapsItemItemsItemColorMax = 7
export const projectsControllerFindAllResponseDataItemProjectManagersItemFirstNameMax = 100
export const projectsControllerFindAllResponseDataItemProjectManagersItemLastNameMax = 100
export const projectsControllerFindAllResponseDataItemProjectManagersItemAddressLatMin =
  -90

export const projectsControllerFindAllResponseDataItemProjectManagersItemAddressLatMax = 90
export const projectsControllerFindAllResponseDataItemProjectManagersItemAddressLonMin =
  -180

export const projectsControllerFindAllResponseDataItemProjectManagersItemAddressLonMax = 180
export const projectsControllerFindAllResponseDataItemProjectManagersItemLeaveTypesItemNameMax = 255
export const projectsControllerFindAllResponseDataItemProjectManagersItemLeaveTypesItemDescriptionMax = 1024
export const projectsControllerFindAllResponseDataItemProjectManagersItemLeaveTypesItemColorMin = 7

export const projectsControllerFindAllResponseDataItemProjectManagersItemLeaveTypesItemColorMax = 7
export const projectsControllerFindAllResponseDataItemTotalAllocationsMin = 0
export const projectsControllerFindAllResponseDataItemAddressLatMin = -90

export const projectsControllerFindAllResponseDataItemAddressLatMax = 90
export const projectsControllerFindAllResponseDataItemAddressLonMin = -180

export const projectsControllerFindAllResponseDataItemAddressLonMax = 180

export const projectsControllerFindAllResponse = zod
  .object({
    data: zod.array(zod.object({})),
    meta: zod.object({
      itemsPerPage: zod.coerce.number(),
      totalItems: zod.coerce.number(),
      currentPage: zod.coerce.number(),
      totalPages: zod.coerce.number(),
      sortBy: zod
        .array(zod.array(zod.coerce.string().or(zod.enum(['ASC', 'DESC']))))
        .optional(),
      searchBy: zod.array(zod.coerce.string()).optional(),
      search: zod.coerce.string().optional(),
      select: zod.array(zod.coerce.string()).optional(),
      filter: zod.object({}).optional(),
    }),
    links: zod.object({
      first: zod.coerce.string().optional(),
      previous: zod.coerce.string().optional(),
      current: zod.coerce.string().optional(),
      next: zod.coerce.string().optional(),
      last: zod.coerce.string().optional(),
    }),
  })
  .and(
    zod.object({
      data: zod
        .array(
          zod.object({
            serial: zod.coerce
              .string()
              .max(projectsControllerFindAllResponseDataItemSerialMax)
              .optional(),
            projectSize: zod.coerce.number(),
            guaranteedUntil: zod.coerce.date().optional(),
            projectType: zod.object({
              name: zod.coerce.string(),
              uuid: zod.coerce.string(),
            }),
            completionPercentage: zod.coerce
              .number()
              .min(
                projectsControllerFindAllResponseDataItemCompletionPercentageMin
              )
              .max(
                projectsControllerFindAllResponseDataItemCompletionPercentageMax
              )
              .optional(),
            startedAt: zod.coerce.date().optional(),
            estimatedEndAt: zod.coerce.date().optional(),
            endAt: zod.coerce.date().optional(),
            status: zod.enum(['Active', 'In Active', 'Potential']).optional(),
            milestones: zod
              .array(
                zod.object({
                  uuid: zod.coerce.string().uuid(),
                  date: zod.coerce.date(),
                  iconUrl: zod.coerce.string().nullable(),
                  description: zod.coerce.string().nullable(),
                  projectUuid: zod.coerce.string().uuid(),
                  createdAt: zod.coerce.string().datetime(),
                  updatedAt: zod.coerce.string().datetime(),
                  project: zod.any(),
                })
              )
              .optional(),
            roadmaps: zod
              .array(
                zod.object({
                  uuid: zod.coerce.string().uuid(),
                  startAt: zod.coerce.date(),
                  endAt: zod.coerce.date(),
                  isVisible: zod.coerce.boolean().optional(),
                  project: zod.any(),
                  tag: zod.object({
                    uuid: zod.coerce.string().uuid(),
                    parent: zod.any().nullish(),
                    name: zod.coerce
                      .string()
                      .max(
                        projectsControllerFindAllResponseDataItemRoadmapsItemTagNameMax
                      ),
                    companyType: zod
                      .enum(['Client', 'Contractor', 'Other'])
                      .optional(),
                  }),
                  color: zod.coerce
                    .string()
                    .min(
                      projectsControllerFindAllResponseDataItemRoadmapsItemColorMin
                    )
                    .max(
                      projectsControllerFindAllResponseDataItemRoadmapsItemColorMax
                    )
                    .nullish(),
                  items: zod.array(
                    zod.object({
                      uuid: zod.coerce.string().uuid(),
                      color: zod.coerce
                        .string()
                        .min(
                          projectsControllerFindAllResponseDataItemRoadmapsItemItemsItemColorMin
                        )
                        .max(
                          projectsControllerFindAllResponseDataItemRoadmapsItemItemsItemColorMax
                        )
                        .nullish(),
                      startAt: zod.coerce.date(),
                      endAt: zod.coerce.date(),
                      description: zod.coerce.string().nullable(),
                      roadmap: zod.any().optional(),
                    })
                  ),
                })
              )
              .optional(),
            projectManagers: zod.array(
              zod.object({
                uuid: zod.coerce.string().uuid(),
                email: zod.coerce.string().email(),
                firstName: zod.coerce
                  .string()
                  .max(
                    projectsControllerFindAllResponseDataItemProjectManagersItemFirstNameMax
                  ),
                lastName: zod.coerce
                  .string()
                  .max(
                    projectsControllerFindAllResponseDataItemProjectManagersItemLastNameMax
                  ),
                dateOfBirth: zod.coerce.date().optional(),
                phoneNumber: zod.coerce.string().optional(),
                status: zod.enum(['Active', 'Terminated']),
                permissions: zod.array(
                  zod.enum(['admin', 'human-resource', 'user']).optional()
                ),
                role: zod
                  .object({
                    uuid: zod.coerce.string().uuid(),
                    name: zod.coerce.string(),
                  })
                  .optional(),
                department: zod
                  .object({
                    uuid: zod.coerce.string().uuid(),
                    name: zod.coerce.string(),
                  })
                  .optional(),
                createdAt: zod.coerce.string().datetime(),
                updatedAt: zod.coerce.string().datetime(),
                address: zod
                  .object({
                    geoLocation: zod
                      .object({
                        x: zod.coerce.number().optional(),
                        y: zod.coerce.number().optional(),
                      })
                      .nullish(),
                    lat: zod.coerce
                      .number()
                      .min(
                        projectsControllerFindAllResponseDataItemProjectManagersItemAddressLatMin
                      )
                      .max(
                        projectsControllerFindAllResponseDataItemProjectManagersItemAddressLatMax
                      )
                      .optional(),
                    lon: zod.coerce
                      .number()
                      .min(
                        projectsControllerFindAllResponseDataItemProjectManagersItemAddressLonMin
                      )
                      .max(
                        projectsControllerFindAllResponseDataItemProjectManagersItemAddressLonMax
                      )
                      .optional(),
                    uuid: zod.coerce.string(),
                    country: zod.coerce.string().optional(),
                    city: zod.coerce.string().optional(),
                    street: zod.coerce.string().optional(),
                    postcode: zod.coerce.string().optional(),
                  })
                  .optional(),
                profilePicture: zod.coerce.string(),
                fullName: zod.coerce.string(),
                leaveTypes: zod.array(
                  zod.object({
                    uuid: zod.coerce.string().uuid(),
                    name: zod.coerce
                      .string()
                      .min(1)
                      .max(
                        projectsControllerFindAllResponseDataItemProjectManagersItemLeaveTypesItemNameMax
                      ),
                    description: zod.coerce
                      .string()
                      .max(
                        projectsControllerFindAllResponseDataItemProjectManagersItemLeaveTypesItemDescriptionMax
                      )
                      .optional(),
                    color: zod.coerce
                      .string()
                      .min(
                        projectsControllerFindAllResponseDataItemProjectManagersItemLeaveTypesItemColorMin
                      )
                      .max(
                        projectsControllerFindAllResponseDataItemProjectManagersItemLeaveTypesItemColorMax
                      )
                      .optional(),
                    allowedPerYear: zod.object({
                      days: zod.coerce.number(),
                      hours: zod.coerce.number(),
                      minutes: zod.coerce.number(),
                      seconds: zod.coerce.number(),
                      milliseconds: zod.coerce.number(),
                    }),
                    isFreebie: zod.coerce.boolean().optional(),
                    isWithAttachment: zod.coerce.boolean().optional(),
                  })
                ),
                layouts: zod.array(
                  zod.object({
                    uuid: zod.coerce.string(),
                    user: zod.any(),
                    layouts: zod.array(
                      zod.object({
                        i: zod.coerce.string(),
                        x: zod.coerce.number(),
                        y: zod.coerce.number(),
                        w: zod.coerce.number(),
                        h: zod.coerce.number(),
                        minW: zod.coerce.number().optional(),
                        maxW: zod.coerce.number().optional(),
                        minH: zod.coerce.number().optional(),
                        maxH: zod.coerce.number().optional(),
                        static: zod.coerce.boolean().optional(),
                        isBounded: zod.coerce.boolean().optional(),
                        isDraggable: zod.coerce.boolean().optional(),
                        isResizable: zod.coerce.boolean().optional(),
                      })
                    ),
                  })
                ),
              })
            ),
            totalAllocations: zod.coerce
              .number()
              .min(
                projectsControllerFindAllResponseDataItemTotalAllocationsMin
              ),
            uuid: zod.coerce.string(),
            name: zod.coerce.string(),
            address: zod.object({
              geoLocation: zod
                .object({
                  x: zod.coerce.number().optional(),
                  y: zod.coerce.number().optional(),
                })
                .nullish(),
              lat: zod.coerce
                .number()
                .min(projectsControllerFindAllResponseDataItemAddressLatMin)
                .max(projectsControllerFindAllResponseDataItemAddressLatMax)
                .optional(),
              lon: zod.coerce
                .number()
                .min(projectsControllerFindAllResponseDataItemAddressLonMin)
                .max(projectsControllerFindAllResponseDataItemAddressLonMax)
                .optional(),
              uuid: zod.coerce.string(),
              country: zod.coerce.string().optional(),
              city: zod.coerce.string().optional(),
              street: zod.coerce.string().optional(),
              postcode: zod.coerce.string().optional(),
            }),
            createdAt: zod.coerce.string().datetime(),
            updatedAt: zod.coerce.string().datetime(),
          })
        )
        .optional(),
      meta: zod
        .object({
          select: zod.array(zod.enum(['uuid', 'name'])).optional(),
          filter: zod
            .object({
              name: zod.coerce
                .string()
                .or(zod.array(zod.coerce.string()))
                .optional(),
            })
            .optional(),
        })
        .optional(),
    })
  )
