import type { UseFormSetError } from 'react-hook-form'
import { type MultipleFieldErrors } from 'react-hook-form'
import { useCallback } from 'react'
import { AxiosError } from 'axios'
import { useIonToast } from '@ionic/react'

export const UseFormRequestError = <T extends object = object>({
  setError,
}: {
  setError: UseFormSetError<T>
}) => {
  const [toastPresent] = useIonToast()
  return useCallback(
    (e: any) => {
      if (e instanceof AxiosError && Boolean(e.response?.data.message)) {
        const messages = e.response?.data.message
        if (typeof messages === 'string') {
          void toastPresent({
            message: messages,
            color: 'danger',
            duration: 5000,
            position: 'middle',
            buttons: [
              {
                text: 'Dismiss',
                role: 'cancel',
              },
            ],
          })
          return
        }
        for (const error in messages) {
          const errorMessages: MultipleFieldErrors = {}
          for (const messageKey in messages[error]) {
            errorMessages[`${error}-${messageKey}`] =
              messages[error][messageKey]
          }
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          setError(error as any, {
            types: errorMessages,
          })
        }
      }
    },
    [setError, toastPresent]
  )
}
