import type React from 'react'
import { createContext } from 'react'
import type { SegmentValue } from '@ionic/react'
import { usePreferenceItem } from '@mortezakarimi/preference-react'

export type SessionProviderProps = {
  initialState?: string | null
} & React.PropsWithChildren
export const SessionContext = createContext<{
  selectedDate: SegmentValue | undefined
  setSelectedDate: (v?: SegmentValue) => Promise<void>
}>({
  selectedDate: undefined,
  setSelectedDate: () => Promise.resolve(),
})

export const SessionProvider: React.FC<SessionProviderProps> = ({
  children,
  initialState,
}) => {
  const [selectedDate, setSelectedDate] = usePreferenceItem<
    SegmentValue | undefined
  >('sessions', initialState as SegmentValue)
  return (
    <SessionContext.Provider value={{ selectedDate, setSelectedDate }}>
      {children}
    </SessionContext.Provider>
  )
}
