import {
  ErrorMessage,
  type FieldValuesFromFieldErrors,
} from '@hookform/error-message'
import { IonNote } from '@ionic/react'
import type React from 'react'
import { type FieldErrors, type FieldName } from 'react-hook-form'

export const FormErrorMessage: React.FC<{
  errors: FieldErrors
  name: FieldName<FieldValuesFromFieldErrors<any>>
}> = ({ errors, name }) => {
  return (
    <div className="ion-margin-top">
      <ErrorMessage name={name} as={IonNote} color="danger" errors={errors} />
      <ErrorMessage
        name={name}
        errors={errors}
        render={({ messages }) => {
          return (
            messages != null &&
            Object.entries(messages).map(([type, message]) => (
              <IonNote color="danger" style={{ display: 'block' }} key={type}>
                {message}
              </IonNote>
            ))
          )
        }}
      />
    </div>
  )
}
