import type React from 'react'

export type GradientProps = {
  id: string
  startColor: string
  stopColor: string
}

export const Gradient: React.FC<GradientProps> = ({
  id,
  startColor,
  stopColor,
}) => {
  return (
    <linearGradient id={id} x1="0%" x2="100%" y1="0%" y2="100%">
      <stop offset="0%" stopColor={startColor} />
      <stop offset="100%" stopColor={stopColor} />
    </linearGradient>
  )
}
