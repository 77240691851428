import { createContext, useContext, useEffect } from 'react'
import {
  type LoginResponse,
  useAuthControllerGetProfileHook,
  type User,
} from '@/api'
import { type AxiosError } from 'axios'
import { useHistory } from 'react-router'
import { type AuthProviderProps } from '@/contracts/auth-provider-props.type'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { useStorage } from '@/hooks/use-storage'
import * as Sentry from '@sentry/capacitor'
import { SessionProvider } from '@/contexts/session.context'
import { DateTime } from 'luxon'

/** Auth */
// eslint-disable-next-line no-unused-vars
type Dispatch = (Auth: LoginResponse | null) => void
const AuthContext = createContext<string | null | undefined>(undefined)
const AuthDispatchContext = createContext<Dispatch | null>(null)

/** User */
// eslint-disable-next-line no-unused-vars
type DispatchUser = (User: User | null) => void
const UserContext = createContext<User | null>(null)
const UserDispatchContext = createContext<DispatchUser | null>(null)

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchIntervalInBackground: false,
      refetchInterval: false,
      refetchOnMount: false,
      retry: false,
      retryOnMount: false,
      refetchOnWindowFocus: false,
      staleTime: 60 * 1000,
    },
  },
})

const AuthProvider = ({
  children,
  initialState = undefined,
  initialUserState = null,
}: AuthProviderProps) => {
  const [token, setToken] = useStorage<string | null | undefined>(
    'token',
    initialState
  )
  const [user, setUser] = useStorage<User | null>('user', initialUserState)
  const profileHook = useAuthControllerGetProfileHook()
  const history = useHistory()

  useEffect(() => {
    if (token != null) {
      profileHook()
        .then((value) => {
          setUser(value)
          Sentry.setUser(value)
        })
        .catch((reason: AxiosError) => {
          if (reason?.response?.status === 401) {
            Sentry.setUser(null)
            setUser(null)
            setToken(null)
          }
          if (reason?.response?.status !== 200) {
            history?.push('/')
          }
        })
    } else if (token === null) {
      queryClient.invalidateQueries().then()
    }
  }, [token])

  return (
    <AuthContext.Provider value={token}>
      <AuthDispatchContext.Provider value={setToken}>
        <UserContext.Provider value={user}>
          <UserDispatchContext.Provider value={setUser}>
            <SessionProvider initialState={DateTime.now().toISODate()}>
              <QueryClientProvider client={queryClient}>
                {children}
              </QueryClientProvider>
            </SessionProvider>
          </UserDispatchContext.Provider>
        </UserContext.Provider>
      </AuthDispatchContext.Provider>
    </AuthContext.Provider>
  )
}

/** related hooks */
const useAuth = (): string | null | undefined => {
  return useContext<string | null | undefined>(AuthContext)
}
const useIsAuthenticated = (): boolean | undefined => {
  const token = useContext<string | null | undefined>(AuthContext)
  if (token === undefined) {
    return undefined
  }
  return token !== null
}

function useAuthDispatch(): Dispatch {
  const context = useContext<Dispatch | null>(AuthDispatchContext)
  if (context === null) {
    throw new Error('useAuthDispatch must be used within an AuthProvider')
  }
  return context
}

const useUser = (): User | null => {
  return useContext<User | null>(UserContext)
}

const useUserDispatch = (): DispatchUser => {
  const context = useContext<DispatchUser | null>(UserDispatchContext)
  if (context === null) {
    throw new Error('useUserDispatch must be within a UserProvider')
  }
  return context
}

export {
  AuthProvider,
  // eslint-disable-next-line react-refresh/only-export-components
  useAuth,
  // eslint-disable-next-line react-refresh/only-export-components
  useIsAuthenticated,
  // eslint-disable-next-line react-refresh/only-export-components
  useAuthDispatch,
  // eslint-disable-next-line react-refresh/only-export-components
  useUser,
  // eslint-disable-next-line react-refresh/only-export-components
  useUserDispatch,
}
