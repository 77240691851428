import { z as zod } from 'zod'

/**
 * @summary Get work location insight
 */
export const reportControllerWorkLocationInsightParams = zod.object({
  from: zod.coerce.date(),
  to: zod.coerce.date(),
})

export const reportControllerWorkLocationInsightResponseItem = zod.object({
  userUuid: zod.coerce.string().uuid(),
  date: zod.coerce.date(),
  workType: zod.enum(['home_work', 'office_work', 'site_work', 'transit']),
  workDuration: zod.object({
    days: zod.coerce.number(),
    hours: zod.coerce.number(),
    minutes: zod.coerce.number(),
    seconds: zod.coerce.number(),
    milliseconds: zod.coerce.number(),
  }),
})
export const reportControllerWorkLocationInsightResponse = zod.array(
  reportControllerWorkLocationInsightResponseItem
)

/**
 * @summary Get work project insight
 */
export const reportControllerWorkProjectInsightParams = zod.object({
  from: zod.coerce.date(),
  to: zod.coerce.date(),
})

export const reportControllerWorkProjectInsightResponseItem = zod.object({
  userUuid: zod.coerce.string().uuid(),
  date: zod.coerce.date(),
  projectUuid: zod.coerce.string().uuid(),
  projectName: zod.coerce.string(),
  workDuration: zod.object({
    days: zod.coerce.number(),
    hours: zod.coerce.number(),
    minutes: zod.coerce.number(),
    seconds: zod.coerce.number(),
    milliseconds: zod.coerce.number(),
  }),
})
export const reportControllerWorkProjectInsightResponse = zod.array(
  reportControllerWorkProjectInsightResponseItem
)

/**
 * @summary Get work vs break insight
 */
export const reportControllerWorkVsBreakInsightParams = zod.object({
  from: zod.coerce.date(),
  to: zod.coerce.date(),
})

export const reportControllerWorkVsBreakInsightResponseUserFirstNameMax = 100
export const reportControllerWorkVsBreakInsightResponseUserLastNameMax = 100
export const reportControllerWorkVsBreakInsightResponseUserAddressLatMin = -90

export const reportControllerWorkVsBreakInsightResponseUserAddressLatMax = 90
export const reportControllerWorkVsBreakInsightResponseUserAddressLonMin = -180

export const reportControllerWorkVsBreakInsightResponseUserAddressLonMax = 180
export const reportControllerWorkVsBreakInsightResponseUserLeaveTypesItemNameMax = 255
export const reportControllerWorkVsBreakInsightResponseUserLeaveTypesItemDescriptionMax = 1024
export const reportControllerWorkVsBreakInsightResponseUserLeaveTypesItemColorMin = 7

export const reportControllerWorkVsBreakInsightResponseUserLeaveTypesItemColorMax = 7

export const reportControllerWorkVsBreakInsightResponse = zod.object({
  date: zod.coerce.string().datetime(),
  startAt: zod.coerce.string().datetime(),
  endAt: zod.coerce.string().datetime(),
  totalDuration: zod.object({
    days: zod.coerce.number(),
    hours: zod.coerce.number(),
    minutes: zod.coerce.number(),
    seconds: zod.coerce.number(),
    milliseconds: zod.coerce.number(),
  }),
  breakDuration: zod.object({
    days: zod.coerce.number(),
    hours: zod.coerce.number(),
    minutes: zod.coerce.number(),
    seconds: zod.coerce.number(),
    milliseconds: zod.coerce.number(),
  }),
  workDuration: zod.object({
    days: zod.coerce.number(),
    hours: zod.coerce.number(),
    minutes: zod.coerce.number(),
    seconds: zod.coerce.number(),
    milliseconds: zod.coerce.number(),
  }),
  userUuid: zod.coerce.string().uuid(),
  user: zod.object({
    uuid: zod.coerce.string().uuid(),
    email: zod.coerce.string().email(),
    firstName: zod.coerce
      .string()
      .max(reportControllerWorkVsBreakInsightResponseUserFirstNameMax),
    lastName: zod.coerce
      .string()
      .max(reportControllerWorkVsBreakInsightResponseUserLastNameMax),
    dateOfBirth: zod.coerce.date().optional(),
    phoneNumber: zod.coerce.string().optional(),
    status: zod.enum(['Active', 'Terminated']),
    permissions: zod.array(
      zod.enum(['admin', 'human-resource', 'user']).optional()
    ),
    role: zod
      .object({
        uuid: zod.coerce.string().uuid(),
        name: zod.coerce.string(),
      })
      .optional(),
    department: zod
      .object({
        uuid: zod.coerce.string().uuid(),
        name: zod.coerce.string(),
      })
      .optional(),
    createdAt: zod.coerce.string().datetime(),
    updatedAt: zod.coerce.string().datetime(),
    address: zod
      .object({
        geoLocation: zod
          .object({
            x: zod.coerce.number().optional(),
            y: zod.coerce.number().optional(),
          })
          .nullish(),
        lat: zod.coerce
          .number()
          .min(reportControllerWorkVsBreakInsightResponseUserAddressLatMin)
          .max(reportControllerWorkVsBreakInsightResponseUserAddressLatMax)
          .optional(),
        lon: zod.coerce
          .number()
          .min(reportControllerWorkVsBreakInsightResponseUserAddressLonMin)
          .max(reportControllerWorkVsBreakInsightResponseUserAddressLonMax)
          .optional(),
        uuid: zod.coerce.string(),
        country: zod.coerce.string().optional(),
        city: zod.coerce.string().optional(),
        street: zod.coerce.string().optional(),
        postcode: zod.coerce.string().optional(),
      })
      .optional(),
    profilePicture: zod.coerce.string(),
    fullName: zod.coerce.string(),
    leaveTypes: zod.array(
      zod.object({
        uuid: zod.coerce.string().uuid(),
        name: zod.coerce
          .string()
          .min(1)
          .max(
            reportControllerWorkVsBreakInsightResponseUserLeaveTypesItemNameMax
          ),
        description: zod.coerce
          .string()
          .max(
            reportControllerWorkVsBreakInsightResponseUserLeaveTypesItemDescriptionMax
          )
          .optional(),
        color: zod.coerce
          .string()
          .min(
            reportControllerWorkVsBreakInsightResponseUserLeaveTypesItemColorMin
          )
          .max(
            reportControllerWorkVsBreakInsightResponseUserLeaveTypesItemColorMax
          )
          .optional(),
        allowedPerYear: zod.object({
          days: zod.coerce.number(),
          hours: zod.coerce.number(),
          minutes: zod.coerce.number(),
          seconds: zod.coerce.number(),
          milliseconds: zod.coerce.number(),
        }),
        isFreebie: zod.coerce.boolean().optional(),
        isWithAttachment: zod.coerce.boolean().optional(),
      })
    ),
    layouts: zod.array(
      zod.object({
        uuid: zod.coerce.string(),
        user: zod.any(),
        layouts: zod.array(
          zod.object({
            i: zod.coerce.string(),
            x: zod.coerce.number(),
            y: zod.coerce.number(),
            w: zod.coerce.number(),
            h: zod.coerce.number(),
            minW: zod.coerce.number().optional(),
            maxW: zod.coerce.number().optional(),
            minH: zod.coerce.number().optional(),
            maxH: zod.coerce.number().optional(),
            static: zod.coerce.boolean().optional(),
            isBounded: zod.coerce.boolean().optional(),
            isDraggable: zod.coerce.boolean().optional(),
            isResizable: zod.coerce.boolean().optional(),
          })
        ),
      })
    ),
  }),
})

/**
 * @summary Get work history insight
 */
export const reportControllerGetWorkHistoryParams = zod.object({
  from: zod.coerce.date(),
  to: zod.coerce.date(),
})

export const reportControllerGetWorkHistoryResponseUserFirstNameMax = 100
export const reportControllerGetWorkHistoryResponseUserLastNameMax = 100
export const reportControllerGetWorkHistoryResponseUserAddressLatMin = -90

export const reportControllerGetWorkHistoryResponseUserAddressLatMax = 90
export const reportControllerGetWorkHistoryResponseUserAddressLonMin = -180

export const reportControllerGetWorkHistoryResponseUserAddressLonMax = 180
export const reportControllerGetWorkHistoryResponseUserLeaveTypesItemNameMax = 255
export const reportControllerGetWorkHistoryResponseUserLeaveTypesItemDescriptionMax = 1024
export const reportControllerGetWorkHistoryResponseUserLeaveTypesItemColorMin = 7

export const reportControllerGetWorkHistoryResponseUserLeaveTypesItemColorMax = 7

export const reportControllerGetWorkHistoryResponseItem = zod.object({
  date: zod.coerce.string().datetime(),
  startAt: zod.coerce.string().datetime(),
  endAt: zod.coerce.string().datetime(),
  totalDuration: zod.object({
    days: zod.coerce.number(),
    hours: zod.coerce.number(),
    minutes: zod.coerce.number(),
    seconds: zod.coerce.number(),
    milliseconds: zod.coerce.number(),
  }),
  breakDuration: zod.object({
    days: zod.coerce.number(),
    hours: zod.coerce.number(),
    minutes: zod.coerce.number(),
    seconds: zod.coerce.number(),
    milliseconds: zod.coerce.number(),
  }),
  workDuration: zod.object({
    days: zod.coerce.number(),
    hours: zod.coerce.number(),
    minutes: zod.coerce.number(),
    seconds: zod.coerce.number(),
    milliseconds: zod.coerce.number(),
  }),
  userUuid: zod.coerce.string().uuid(),
  user: zod.object({
    uuid: zod.coerce.string().uuid(),
    email: zod.coerce.string().email(),
    firstName: zod.coerce
      .string()
      .max(reportControllerGetWorkHistoryResponseUserFirstNameMax),
    lastName: zod.coerce
      .string()
      .max(reportControllerGetWorkHistoryResponseUserLastNameMax),
    dateOfBirth: zod.coerce.date().optional(),
    phoneNumber: zod.coerce.string().optional(),
    status: zod.enum(['Active', 'Terminated']),
    permissions: zod.array(
      zod.enum(['admin', 'human-resource', 'user']).optional()
    ),
    role: zod
      .object({
        uuid: zod.coerce.string().uuid(),
        name: zod.coerce.string(),
      })
      .optional(),
    department: zod
      .object({
        uuid: zod.coerce.string().uuid(),
        name: zod.coerce.string(),
      })
      .optional(),
    createdAt: zod.coerce.string().datetime(),
    updatedAt: zod.coerce.string().datetime(),
    address: zod
      .object({
        geoLocation: zod
          .object({
            x: zod.coerce.number().optional(),
            y: zod.coerce.number().optional(),
          })
          .nullish(),
        lat: zod.coerce
          .number()
          .min(reportControllerGetWorkHistoryResponseUserAddressLatMin)
          .max(reportControllerGetWorkHistoryResponseUserAddressLatMax)
          .optional(),
        lon: zod.coerce
          .number()
          .min(reportControllerGetWorkHistoryResponseUserAddressLonMin)
          .max(reportControllerGetWorkHistoryResponseUserAddressLonMax)
          .optional(),
        uuid: zod.coerce.string(),
        country: zod.coerce.string().optional(),
        city: zod.coerce.string().optional(),
        street: zod.coerce.string().optional(),
        postcode: zod.coerce.string().optional(),
      })
      .optional(),
    profilePicture: zod.coerce.string(),
    fullName: zod.coerce.string(),
    leaveTypes: zod.array(
      zod.object({
        uuid: zod.coerce.string().uuid(),
        name: zod.coerce
          .string()
          .min(1)
          .max(reportControllerGetWorkHistoryResponseUserLeaveTypesItemNameMax),
        description: zod.coerce
          .string()
          .max(
            reportControllerGetWorkHistoryResponseUserLeaveTypesItemDescriptionMax
          )
          .optional(),
        color: zod.coerce
          .string()
          .min(reportControllerGetWorkHistoryResponseUserLeaveTypesItemColorMin)
          .max(reportControllerGetWorkHistoryResponseUserLeaveTypesItemColorMax)
          .optional(),
        allowedPerYear: zod.object({
          days: zod.coerce.number(),
          hours: zod.coerce.number(),
          minutes: zod.coerce.number(),
          seconds: zod.coerce.number(),
          milliseconds: zod.coerce.number(),
        }),
        isFreebie: zod.coerce.boolean().optional(),
        isWithAttachment: zod.coerce.boolean().optional(),
      })
    ),
    layouts: zod.array(
      zod.object({
        uuid: zod.coerce.string(),
        user: zod.any(),
        layouts: zod.array(
          zod.object({
            i: zod.coerce.string(),
            x: zod.coerce.number(),
            y: zod.coerce.number(),
            w: zod.coerce.number(),
            h: zod.coerce.number(),
            minW: zod.coerce.number().optional(),
            maxW: zod.coerce.number().optional(),
            minH: zod.coerce.number().optional(),
            maxH: zod.coerce.number().optional(),
            static: zod.coerce.boolean().optional(),
            isBounded: zod.coerce.boolean().optional(),
            isDraggable: zod.coerce.boolean().optional(),
            isResizable: zod.coerce.boolean().optional(),
          })
        ),
      })
    ),
  }),
})
export const reportControllerGetWorkHistoryResponse = zod.array(
  reportControllerGetWorkHistoryResponseItem
)
