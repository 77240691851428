import type React from 'react'
import { useState } from 'react'
import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonImg,
  IonInput,
  IonLabel,
  IonPage,
  IonRippleEffect,
  IonRow,
  useIonRouter,
} from '@ionic/react'
import {
  eyeOffOutline,
  eyeOutline,
  lockOpenOutline,
  mailOutline,
} from 'ionicons/icons'
import {
  authControllerSignInBody,
  type SignInDto,
  useAuthControllerSignInHook,
} from '@/api'
import { useAuthDispatch } from '@/contexts/storage.context'
import { Controller, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { FormErrorMessage } from '@/components/_form-error-message'
import './login.scss'

const Login: React.FC = () => {
  const [show, setShow] = useState(false)
  const signInHook = useAuthControllerSignInHook()
  const {
    handleSubmit,
    control,
    formState: { isValid, isDirty, errors },
    setError,
  } = useForm<SignInDto>({
    mode: 'onBlur',
    defaultValues: {
      email: '',
      password: '',
    },
    resolver: zodResolver(authControllerSignInBody),
  })
  const router = useIonRouter()
  const setToken = useAuthDispatch()

  const onSubmit = async (signInDto: SignInDto) => {
    await signInHook(signInDto)
      .then((value) => {
        setToken(value.accessToken)
        router.push('/tabs/timer')
      })
      .catch(() => {
        setError('password', {
          type: 'custom',
          message: 'Invalid email or password. Please try again.',
        })
      })
  }

  return (
    <IonPage className="login">
      <IonContent scrollY={false} scrollX={false}>
        <div className="login-content">
          <IonGrid className="ion-padding">
            <IonRow className="ion-justify-content-center ion-align-items-center">
              <IonCol size="12" className="ion-text-center">
                <IonImg
                  src="assets/img/trium-novem-logo.svg"
                  style={{ width: 187, height: 89, display: 'inline-block' }}
                />
              </IonCol>
            </IonRow>
          </IonGrid>

          {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
          <form onSubmit={handleSubmit(onSubmit)}>
            <IonGrid>
              <IonRow>
                <IonCol>
                  <IonLabel>
                    <h1>Sign in</h1>
                  </IonLabel>
                </IonCol>
              </IonRow>

              <IonRow>
                <IonCol>
                  <IonLabel>
                    <p>Please enter your credentials to continue.</p>
                  </IonLabel>
                </IonCol>
              </IonRow>

              <IonRow>
                <IonCol>
                  <Controller
                    name="email"
                    control={control}
                    render={({ field }) => (
                      <>
                        <IonInput
                          name={field.name}
                          value={field.value}
                          placeholder="Email"
                          aria-label="Email"
                          className={'custom-input'.concat(
                            isDirty && !isValid ? ` custom-label-error` : ``
                          )}
                          onIonInput={field.onChange}
                          onIonBlur={field.onBlur}
                          type="email"
                        >
                          <IonIcon
                            slot="start"
                            icon={mailOutline}
                            className={'ion-padding-start'}
                          />
                        </IonInput>
                        <FormErrorMessage errors={errors} name={field.name} />
                      </>
                    )}
                  />
                </IonCol>
              </IonRow>

              <IonRow>
                <IonCol>
                  <Controller
                    name="password"
                    control={control}
                    render={({ field }) => (
                      <>
                        <IonInput
                          value={field.value}
                          name={field.name}
                          placeholder="Password"
                          aria-label="Password"
                          type={show ? 'text' : 'password'}
                          className="custom-input"
                          onIonInput={field.onChange}
                          onIonBlur={field.onBlur}
                        >
                          <IonIcon
                            icon={lockOpenOutline}
                            slot="start"
                            className={'ion-padding-start'}
                          />
                          <IonButton
                            fill={'clear'}
                            slot={'end'}
                            size="small"
                            onClick={() => {
                              setShow((prevState) => !prevState)
                            }}
                            tabIndex={-1}
                            className="ion-no-padding ion-no-margin"
                          >
                            <IonIcon
                              icon={show ? eyeOutline : eyeOffOutline}
                              size="small"
                              slot={'icon-only'}
                              className={'ion-padding-end'}
                              color={'dark'}
                            />
                          </IonButton>
                        </IonInput>
                        <FormErrorMessage errors={errors} name={field.name} />
                      </>
                    )}
                  />
                </IonCol>
              </IonRow>
            </IonGrid>
            <IonButton
              type="submit"
              expand="block"
              fill="clear"
              className="custom-item-button"
              mode="md"
              disabled={!isDirty || !isValid}
            >
              <IonLabel>Login</IonLabel>
              <IonRippleEffect></IonRippleEffect>
            </IonButton>
          </form>
        </div>
      </IonContent>
    </IonPage>
  )
}

export default Login
