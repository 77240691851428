import type React from 'react'
import { useRef, useState } from 'react'
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonNote,
  IonPage,
  IonTitle,
  IonToolbar,
  useIonLoading,
  useIonRouter,
} from '@ionic/react'
import { useParams } from 'react-router'
import {
  getTimeTrackingControllerFindAllQueryKey,
  timeTrackingControllerCreateBody,
  type TimeTrackingDto,
  TimeTrackingType,
  useTimeTrackingControllerCreate,
  useTimeTrackingControllerPreview,
} from '@/api'
import * as z from 'zod'
import { type SubmitHandler, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { SessionForm } from '@/components/tabs/sessions/_form'
import { DateTime } from 'luxon'
import { UseFormRequestError } from '@/hooks/use-form-request-error'
import { useQueryClient } from '@tanstack/react-query'
import { useWorkOptions } from '@/hooks/use-work-options'
import type { OverlayEventDetail } from '@ionic/core'

const resolver = timeTrackingControllerCreateBody
  .extend({
    startAt: z.date(),
    endAt: z.date(),
  })
  .refine(
    (data) => {
      return data.startAt < data.endAt
    },
    {
      message: 'End time should after start time',
      path: ['endAt'],
    }
  )
  .refine(
    (data) => {
      return (
        (data.timeType === TimeTrackingType.work &&
          data.project?.uuid != null) ||
        (data.timeType === TimeTrackingType.break &&
          data.project?.uuid === undefined)
      )
    },
    {
      message: 'Project is required',
      path: ['project'],
    }
  )
  .refine(
    (data) => {
      return (
        (data.timeType === TimeTrackingType.work &&
          data.type?.length != null) ||
        (data.timeType === TimeTrackingType.break &&
          data.type?.length === undefined)
      )
    },
    {
      message: 'State is required',
      path: ['type'],
    }
  )
export const SessionAdd: React.FC = () => {
  const { type: timeType } = useParams<{ type: string }>()
  const modal = useRef<HTMLIonModalElement>(null)
  const [loadingPresent, dismiss] = useIonLoading()
  const router = useIonRouter()
  const queryClient = useQueryClient()
  const [isOpen, setIsOpen] = useState(false)
  const [modalMessage, setModalMessage] = useState<React.ReactNode>('')
  const { findWorkOption } = useWorkOptions()
  const { mutateAsync: previewChanges } = useTimeTrackingControllerPreview()
  const { mutateAsync: createTimeTracking } = useTimeTrackingControllerCreate({
    mutation: {
      onSuccess: () => {
        void queryClient.invalidateQueries({
          queryKey: getTimeTrackingControllerFindAllQueryKey(),
        })
        router.push('/tabs/sessions', 'back')
      },
    },
  })
  const form = useForm<TimeTrackingDto, any, TimeTrackingDto>({
    mode: 'onBlur',
    resolver: zodResolver(resolver),
    defaultValues: {
      project: null,
      timeType: timeType as TimeTrackingType,
      isClockOut: false,
      startAt: DateTime.now().minus({ minute: 2 }).startOf('minute').toJSDate(),
      endAt: DateTime.now().minus({ minute: 1 }).startOf('minute').toJSDate(),
    },
  })
  const handleFormRequestError = UseFormRequestError<TimeTrackingDto>({
    setError: form.setError,
  })

  const onSubmit: SubmitHandler<TimeTrackingDto> = async (data) => {
    void loadingPresent()
    try {
      const result = await previewChanges({ data })
      if (result.hasConflict) {
        const AffectedRecordsContent = (
          <IonList lines="none">
            <IonItem>
              <IonLabel color="danger">
                Adding this record will affect the following record(s). Do you
                want to proceed?
              </IonLabel>
            </IonItem>
            {result.affectedRecords.map((item, index) => (
              <IonItem key={item.uuid ?? index}>
                <IonLabel>
                  <strong>
                    {item.timeType === TimeTrackingType.work
                      ? `${item.project?.name} - ${findWorkOption(item.type).title}`
                      : 'Break Time'}
                  </strong>
                  <br />
                  <IonNote className="ion-text-wrap">
                    {DateTime.fromJSDate(item.startAt).toFormat('HH:mm a')} -
                    {item.endAt != null
                      ? DateTime.fromJSDate(item.endAt).toFormat('HH:mm a')
                      : 'Present'}
                  </IonNote>
                </IonLabel>
              </IonItem>
            ))}
          </IonList>
        )
        setModalMessage(AffectedRecordsContent)
        setIsOpen(true)
      } else {
        createTimeTracking({ data })
          .catch(handleFormRequestError)
          .finally(() => {
            void dismiss()
          })
      }
    } finally {
      void dismiss()
    }
  }

  function confirm() {
    modal.current?.dismiss(form.getValues(), 'confirm')
  }

  function onWillDismiss(ev: CustomEvent<OverlayEventDetail>) {
    setIsOpen(false)
    if (ev.detail.role === 'confirm') {
      void loadingPresent()
      createTimeTracking({ data: ev.detail.data })
        .catch(handleFormRequestError)
        .finally(() => {
          void dismiss()
        })
    }
  }

  return (
    <IonPage className="sessions">
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton></IonBackButton>
          </IonButtons>
          <IonTitle className={'ion-text-center'}>Sessions - New</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <SessionForm form={form} onSubmit={onSubmit} />
        <IonModal isOpen={isOpen} ref={modal} onWillDismiss={onWillDismiss}>
          <IonHeader>
            <IonToolbar>
              <IonButtons slot="start">
                <IonButton
                  color="medium"
                  onClick={() => modal.current?.dismiss()}
                >
                  Cancel
                </IonButton>
              </IonButtons>
              <IonTitle>Notice</IonTitle>
              <IonButtons slot="end">
                <IonButton onClick={() => confirm()} strong={true}>
                  Confirm
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent className="ion-padding">{modalMessage}</IonContent>
        </IonModal>
      </IonContent>
    </IonPage>
  )
}
