import type React from 'react'
import { useCallback, useRef } from 'react'
import {
  Controller,
  type SubmitHandler,
  type UseFormReturn,
} from 'react-hook-form'
import type { Project } from '@/api'
import {
  type TimeTrackingDto,
  TimeTrackingType,
  useProjectsControllerFindAll,
  WorkType,
} from '@/api'
import {
  type DatetimeChangeEventDetail,
  IonButton,
  IonCol,
  IonDatetime,
  IonDatetimeButton,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonRadio,
  IonRadioGroup,
  IonRow,
} from '@ionic/react'
import {
  businessOutline,
  carOutline,
  homeOutline,
  locationOutline,
} from 'ionicons/icons'

import './_form.scss'
import { DateTime } from 'luxon'
import { type IonDatetimeCustomEvent } from '@ionic/core/dist/types/components'
import { FormErrorMessage } from '@/components/_form-error-message'
import AppTypeahead from '@/AppTypeahead'
import type { TypeheadItem } from '@/contracts/typehead-item'
import { useBoolean } from 'usehooks-ts'

export const SessionForm: React.FC<{
  form: UseFormReturn<TimeTrackingDto, any, TimeTrackingDto>
  onSubmit: SubmitHandler<TimeTrackingDto>
}> = ({
  form: {
    control,
    handleSubmit,
    getValues,
    formState: { errors },
    setValue,
  },
  onSubmit,
}) => {
  const { data: projects, isLoading } = useProjectsControllerFindAll(
    {},
    {
      query: {
        select: (data) => {
          return data.data.map((d: Project) => {
            return {
              value: d,
              title: d.name,
            } as TypeheadItem<Project>
          })
        },
      },
    }
  )
  const modal = useRef<HTMLIonModalElement>(null)
  const { value: isOpen, setTrue: open, setFalse: close } = useBoolean(false)
  const onDateChange = useCallback(
    (e: IonDatetimeCustomEvent<DatetimeChangeEventDetail>) => {
      const date = DateTime.fromISO(e.detail.value as string)
      setValue(
        'startAt',
        DateTime.fromJSDate(getValues('startAt'))
          .set({
            year: date.year,
            month: date.month,
            day: date.day,
          })
          .toJSDate()
      )
      setValue(
        'endAt',
        DateTime.fromJSDate(getValues('endAt'))
          .set({
            year: date.year,
            month: date.month,
            day: date.day,
          })
          .toJSDate()
      )
    },
    []
  )
  if (isLoading) {
    return <></>
  }
  return (
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    <form id="time-tracking-form" onSubmit={handleSubmit(onSubmit)}>
      <IonGrid>
        {getValues('timeType') === TimeTrackingType.work && (
          <>
            <IonRow>
              <IonCol>
                <IonLabel>
                  <h2>Project</h2>
                </IonLabel>
              </IonCol>
            </IonRow>
            <IonRow id={'project-selector'}>
              <IonCol>
                <Controller
                  name="project"
                  control={control}
                  render={({ field }) => (
                    <>
                      <IonList>
                        <IonItem
                          button={true}
                          detail={false}
                          onClick={() => open()}
                          lines={'none'}
                          className={'radio-item'}
                        >
                          <IonLabel>Select Project</IonLabel>
                          <div slot="end" id="selected-fruits">
                            {(field.value as Project)?.name}
                          </div>
                        </IonItem>
                      </IonList>
                      <FormErrorMessage errors={errors} name={field.name} />
                      <IonModal isOpen={isOpen} ref={modal}>
                        <AppTypeahead
                          title="Select project"
                          items={projects ?? []}
                          selectedItems={field.value as Project}
                          onSelectionCancel={() => {
                            modal.current?.dismiss()
                            close()
                          }}
                          compareWith={(c, v) => c?.uuid === v?.uuid}
                          onSelectionChange={(s) => {
                            field.onChange(s)
                            close()
                            modal.current?.dismiss()
                          }}
                        />
                      </IonModal>
                    </>
                  )}
                />
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonLabel>
                  <h2>State</h2>
                </IonLabel>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <Controller
                  name="type"
                  control={control}
                  render={({ field }) => (
                    <>
                      <IonRadioGroup
                        onIonChange={field.onChange}
                        value={field.value}
                      >
                        <IonRow>
                          <IonCol>
                            <IonItem lines={'none'} className={'radio-item'}>
                              <IonIcon
                                icon={homeOutline}
                                className={'radio-icon'}
                                slot={'start'}
                              ></IonIcon>
                              <IonRadio
                                value={WorkType.home_work}
                                justify="space-between"
                                labelPlacement={'start'}
                              >
                                Home office
                              </IonRadio>
                            </IonItem>
                          </IonCol>
                          <IonCol>
                            <IonItem lines={'none'} className={'radio-item'}>
                              <IonIcon
                                icon={businessOutline}
                                className={'radio-icon'}
                                slot={'start'}
                              ></IonIcon>
                              <IonRadio
                                value={WorkType.office_work}
                                justify="space-between"
                              >
                                Main office
                              </IonRadio>
                            </IonItem>
                          </IonCol>
                        </IonRow>
                        <IonRow>
                          <IonCol>
                            <IonItem lines={'none'} className={'radio-item'}>
                              <IonIcon
                                icon={locationOutline}
                                slot={'start'}
                                className={'radio-icon'}
                              ></IonIcon>
                              <IonRadio
                                value={WorkType.site_work}
                                justify="space-between"
                              >
                                Project site
                              </IonRadio>
                            </IonItem>
                          </IonCol>
                          <IonCol>
                            <IonItem lines={'none'} className={'radio-item'}>
                              <IonIcon
                                icon={carOutline}
                                slot={'start'}
                                className={'radio-icon'}
                              ></IonIcon>
                              <IonRadio
                                value={WorkType.transit}
                                justify="space-between"
                              >
                                In transit
                              </IonRadio>
                            </IonItem>
                          </IonCol>
                        </IonRow>
                      </IonRadioGroup>

                      <FormErrorMessage errors={errors} name={field.name} />
                    </>
                  )}
                />
              </IonCol>
            </IonRow>
          </>
        )}
        <IonRow>
          <IonCol>
            <IonLabel>
              <h2>Session Date</h2>
            </IonLabel>
          </IonCol>
        </IonRow>
        <IonRow className={'ion-justify-content-center'}>
          <IonCol size="12">
            <IonDatetimeButton datetime="date"></IonDatetimeButton>

            <IonModal keepContentsMounted={true}>
              <IonDatetime
                id="date"
                presentation="date"
                min={DateTime.now().minus({ day: 6 }).toISODate()}
                max={DateTime.now().toISODate()}
                value={DateTime.fromJSDate(getValues('startAt')).toISODate()}
                onIonChange={onDateChange}
              ></IonDatetime>
            </IonModal>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol size="6">
            <IonLabel>
              <h2>Start Time</h2>
            </IonLabel>
          </IonCol>
          <IonCol size="6">
            <IonLabel>
              <h2>End Time</h2>
            </IonLabel>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol size="6">
            <Controller
              name="startAt"
              control={control}
              render={({ field }) => (
                <>
                  <IonLabel>
                    <IonDatetimeButton
                      datetime="start-time"
                      color="primary"
                    ></IonDatetimeButton>
                  </IonLabel>
                  <IonModal keepContentsMounted={true}>
                    <IonDatetime
                      name={field.name}
                      id="start-time"
                      presentation="time"
                      value={DateTime.fromJSDate(field.value).toISO()}
                      onIonChange={(e) => {
                        field.onChange(
                          DateTime.fromISO(e.detail.value as string).toJSDate()
                        )
                      }}
                    ></IonDatetime>
                  </IonModal>
                  <FormErrorMessage errors={errors} name={field.name} />
                </>
              )}
            />
          </IonCol>
          <IonCol size="6">
            <Controller
              name="endAt"
              control={control}
              render={({ field }) => (
                <>
                  <IonLabel>
                    <IonDatetimeButton datetime="end-time"></IonDatetimeButton>
                  </IonLabel>
                  <IonModal keepContentsMounted={true}>
                    <IonDatetime
                      name={field.name}
                      id="end-time"
                      presentation="time"
                      value={DateTime.fromJSDate(field.value).toISO()}
                      onIonChange={(e) => {
                        field.onChange(
                          DateTime.fromISO(e.detail.value as string).toJSDate()
                        )
                      }}
                    ></IonDatetime>
                  </IonModal>
                  <FormErrorMessage errors={errors} name={field.name} />
                </>
              )}
            />
          </IonCol>
        </IonRow>
        <IonRow className="ion-margin-top ion-padding-top">
          <IonCol size="12">
            <IonButton type="submit" expand="block">
              Save
            </IonButton>
          </IonCol>
        </IonRow>
      </IonGrid>
    </form>
  )
}
