import type React from 'react'
import {
  IonAlert,
  IonButton,
  IonCol,
  IonGrid,
  IonIcon,
  IonRow,
  IonText,
} from '@ionic/react'
import { TimerButtonState } from '@/reducers/timer.reducer'

import '../../main.css'
import { pause, play } from 'ionicons/icons'

type HandlePause = () => void
type HandleStop = () => Promise<void>
type HandleStart = () => Promise<void>
type HandleResume = () => Promise<void>
type TimerString = string

type TimerButtonProps = {
  handleStop: HandleStop
  handleStart: HandleStart
  handlePause: HandlePause
  handleResume: HandleResume
  currentState: TimerButtonState
  timerString: TimerString
}
type TimerButtonStoppedProps = {
  handleStart: HandleStart
}
type TimerButtonRunningProps = {
  handleStop: HandleStop
  handlePause: HandlePause
  timerString: TimerString
}
type TimerButtonPausedProps = {
  handleStop: HandleStop
  handleResume: HandleResume
  timerString: TimerString
}

const TimerButtonDefault: React.FC = () => {
  return (
    <IonButton id="start-button" className={'start-concentric'} disabled={true}>
      <IonGrid>
        <IonRow className={'ion-justify-content-center'}>
          <IonCol size={'auto'}>
            <IonText color={'dark'}>...</IonText>
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonButton>
  )
}

const TimerButtonStopped: React.FC<TimerButtonStoppedProps> = ({
  handleStart,
}) => {
  return (
    <IonButton
      id="start-button"
      className={'start-concentric'}
      onClick={() => {
        void handleStart()
      }}
    >
      <IonGrid>
        <IonRow>
          <IonCol>
            <IonIcon icon={play} size={'large'} />
          </IonCol>
        </IonRow>
        <IonRow className={'ion-justify-content-center'}>
          <IonCol size={'auto'}>
            <IonText color={'dark'}>Start</IonText>
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonButton>
  )
}

const TimerButtonRunning: React.FC<TimerButtonRunningProps> = ({
  handlePause,
  handleStop,
  timerString,
}) => {
  return (
    <IonButton id="start-button" className={'start-concentric'}>
      <IonAlert
        trigger={'start-button'}
        cssClass={'custom-alert'}
        header={'Want to take a break?'}
        message={'By pausing the timer you will start your break time.'}
        buttons={[
          { text: 'Take a break', handler: handlePause },
          { text: 'Cancel', cssClass: 'bold' },
          { text: 'End work', cssClass: 'danger', handler: handleStop },
        ]}
      />
      <IonGrid>
        <IonRow>
          <IonCol>
            <IonIcon icon={pause} size={'large'} />
          </IonCol>
        </IonRow>
        <IonRow className={'ion-justify-content-center'}>
          <IonCol size={'auto'}>
            <IonText color={'custom-01'}>{timerString}</IonText>
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonButton>
  )
}

const TimerButtonPaused: React.FC<TimerButtonPausedProps> = ({
  handleResume,
  handleStop,
  timerString,
}) => {
  return (
    <IonButton id="start-button" className={'start-concentric'}>
      <IonAlert
        cssClass={'custom-alert'}
        trigger={'start-button'}
        header={'Want to resume work?'}
        message={'By playing the timer you will start your work time again.'}
        buttons={[
          { text: 'Resume work', handler: handleResume },
          { text: 'Cancel', cssClass: 'bold' },
          { text: 'End work', cssClass: 'danger', handler: handleStop },
        ]}
      />
      <IonGrid>
        <IonRow>
          <IonCol>
            <IonIcon icon={play} size={'large'} />
          </IonCol>
        </IonRow>
        <IonRow className={'ion-justify-content-center'}>
          <IonCol size={'auto'}>
            <IonText color={'custom-02'}>{timerString}</IonText>
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonButton>
  )
}

export const TimerButton: React.FC<TimerButtonProps> = ({
  currentState,
  handleStop,
  handleStart,
  handlePause,
  handleResume,
  timerString,
}) => {
  switch (currentState) {
    case TimerButtonState.Stopped:
      return <TimerButtonStopped handleStart={handleStart} />
    case TimerButtonState.Running:
      return (
        <TimerButtonRunning
          handleStop={handleStop}
          handlePause={handlePause}
          timerString={timerString}
        />
      )

    case TimerButtonState.Paused:
      return (
        <TimerButtonPaused
          handleStop={handleStop}
          handleResume={handleResume}
          timerString={timerString}
        />
      )
    default:
      return <TimerButtonDefault />
  }
}
