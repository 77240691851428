import type { NotificationActionType } from '@/enums/notification-action-type.enum'
import type { NotificationId } from '@/enums/notification-id.enum'
import type {
  PendingResult,
  ScheduleResult,
} from '@capacitor/local-notifications'
import { LocalNotifications } from '@capacitor/local-notifications'
import { useCallback, useEffect } from 'react'
import type {
  ActionPerformed,
  Schedule,
} from '@capacitor/local-notifications/dist/esm/definitions'
import { useEventCallback } from 'usehooks-ts'

interface NotificationHookConfig {
  onListen: (action: ActionPerformed) => void
}

interface NotificationOptions {
  // This notification related to which action
  actionTypeId: NotificationActionType
  // Unique ID for cancel notification
  id: NotificationId
  title: string
  body: string
  schedule: Schedule
  extra?: any
}

export const useLocalNotification = (config: NotificationHookConfig) => {
  const send = useCallback(
    async (options: NotificationOptions): Promise<ScheduleResult> => {
      return await LocalNotifications.schedule({
        notifications: [
          {
            id: options.id,
            actionTypeId: options.actionTypeId,
            title: options.title,
            body: options.body,
            schedule: options.schedule,
            extra: options.extra,
          },
        ],
      })
    },
    [LocalNotifications]
  )

  const getPending = useCallback(async (): Promise<PendingResult> => {
    return await LocalNotifications.getPending()
  }, [LocalNotifications])

  const cancel = useCallback(
    async (id: number): Promise<void> => {
      await LocalNotifications.cancel({ notifications: [{ id }] })
    },
    [LocalNotifications]
  )
  const onNotificationAction = useEventCallback(config.onListen)

  useEffect(() => {
    LocalNotifications.addListener(
      'localNotificationActionPerformed',
      onNotificationAction
    )
  }, [onNotificationAction])

  return { send, cancel, getPending }
}
