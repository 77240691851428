import type React from 'react'
import { useCallback, useMemo } from 'react'
import {
  IonAlert,
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonItem,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
  IonLabel,
  IonList,
  IonNote,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonRow,
  IonSegment,
  IonSegmentButton,
  IonTitle,
  IonToolbar,
  type RefresherEventDetail,
  useIonAlert,
  useIonRouter,
  useIonToast,
  useIonViewWillEnter,
} from '@ionic/react'
import 'swiper/css/pagination'
import './sessions.css'
import { DateTime, Interval } from 'luxon'
import {
  getTimeTrackingControllerFindAllQueryKey,
  TimeTrackingType,
  useTimeTrackingControllerDelete,
  useTimeTrackingControllerFindAllInfinite,
} from '@/api'
import { createOutline, trashOutline } from 'ionicons/icons'
import { type IonRefresherCustomEvent } from '@ionic/core/dist/types/components'
import { useTimeSessions } from '@/hooks/useTimeSessions'
import { useWorkOptions } from '@/hooks/use-work-options'
import { useQueryClient } from '@tanstack/react-query'

export const Sessions: React.FC = () => {
  const { selectedDate, setSelectedDate } = useTimeSessions()
  const { findWorkOption } = useWorkOptions()
  const router = useIonRouter()
  const [toastPresent] = useIonToast()
  const [presentAlert] = useIonAlert()
  const queryClient = useQueryClient()
  const dates = useMemo(() => {
    return Interval.before(DateTime.now().plus({ day: 1 }).startOf('day'), {
      day: 7,
    })
      .splitBy({ day: 1 })
      .map((d) => d.start)
  }, [])
  const selectedDateStart = DateTime.fromISO(selectedDate as string)
    .startOf('day')
    .toUTC()
    .toISO()
  const selectedDateEnd = DateTime.fromISO(selectedDate as string)
    .endOf('day')
    .toUTC()
    .toISO()

  const filter = [`$btw:${selectedDateStart},${selectedDateEnd}`]
  if (DateTime.now().toISODate() === selectedDate) {
    filter.push(`$or:$null`)
  }
  const { data, fetchNextPage } = useTimeTrackingControllerFindAllInfinite(
    {
      'filter.startAt': filter,
      'filter.endAt': filter,
      limit: 100,
    },
    {
      query: {
        initialPageParam: 1,
        getNextPageParam: (lastPage) => {
          return lastPage.meta.currentPage < lastPage.meta.totalPages
            ? lastPage.meta.currentPage + 1
            : undefined
        },
      },
    }
  )

  const { mutateAsync: timeTrackingControllerDeleteHook } =
    useTimeTrackingControllerDelete({
      mutation: {
        onSuccess: async () => {
          void queryClient.invalidateQueries({
            queryKey: getTimeTrackingControllerFindAllQueryKey(),
          })
        },
      },
    })

  const deleteItem = useCallback((id: string) => {
    timeTrackingControllerDeleteHook({ pathParams: { id } }).catch((e) => {
      const messages = e.response?.data.message
      if (typeof messages === 'string') {
        void toastPresent({
          message: messages,
          color: 'danger',
          duration: 5000,
          position: 'middle',
          buttons: [
            {
              text: 'Dismiss',
              role: 'cancel',
            },
          ],
        })
        return
      }
    })
  }, [])
  useIonViewWillEnter(() => {
    handleRefresh()
  })
  const handleRefresh = (
    event?: IonRefresherCustomEvent<RefresherEventDetail>
  ) => {
    queryClient
      .invalidateQueries({
        queryKey: getTimeTrackingControllerFindAllQueryKey(),
      })
      .then(() => {
        event?.detail.complete()
      })
  }
  if (!data) {
    return null
  }
  return (
    <IonPage className="sessions">
      <IonHeader>
        <IonToolbar>
          <IonTitle className={'ion-text-center'}>Sessions</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>
        <IonList lines={'none'}>
          <IonItem>
            <h1 className={'ion-no-margin'}>
              {selectedDate !== undefined &&
                DateTime.fromISO(selectedDate as string).toFormat('LLLL, yyyy')}
            </h1>
          </IonItem>
          <IonItem>
            <IonSegment
              scrollable
              value={selectedDate}
              onIonChange={(v) => {
                setSelectedDate(v.detail.value).then()
              }}
            >
              {dates.map((date) => (
                <IonSegmentButton
                  key={date?.toISODate()}
                  value={date?.toISODate()}
                >
                  <IonLabel>{date?.toFormat('dd')}</IonLabel>
                  <IonLabel>{date?.toFormat('ccc')}</IonLabel>
                </IonSegmentButton>
              ))}
            </IonSegment>
          </IonItem>
          <IonItem>
            <IonGrid className="ion-no-padding">
              <IonRow className="ion-align-items-center ion-justify-content-between">
                <IonCol className="ion-no-padding">
                  <h2 className="ion-no-margin">Work Sessions</h2>
                </IonCol>
                <IonCol size="auto" className="ion-no-padding">
                  <IonButton id="add-session-alert">Add Session</IonButton>
                  <IonAlert
                    trigger="add-session-alert"
                    header="Add New Session?"
                    message="Please choose session type that you want to add manually"
                    buttons={[
                      {
                        text: 'Add Work Session',
                        role: 'confirm-work',
                        handler: () => {
                          router.push('/tabs/sessions/new/work')
                        },
                      },
                      {
                        text: 'Add Break Session',
                        role: 'confirm-break',
                        handler: () => {
                          router.push('/tabs/sessions/new/break')
                        },
                      },
                    ]}
                  ></IonAlert>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonItem>
        </IonList>
        <IonList className="time-tracking-records">
          {data.pages.map(({ data: items }) =>
            items.map((item) => (
              <IonItemSliding key={item.uuid}>
                <IonItem
                  button
                  detail
                  className={item.timeType}
                  routerLink={`/tabs/sessions/${item.uuid}/edit`}
                >
                  <IonLabel>
                    <strong>
                      {item.timeType === TimeTrackingType.work
                        ? `${item.project?.name} - ${findWorkOption(item.type).title}`
                        : 'Break Time'}
                    </strong>
                    <IonNote className="ion-text-wrap">
                      {DateTime.fromJSDate(item.startAt).toFormat('HH:mm a')} -
                      {item.endAt != null
                        ? DateTime.fromJSDate(item.endAt).toFormat('HH:mm a')
                        : 'Present'}
                    </IonNote>
                  </IonLabel>
                </IonItem>
                <IonItemOptions slot="end">
                  <IonItemOption
                    color="primary"
                    routerLink={`/tabs/sessions/${item.uuid}/edit`}
                  >
                    <IonIcon slot="icon-only" icon={createOutline}></IonIcon>
                  </IonItemOption>
                  <IonItemOption
                    color="danger"
                    type="button"
                    onClick={() => {
                      void presentAlert({
                        header: 'Confirm Delete',
                        message:
                          'Are you sure you want to delete this session?',
                        buttons: [
                          {
                            text: 'Cancel',
                            role: 'cancel',
                          },
                          {
                            text: 'OK',
                            role: 'confirm',
                            handler: () => {
                              deleteItem(item.uuid)
                            },
                          },
                        ],
                      })
                    }}
                  >
                    <IonIcon slot="icon-only" icon={trashOutline}></IonIcon>
                  </IonItemOption>
                </IonItemOptions>
              </IonItemSliding>
            ))
          )}
        </IonList>
        <IonInfiniteScroll
          onIonInfinite={(ev) => {
            fetchNextPage().then(() => void ev.target.complete())
          }}
        >
          <IonInfiniteScrollContent></IonInfiniteScrollContent>
        </IonInfiniteScroll>
      </IonContent>
    </IonPage>
  )
}
