import { useHistory } from 'react-router'
import { useEffect } from 'react'
import type { URLOpenListenerEvent } from '@capacitor/app'
import { App as CapacitorApp } from '@capacitor/app'

const AppUrlListener: React.FC<any> = () => {
  const history = useHistory()
  useEffect(() => {
    CapacitorApp.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      // Example url: https://app.triumnovem.de/tabs/tab2
      // slug = /tabs/tab2
      const slug = event.url.split('app.triumnovem.de').pop()
      if (slug) {
        history.push(slug)
      }
      // If no match, do nothing - let regular routing
      // logic take over
    })
  }, [])

  return null
}

export default AppUrlListener
