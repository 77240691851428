import { z as zod } from 'zod'

/**
 * @summary Start new time tracking record with selected type and project
 */
export const timeTrackingControllerStartHeader = zod.object({
  'x-time-zone': zod.coerce.string(),
})

export const timeTrackingControllerStartBody = zod.object({
  type: zod.enum(['home_work', 'office_work', 'site_work', 'transit']),
  project: zod.object({
    uuid: zod.coerce.string().uuid(),
  }),
})

export const timeTrackingControllerStartResponseProjectSerialMax = 50
export const timeTrackingControllerStartResponseProjectCompletionPercentageMin = 0

export const timeTrackingControllerStartResponseProjectCompletionPercentageMax = 100
export const timeTrackingControllerStartResponseProjectRoadmapsItemTagNameMax = 50
export const timeTrackingControllerStartResponseProjectRoadmapsItemColorMin = 7

export const timeTrackingControllerStartResponseProjectRoadmapsItemColorMax = 7
export const timeTrackingControllerStartResponseProjectRoadmapsItemItemsItemColorMin = 7

export const timeTrackingControllerStartResponseProjectRoadmapsItemItemsItemColorMax = 7
export const timeTrackingControllerStartResponseProjectProjectManagersItemFirstNameMax = 100
export const timeTrackingControllerStartResponseProjectProjectManagersItemLastNameMax = 100
export const timeTrackingControllerStartResponseProjectProjectManagersItemAddressLatMin =
  -90

export const timeTrackingControllerStartResponseProjectProjectManagersItemAddressLatMax = 90
export const timeTrackingControllerStartResponseProjectProjectManagersItemAddressLonMin =
  -180

export const timeTrackingControllerStartResponseProjectProjectManagersItemAddressLonMax = 180
export const timeTrackingControllerStartResponseProjectProjectManagersItemLeaveTypesItemNameMax = 255
export const timeTrackingControllerStartResponseProjectProjectManagersItemLeaveTypesItemDescriptionMax = 1024
export const timeTrackingControllerStartResponseProjectProjectManagersItemLeaveTypesItemColorMin = 7

export const timeTrackingControllerStartResponseProjectProjectManagersItemLeaveTypesItemColorMax = 7
export const timeTrackingControllerStartResponseProjectTotalAllocationsMin = 0
export const timeTrackingControllerStartResponseProjectAddressLatMin = -90

export const timeTrackingControllerStartResponseProjectAddressLatMax = 90
export const timeTrackingControllerStartResponseProjectAddressLonMin = -180

export const timeTrackingControllerStartResponseProjectAddressLonMax = 180
export const timeTrackingControllerStartResponseUserFirstNameMax = 100
export const timeTrackingControllerStartResponseUserLastNameMax = 100
export const timeTrackingControllerStartResponseUserAddressLatMin = -90

export const timeTrackingControllerStartResponseUserAddressLatMax = 90
export const timeTrackingControllerStartResponseUserAddressLonMin = -180

export const timeTrackingControllerStartResponseUserAddressLonMax = 180
export const timeTrackingControllerStartResponseUserLeaveTypesItemNameMax = 255
export const timeTrackingControllerStartResponseUserLeaveTypesItemDescriptionMax = 1024
export const timeTrackingControllerStartResponseUserLeaveTypesItemColorMin = 7

export const timeTrackingControllerStartResponseUserLeaveTypesItemColorMax = 7

export const timeTrackingControllerStartResponse = zod.object({
  uuid: zod.coerce.string().uuid(),
  startAt: zod.coerce.string().datetime(),
  endAt: zod.coerce.string().datetime().optional(),
  project: zod
    .object({
      serial: zod.coerce
        .string()
        .max(timeTrackingControllerStartResponseProjectSerialMax)
        .optional(),
      projectSize: zod.coerce.number(),
      guaranteedUntil: zod.coerce.date().optional(),
      projectType: zod.object({
        name: zod.coerce.string(),
        uuid: zod.coerce.string(),
      }),
      completionPercentage: zod.coerce
        .number()
        .min(timeTrackingControllerStartResponseProjectCompletionPercentageMin)
        .max(timeTrackingControllerStartResponseProjectCompletionPercentageMax)
        .optional(),
      startedAt: zod.coerce.date().optional(),
      estimatedEndAt: zod.coerce.date().optional(),
      endAt: zod.coerce.date().optional(),
      status: zod.enum(['Active', 'In Active', 'Potential']).optional(),
      milestones: zod
        .array(
          zod.object({
            uuid: zod.coerce.string().uuid(),
            date: zod.coerce.date(),
            iconUrl: zod.coerce.string().nullable(),
            description: zod.coerce.string().nullable(),
            projectUuid: zod.coerce.string().uuid(),
            createdAt: zod.coerce.string().datetime(),
            updatedAt: zod.coerce.string().datetime(),
            project: zod.any(),
          })
        )
        .optional(),
      roadmaps: zod
        .array(
          zod.object({
            uuid: zod.coerce.string().uuid(),
            startAt: zod.coerce.date(),
            endAt: zod.coerce.date(),
            isVisible: zod.coerce.boolean().optional(),
            project: zod.any(),
            tag: zod.object({
              uuid: zod.coerce.string().uuid(),
              parent: zod.any().nullish(),
              name: zod.coerce
                .string()
                .max(
                  timeTrackingControllerStartResponseProjectRoadmapsItemTagNameMax
                ),
              companyType: zod
                .enum(['Client', 'Contractor', 'Other'])
                .optional(),
            }),
            color: zod.coerce
              .string()
              .min(
                timeTrackingControllerStartResponseProjectRoadmapsItemColorMin
              )
              .max(
                timeTrackingControllerStartResponseProjectRoadmapsItemColorMax
              )
              .nullish(),
            items: zod.array(
              zod.object({
                uuid: zod.coerce.string().uuid(),
                color: zod.coerce
                  .string()
                  .min(
                    timeTrackingControllerStartResponseProjectRoadmapsItemItemsItemColorMin
                  )
                  .max(
                    timeTrackingControllerStartResponseProjectRoadmapsItemItemsItemColorMax
                  )
                  .nullish(),
                startAt: zod.coerce.date(),
                endAt: zod.coerce.date(),
                description: zod.coerce.string().nullable(),
                roadmap: zod.any().optional(),
              })
            ),
          })
        )
        .optional(),
      projectManagers: zod.array(
        zod.object({
          uuid: zod.coerce.string().uuid(),
          email: zod.coerce.string().email(),
          firstName: zod.coerce
            .string()
            .max(
              timeTrackingControllerStartResponseProjectProjectManagersItemFirstNameMax
            ),
          lastName: zod.coerce
            .string()
            .max(
              timeTrackingControllerStartResponseProjectProjectManagersItemLastNameMax
            ),
          dateOfBirth: zod.coerce.date().optional(),
          phoneNumber: zod.coerce.string().optional(),
          status: zod.enum(['Active', 'Terminated']),
          permissions: zod.array(
            zod.enum(['admin', 'human-resource', 'user']).optional()
          ),
          role: zod
            .object({
              uuid: zod.coerce.string().uuid(),
              name: zod.coerce.string(),
            })
            .optional(),
          department: zod
            .object({
              uuid: zod.coerce.string().uuid(),
              name: zod.coerce.string(),
            })
            .optional(),
          createdAt: zod.coerce.string().datetime(),
          updatedAt: zod.coerce.string().datetime(),
          address: zod
            .object({
              geoLocation: zod
                .object({
                  x: zod.coerce.number().optional(),
                  y: zod.coerce.number().optional(),
                })
                .nullish(),
              lat: zod.coerce
                .number()
                .min(
                  timeTrackingControllerStartResponseProjectProjectManagersItemAddressLatMin
                )
                .max(
                  timeTrackingControllerStartResponseProjectProjectManagersItemAddressLatMax
                )
                .optional(),
              lon: zod.coerce
                .number()
                .min(
                  timeTrackingControllerStartResponseProjectProjectManagersItemAddressLonMin
                )
                .max(
                  timeTrackingControllerStartResponseProjectProjectManagersItemAddressLonMax
                )
                .optional(),
              uuid: zod.coerce.string(),
              country: zod.coerce.string().optional(),
              city: zod.coerce.string().optional(),
              street: zod.coerce.string().optional(),
              postcode: zod.coerce.string().optional(),
            })
            .optional(),
          profilePicture: zod.coerce.string(),
          fullName: zod.coerce.string(),
          leaveTypes: zod.array(
            zod.object({
              uuid: zod.coerce.string().uuid(),
              name: zod.coerce
                .string()
                .min(1)
                .max(
                  timeTrackingControllerStartResponseProjectProjectManagersItemLeaveTypesItemNameMax
                ),
              description: zod.coerce
                .string()
                .max(
                  timeTrackingControllerStartResponseProjectProjectManagersItemLeaveTypesItemDescriptionMax
                )
                .optional(),
              color: zod.coerce
                .string()
                .min(
                  timeTrackingControllerStartResponseProjectProjectManagersItemLeaveTypesItemColorMin
                )
                .max(
                  timeTrackingControllerStartResponseProjectProjectManagersItemLeaveTypesItemColorMax
                )
                .optional(),
              allowedPerYear: zod.object({
                days: zod.coerce.number(),
                hours: zod.coerce.number(),
                minutes: zod.coerce.number(),
                seconds: zod.coerce.number(),
                milliseconds: zod.coerce.number(),
              }),
              isFreebie: zod.coerce.boolean().optional(),
              isWithAttachment: zod.coerce.boolean().optional(),
            })
          ),
          layouts: zod.array(
            zod.object({
              uuid: zod.coerce.string(),
              user: zod.any(),
              layouts: zod.array(
                zod.object({
                  i: zod.coerce.string(),
                  x: zod.coerce.number(),
                  y: zod.coerce.number(),
                  w: zod.coerce.number(),
                  h: zod.coerce.number(),
                  minW: zod.coerce.number().optional(),
                  maxW: zod.coerce.number().optional(),
                  minH: zod.coerce.number().optional(),
                  maxH: zod.coerce.number().optional(),
                  static: zod.coerce.boolean().optional(),
                  isBounded: zod.coerce.boolean().optional(),
                  isDraggable: zod.coerce.boolean().optional(),
                  isResizable: zod.coerce.boolean().optional(),
                })
              ),
            })
          ),
        })
      ),
      totalAllocations: zod.coerce
        .number()
        .min(timeTrackingControllerStartResponseProjectTotalAllocationsMin),
      uuid: zod.coerce.string(),
      name: zod.coerce.string(),
      address: zod.object({
        geoLocation: zod
          .object({
            x: zod.coerce.number().optional(),
            y: zod.coerce.number().optional(),
          })
          .nullish(),
        lat: zod.coerce
          .number()
          .min(timeTrackingControllerStartResponseProjectAddressLatMin)
          .max(timeTrackingControllerStartResponseProjectAddressLatMax)
          .optional(),
        lon: zod.coerce
          .number()
          .min(timeTrackingControllerStartResponseProjectAddressLonMin)
          .max(timeTrackingControllerStartResponseProjectAddressLonMax)
          .optional(),
        uuid: zod.coerce.string(),
        country: zod.coerce.string().optional(),
        city: zod.coerce.string().optional(),
        street: zod.coerce.string().optional(),
        postcode: zod.coerce.string().optional(),
      }),
      createdAt: zod.coerce.string().datetime(),
      updatedAt: zod.coerce.string().datetime(),
    })
    .optional(),
  type: zod
    .enum(['home_work', 'office_work', 'site_work', 'transit'])
    .optional(),
  isClockOut: zod.coerce.boolean().optional(),
  status: zod.enum(['approved', 'pending', 'draft']),
  timeType: zod.enum(['work', 'break', 'time-off']).optional(),
  deletedAt: zod.coerce.string().datetime(),
  createdAt: zod.coerce.string().datetime(),
  updatedAt: zod.coerce.string().datetime(),
  duration: zod.coerce.number(),
  user: zod.object({
    uuid: zod.coerce.string().uuid(),
    email: zod.coerce.string().email(),
    firstName: zod.coerce
      .string()
      .max(timeTrackingControllerStartResponseUserFirstNameMax),
    lastName: zod.coerce
      .string()
      .max(timeTrackingControllerStartResponseUserLastNameMax),
    dateOfBirth: zod.coerce.date().optional(),
    phoneNumber: zod.coerce.string().optional(),
    status: zod.enum(['Active', 'Terminated']),
    permissions: zod.array(
      zod.enum(['admin', 'human-resource', 'user']).optional()
    ),
    role: zod
      .object({
        uuid: zod.coerce.string().uuid(),
        name: zod.coerce.string(),
      })
      .optional(),
    department: zod
      .object({
        uuid: zod.coerce.string().uuid(),
        name: zod.coerce.string(),
      })
      .optional(),
    createdAt: zod.coerce.string().datetime(),
    updatedAt: zod.coerce.string().datetime(),
    address: zod
      .object({
        geoLocation: zod
          .object({
            x: zod.coerce.number().optional(),
            y: zod.coerce.number().optional(),
          })
          .nullish(),
        lat: zod.coerce
          .number()
          .min(timeTrackingControllerStartResponseUserAddressLatMin)
          .max(timeTrackingControllerStartResponseUserAddressLatMax)
          .optional(),
        lon: zod.coerce
          .number()
          .min(timeTrackingControllerStartResponseUserAddressLonMin)
          .max(timeTrackingControllerStartResponseUserAddressLonMax)
          .optional(),
        uuid: zod.coerce.string(),
        country: zod.coerce.string().optional(),
        city: zod.coerce.string().optional(),
        street: zod.coerce.string().optional(),
        postcode: zod.coerce.string().optional(),
      })
      .optional(),
    profilePicture: zod.coerce.string(),
    fullName: zod.coerce.string(),
    leaveTypes: zod.array(
      zod.object({
        uuid: zod.coerce.string().uuid(),
        name: zod.coerce
          .string()
          .min(1)
          .max(timeTrackingControllerStartResponseUserLeaveTypesItemNameMax),
        description: zod.coerce
          .string()
          .max(
            timeTrackingControllerStartResponseUserLeaveTypesItemDescriptionMax
          )
          .optional(),
        color: zod.coerce
          .string()
          .min(timeTrackingControllerStartResponseUserLeaveTypesItemColorMin)
          .max(timeTrackingControllerStartResponseUserLeaveTypesItemColorMax)
          .optional(),
        allowedPerYear: zod.object({
          days: zod.coerce.number(),
          hours: zod.coerce.number(),
          minutes: zod.coerce.number(),
          seconds: zod.coerce.number(),
          milliseconds: zod.coerce.number(),
        }),
        isFreebie: zod.coerce.boolean().optional(),
        isWithAttachment: zod.coerce.boolean().optional(),
      })
    ),
    layouts: zod.array(
      zod.object({
        uuid: zod.coerce.string(),
        user: zod.any(),
        layouts: zod.array(
          zod.object({
            i: zod.coerce.string(),
            x: zod.coerce.number(),
            y: zod.coerce.number(),
            w: zod.coerce.number(),
            h: zod.coerce.number(),
            minW: zod.coerce.number().optional(),
            maxW: zod.coerce.number().optional(),
            minH: zod.coerce.number().optional(),
            maxH: zod.coerce.number().optional(),
            static: zod.coerce.boolean().optional(),
            isBounded: zod.coerce.boolean().optional(),
            isDraggable: zod.coerce.boolean().optional(),
            isResizable: zod.coerce.boolean().optional(),
          })
        ),
      })
    ),
  }),
  timeZone: zod.coerce.string(),
})

/**
 * @summary Mark last started time tracking record ended
 */
export const timeTrackingControllerBreakHeader = zod.object({
  'x-time-zone': zod.coerce.string(),
})

export const timeTrackingControllerBreakResponseProjectSerialMax = 50
export const timeTrackingControllerBreakResponseProjectCompletionPercentageMin = 0

export const timeTrackingControllerBreakResponseProjectCompletionPercentageMax = 100
export const timeTrackingControllerBreakResponseProjectRoadmapsItemTagNameMax = 50
export const timeTrackingControllerBreakResponseProjectRoadmapsItemColorMin = 7

export const timeTrackingControllerBreakResponseProjectRoadmapsItemColorMax = 7
export const timeTrackingControllerBreakResponseProjectRoadmapsItemItemsItemColorMin = 7

export const timeTrackingControllerBreakResponseProjectRoadmapsItemItemsItemColorMax = 7
export const timeTrackingControllerBreakResponseProjectProjectManagersItemFirstNameMax = 100
export const timeTrackingControllerBreakResponseProjectProjectManagersItemLastNameMax = 100
export const timeTrackingControllerBreakResponseProjectProjectManagersItemAddressLatMin =
  -90

export const timeTrackingControllerBreakResponseProjectProjectManagersItemAddressLatMax = 90
export const timeTrackingControllerBreakResponseProjectProjectManagersItemAddressLonMin =
  -180

export const timeTrackingControllerBreakResponseProjectProjectManagersItemAddressLonMax = 180
export const timeTrackingControllerBreakResponseProjectProjectManagersItemLeaveTypesItemNameMax = 255
export const timeTrackingControllerBreakResponseProjectProjectManagersItemLeaveTypesItemDescriptionMax = 1024
export const timeTrackingControllerBreakResponseProjectProjectManagersItemLeaveTypesItemColorMin = 7

export const timeTrackingControllerBreakResponseProjectProjectManagersItemLeaveTypesItemColorMax = 7
export const timeTrackingControllerBreakResponseProjectTotalAllocationsMin = 0
export const timeTrackingControllerBreakResponseProjectAddressLatMin = -90

export const timeTrackingControllerBreakResponseProjectAddressLatMax = 90
export const timeTrackingControllerBreakResponseProjectAddressLonMin = -180

export const timeTrackingControllerBreakResponseProjectAddressLonMax = 180
export const timeTrackingControllerBreakResponseUserFirstNameMax = 100
export const timeTrackingControllerBreakResponseUserLastNameMax = 100
export const timeTrackingControllerBreakResponseUserAddressLatMin = -90

export const timeTrackingControllerBreakResponseUserAddressLatMax = 90
export const timeTrackingControllerBreakResponseUserAddressLonMin = -180

export const timeTrackingControllerBreakResponseUserAddressLonMax = 180
export const timeTrackingControllerBreakResponseUserLeaveTypesItemNameMax = 255
export const timeTrackingControllerBreakResponseUserLeaveTypesItemDescriptionMax = 1024
export const timeTrackingControllerBreakResponseUserLeaveTypesItemColorMin = 7

export const timeTrackingControllerBreakResponseUserLeaveTypesItemColorMax = 7

export const timeTrackingControllerBreakResponse = zod.object({
  uuid: zod.coerce.string().uuid(),
  startAt: zod.coerce.string().datetime(),
  endAt: zod.coerce.string().datetime().optional(),
  project: zod
    .object({
      serial: zod.coerce
        .string()
        .max(timeTrackingControllerBreakResponseProjectSerialMax)
        .optional(),
      projectSize: zod.coerce.number(),
      guaranteedUntil: zod.coerce.date().optional(),
      projectType: zod.object({
        name: zod.coerce.string(),
        uuid: zod.coerce.string(),
      }),
      completionPercentage: zod.coerce
        .number()
        .min(timeTrackingControllerBreakResponseProjectCompletionPercentageMin)
        .max(timeTrackingControllerBreakResponseProjectCompletionPercentageMax)
        .optional(),
      startedAt: zod.coerce.date().optional(),
      estimatedEndAt: zod.coerce.date().optional(),
      endAt: zod.coerce.date().optional(),
      status: zod.enum(['Active', 'In Active', 'Potential']).optional(),
      milestones: zod
        .array(
          zod.object({
            uuid: zod.coerce.string().uuid(),
            date: zod.coerce.date(),
            iconUrl: zod.coerce.string().nullable(),
            description: zod.coerce.string().nullable(),
            projectUuid: zod.coerce.string().uuid(),
            createdAt: zod.coerce.string().datetime(),
            updatedAt: zod.coerce.string().datetime(),
            project: zod.any(),
          })
        )
        .optional(),
      roadmaps: zod
        .array(
          zod.object({
            uuid: zod.coerce.string().uuid(),
            startAt: zod.coerce.date(),
            endAt: zod.coerce.date(),
            isVisible: zod.coerce.boolean().optional(),
            project: zod.any(),
            tag: zod.object({
              uuid: zod.coerce.string().uuid(),
              parent: zod.any().nullish(),
              name: zod.coerce
                .string()
                .max(
                  timeTrackingControllerBreakResponseProjectRoadmapsItemTagNameMax
                ),
              companyType: zod
                .enum(['Client', 'Contractor', 'Other'])
                .optional(),
            }),
            color: zod.coerce
              .string()
              .min(
                timeTrackingControllerBreakResponseProjectRoadmapsItemColorMin
              )
              .max(
                timeTrackingControllerBreakResponseProjectRoadmapsItemColorMax
              )
              .nullish(),
            items: zod.array(
              zod.object({
                uuid: zod.coerce.string().uuid(),
                color: zod.coerce
                  .string()
                  .min(
                    timeTrackingControllerBreakResponseProjectRoadmapsItemItemsItemColorMin
                  )
                  .max(
                    timeTrackingControllerBreakResponseProjectRoadmapsItemItemsItemColorMax
                  )
                  .nullish(),
                startAt: zod.coerce.date(),
                endAt: zod.coerce.date(),
                description: zod.coerce.string().nullable(),
                roadmap: zod.any().optional(),
              })
            ),
          })
        )
        .optional(),
      projectManagers: zod.array(
        zod.object({
          uuid: zod.coerce.string().uuid(),
          email: zod.coerce.string().email(),
          firstName: zod.coerce
            .string()
            .max(
              timeTrackingControllerBreakResponseProjectProjectManagersItemFirstNameMax
            ),
          lastName: zod.coerce
            .string()
            .max(
              timeTrackingControllerBreakResponseProjectProjectManagersItemLastNameMax
            ),
          dateOfBirth: zod.coerce.date().optional(),
          phoneNumber: zod.coerce.string().optional(),
          status: zod.enum(['Active', 'Terminated']),
          permissions: zod.array(
            zod.enum(['admin', 'human-resource', 'user']).optional()
          ),
          role: zod
            .object({
              uuid: zod.coerce.string().uuid(),
              name: zod.coerce.string(),
            })
            .optional(),
          department: zod
            .object({
              uuid: zod.coerce.string().uuid(),
              name: zod.coerce.string(),
            })
            .optional(),
          createdAt: zod.coerce.string().datetime(),
          updatedAt: zod.coerce.string().datetime(),
          address: zod
            .object({
              geoLocation: zod
                .object({
                  x: zod.coerce.number().optional(),
                  y: zod.coerce.number().optional(),
                })
                .nullish(),
              lat: zod.coerce
                .number()
                .min(
                  timeTrackingControllerBreakResponseProjectProjectManagersItemAddressLatMin
                )
                .max(
                  timeTrackingControllerBreakResponseProjectProjectManagersItemAddressLatMax
                )
                .optional(),
              lon: zod.coerce
                .number()
                .min(
                  timeTrackingControllerBreakResponseProjectProjectManagersItemAddressLonMin
                )
                .max(
                  timeTrackingControllerBreakResponseProjectProjectManagersItemAddressLonMax
                )
                .optional(),
              uuid: zod.coerce.string(),
              country: zod.coerce.string().optional(),
              city: zod.coerce.string().optional(),
              street: zod.coerce.string().optional(),
              postcode: zod.coerce.string().optional(),
            })
            .optional(),
          profilePicture: zod.coerce.string(),
          fullName: zod.coerce.string(),
          leaveTypes: zod.array(
            zod.object({
              uuid: zod.coerce.string().uuid(),
              name: zod.coerce
                .string()
                .min(1)
                .max(
                  timeTrackingControllerBreakResponseProjectProjectManagersItemLeaveTypesItemNameMax
                ),
              description: zod.coerce
                .string()
                .max(
                  timeTrackingControllerBreakResponseProjectProjectManagersItemLeaveTypesItemDescriptionMax
                )
                .optional(),
              color: zod.coerce
                .string()
                .min(
                  timeTrackingControllerBreakResponseProjectProjectManagersItemLeaveTypesItemColorMin
                )
                .max(
                  timeTrackingControllerBreakResponseProjectProjectManagersItemLeaveTypesItemColorMax
                )
                .optional(),
              allowedPerYear: zod.object({
                days: zod.coerce.number(),
                hours: zod.coerce.number(),
                minutes: zod.coerce.number(),
                seconds: zod.coerce.number(),
                milliseconds: zod.coerce.number(),
              }),
              isFreebie: zod.coerce.boolean().optional(),
              isWithAttachment: zod.coerce.boolean().optional(),
            })
          ),
          layouts: zod.array(
            zod.object({
              uuid: zod.coerce.string(),
              user: zod.any(),
              layouts: zod.array(
                zod.object({
                  i: zod.coerce.string(),
                  x: zod.coerce.number(),
                  y: zod.coerce.number(),
                  w: zod.coerce.number(),
                  h: zod.coerce.number(),
                  minW: zod.coerce.number().optional(),
                  maxW: zod.coerce.number().optional(),
                  minH: zod.coerce.number().optional(),
                  maxH: zod.coerce.number().optional(),
                  static: zod.coerce.boolean().optional(),
                  isBounded: zod.coerce.boolean().optional(),
                  isDraggable: zod.coerce.boolean().optional(),
                  isResizable: zod.coerce.boolean().optional(),
                })
              ),
            })
          ),
        })
      ),
      totalAllocations: zod.coerce
        .number()
        .min(timeTrackingControllerBreakResponseProjectTotalAllocationsMin),
      uuid: zod.coerce.string(),
      name: zod.coerce.string(),
      address: zod.object({
        geoLocation: zod
          .object({
            x: zod.coerce.number().optional(),
            y: zod.coerce.number().optional(),
          })
          .nullish(),
        lat: zod.coerce
          .number()
          .min(timeTrackingControllerBreakResponseProjectAddressLatMin)
          .max(timeTrackingControllerBreakResponseProjectAddressLatMax)
          .optional(),
        lon: zod.coerce
          .number()
          .min(timeTrackingControllerBreakResponseProjectAddressLonMin)
          .max(timeTrackingControllerBreakResponseProjectAddressLonMax)
          .optional(),
        uuid: zod.coerce.string(),
        country: zod.coerce.string().optional(),
        city: zod.coerce.string().optional(),
        street: zod.coerce.string().optional(),
        postcode: zod.coerce.string().optional(),
      }),
      createdAt: zod.coerce.string().datetime(),
      updatedAt: zod.coerce.string().datetime(),
    })
    .optional(),
  type: zod
    .enum(['home_work', 'office_work', 'site_work', 'transit'])
    .optional(),
  isClockOut: zod.coerce.boolean().optional(),
  status: zod.enum(['approved', 'pending', 'draft']),
  timeType: zod.enum(['work', 'break', 'time-off']).optional(),
  deletedAt: zod.coerce.string().datetime(),
  createdAt: zod.coerce.string().datetime(),
  updatedAt: zod.coerce.string().datetime(),
  duration: zod.coerce.number(),
  user: zod.object({
    uuid: zod.coerce.string().uuid(),
    email: zod.coerce.string().email(),
    firstName: zod.coerce
      .string()
      .max(timeTrackingControllerBreakResponseUserFirstNameMax),
    lastName: zod.coerce
      .string()
      .max(timeTrackingControllerBreakResponseUserLastNameMax),
    dateOfBirth: zod.coerce.date().optional(),
    phoneNumber: zod.coerce.string().optional(),
    status: zod.enum(['Active', 'Terminated']),
    permissions: zod.array(
      zod.enum(['admin', 'human-resource', 'user']).optional()
    ),
    role: zod
      .object({
        uuid: zod.coerce.string().uuid(),
        name: zod.coerce.string(),
      })
      .optional(),
    department: zod
      .object({
        uuid: zod.coerce.string().uuid(),
        name: zod.coerce.string(),
      })
      .optional(),
    createdAt: zod.coerce.string().datetime(),
    updatedAt: zod.coerce.string().datetime(),
    address: zod
      .object({
        geoLocation: zod
          .object({
            x: zod.coerce.number().optional(),
            y: zod.coerce.number().optional(),
          })
          .nullish(),
        lat: zod.coerce
          .number()
          .min(timeTrackingControllerBreakResponseUserAddressLatMin)
          .max(timeTrackingControllerBreakResponseUserAddressLatMax)
          .optional(),
        lon: zod.coerce
          .number()
          .min(timeTrackingControllerBreakResponseUserAddressLonMin)
          .max(timeTrackingControllerBreakResponseUserAddressLonMax)
          .optional(),
        uuid: zod.coerce.string(),
        country: zod.coerce.string().optional(),
        city: zod.coerce.string().optional(),
        street: zod.coerce.string().optional(),
        postcode: zod.coerce.string().optional(),
      })
      .optional(),
    profilePicture: zod.coerce.string(),
    fullName: zod.coerce.string(),
    leaveTypes: zod.array(
      zod.object({
        uuid: zod.coerce.string().uuid(),
        name: zod.coerce
          .string()
          .min(1)
          .max(timeTrackingControllerBreakResponseUserLeaveTypesItemNameMax),
        description: zod.coerce
          .string()
          .max(
            timeTrackingControllerBreakResponseUserLeaveTypesItemDescriptionMax
          )
          .optional(),
        color: zod.coerce
          .string()
          .min(timeTrackingControllerBreakResponseUserLeaveTypesItemColorMin)
          .max(timeTrackingControllerBreakResponseUserLeaveTypesItemColorMax)
          .optional(),
        allowedPerYear: zod.object({
          days: zod.coerce.number(),
          hours: zod.coerce.number(),
          minutes: zod.coerce.number(),
          seconds: zod.coerce.number(),
          milliseconds: zod.coerce.number(),
        }),
        isFreebie: zod.coerce.boolean().optional(),
        isWithAttachment: zod.coerce.boolean().optional(),
      })
    ),
    layouts: zod.array(
      zod.object({
        uuid: zod.coerce.string(),
        user: zod.any(),
        layouts: zod.array(
          zod.object({
            i: zod.coerce.string(),
            x: zod.coerce.number(),
            y: zod.coerce.number(),
            w: zod.coerce.number(),
            h: zod.coerce.number(),
            minW: zod.coerce.number().optional(),
            maxW: zod.coerce.number().optional(),
            minH: zod.coerce.number().optional(),
            maxH: zod.coerce.number().optional(),
            static: zod.coerce.boolean().optional(),
            isBounded: zod.coerce.boolean().optional(),
            isDraggable: zod.coerce.boolean().optional(),
            isResizable: zod.coerce.boolean().optional(),
          })
        ),
      })
    ),
  }),
  timeZone: zod.coerce.string(),
})

/**
 * @summary Mark last started or broke time tracking as clock out item
 */
export const timeTrackingControllerEndHeader = zod.object({
  'x-time-zone': zod.coerce.string(),
})

export const timeTrackingControllerEndResponseProjectSerialMax = 50
export const timeTrackingControllerEndResponseProjectCompletionPercentageMin = 0

export const timeTrackingControllerEndResponseProjectCompletionPercentageMax = 100
export const timeTrackingControllerEndResponseProjectRoadmapsItemTagNameMax = 50
export const timeTrackingControllerEndResponseProjectRoadmapsItemColorMin = 7

export const timeTrackingControllerEndResponseProjectRoadmapsItemColorMax = 7
export const timeTrackingControllerEndResponseProjectRoadmapsItemItemsItemColorMin = 7

export const timeTrackingControllerEndResponseProjectRoadmapsItemItemsItemColorMax = 7
export const timeTrackingControllerEndResponseProjectProjectManagersItemFirstNameMax = 100
export const timeTrackingControllerEndResponseProjectProjectManagersItemLastNameMax = 100
export const timeTrackingControllerEndResponseProjectProjectManagersItemAddressLatMin =
  -90

export const timeTrackingControllerEndResponseProjectProjectManagersItemAddressLatMax = 90
export const timeTrackingControllerEndResponseProjectProjectManagersItemAddressLonMin =
  -180

export const timeTrackingControllerEndResponseProjectProjectManagersItemAddressLonMax = 180
export const timeTrackingControllerEndResponseProjectProjectManagersItemLeaveTypesItemNameMax = 255
export const timeTrackingControllerEndResponseProjectProjectManagersItemLeaveTypesItemDescriptionMax = 1024
export const timeTrackingControllerEndResponseProjectProjectManagersItemLeaveTypesItemColorMin = 7

export const timeTrackingControllerEndResponseProjectProjectManagersItemLeaveTypesItemColorMax = 7
export const timeTrackingControllerEndResponseProjectTotalAllocationsMin = 0
export const timeTrackingControllerEndResponseProjectAddressLatMin = -90

export const timeTrackingControllerEndResponseProjectAddressLatMax = 90
export const timeTrackingControllerEndResponseProjectAddressLonMin = -180

export const timeTrackingControllerEndResponseProjectAddressLonMax = 180
export const timeTrackingControllerEndResponseUserFirstNameMax = 100
export const timeTrackingControllerEndResponseUserLastNameMax = 100
export const timeTrackingControllerEndResponseUserAddressLatMin = -90

export const timeTrackingControllerEndResponseUserAddressLatMax = 90
export const timeTrackingControllerEndResponseUserAddressLonMin = -180

export const timeTrackingControllerEndResponseUserAddressLonMax = 180
export const timeTrackingControllerEndResponseUserLeaveTypesItemNameMax = 255
export const timeTrackingControllerEndResponseUserLeaveTypesItemDescriptionMax = 1024
export const timeTrackingControllerEndResponseUserLeaveTypesItemColorMin = 7

export const timeTrackingControllerEndResponseUserLeaveTypesItemColorMax = 7

export const timeTrackingControllerEndResponse = zod.object({
  uuid: zod.coerce.string().uuid(),
  startAt: zod.coerce.string().datetime(),
  endAt: zod.coerce.string().datetime().optional(),
  project: zod
    .object({
      serial: zod.coerce
        .string()
        .max(timeTrackingControllerEndResponseProjectSerialMax)
        .optional(),
      projectSize: zod.coerce.number(),
      guaranteedUntil: zod.coerce.date().optional(),
      projectType: zod.object({
        name: zod.coerce.string(),
        uuid: zod.coerce.string(),
      }),
      completionPercentage: zod.coerce
        .number()
        .min(timeTrackingControllerEndResponseProjectCompletionPercentageMin)
        .max(timeTrackingControllerEndResponseProjectCompletionPercentageMax)
        .optional(),
      startedAt: zod.coerce.date().optional(),
      estimatedEndAt: zod.coerce.date().optional(),
      endAt: zod.coerce.date().optional(),
      status: zod.enum(['Active', 'In Active', 'Potential']).optional(),
      milestones: zod
        .array(
          zod.object({
            uuid: zod.coerce.string().uuid(),
            date: zod.coerce.date(),
            iconUrl: zod.coerce.string().nullable(),
            description: zod.coerce.string().nullable(),
            projectUuid: zod.coerce.string().uuid(),
            createdAt: zod.coerce.string().datetime(),
            updatedAt: zod.coerce.string().datetime(),
            project: zod.any(),
          })
        )
        .optional(),
      roadmaps: zod
        .array(
          zod.object({
            uuid: zod.coerce.string().uuid(),
            startAt: zod.coerce.date(),
            endAt: zod.coerce.date(),
            isVisible: zod.coerce.boolean().optional(),
            project: zod.any(),
            tag: zod.object({
              uuid: zod.coerce.string().uuid(),
              parent: zod.any().nullish(),
              name: zod.coerce
                .string()
                .max(
                  timeTrackingControllerEndResponseProjectRoadmapsItemTagNameMax
                ),
              companyType: zod
                .enum(['Client', 'Contractor', 'Other'])
                .optional(),
            }),
            color: zod.coerce
              .string()
              .min(timeTrackingControllerEndResponseProjectRoadmapsItemColorMin)
              .max(timeTrackingControllerEndResponseProjectRoadmapsItemColorMax)
              .nullish(),
            items: zod.array(
              zod.object({
                uuid: zod.coerce.string().uuid(),
                color: zod.coerce
                  .string()
                  .min(
                    timeTrackingControllerEndResponseProjectRoadmapsItemItemsItemColorMin
                  )
                  .max(
                    timeTrackingControllerEndResponseProjectRoadmapsItemItemsItemColorMax
                  )
                  .nullish(),
                startAt: zod.coerce.date(),
                endAt: zod.coerce.date(),
                description: zod.coerce.string().nullable(),
                roadmap: zod.any().optional(),
              })
            ),
          })
        )
        .optional(),
      projectManagers: zod.array(
        zod.object({
          uuid: zod.coerce.string().uuid(),
          email: zod.coerce.string().email(),
          firstName: zod.coerce
            .string()
            .max(
              timeTrackingControllerEndResponseProjectProjectManagersItemFirstNameMax
            ),
          lastName: zod.coerce
            .string()
            .max(
              timeTrackingControllerEndResponseProjectProjectManagersItemLastNameMax
            ),
          dateOfBirth: zod.coerce.date().optional(),
          phoneNumber: zod.coerce.string().optional(),
          status: zod.enum(['Active', 'Terminated']),
          permissions: zod.array(
            zod.enum(['admin', 'human-resource', 'user']).optional()
          ),
          role: zod
            .object({
              uuid: zod.coerce.string().uuid(),
              name: zod.coerce.string(),
            })
            .optional(),
          department: zod
            .object({
              uuid: zod.coerce.string().uuid(),
              name: zod.coerce.string(),
            })
            .optional(),
          createdAt: zod.coerce.string().datetime(),
          updatedAt: zod.coerce.string().datetime(),
          address: zod
            .object({
              geoLocation: zod
                .object({
                  x: zod.coerce.number().optional(),
                  y: zod.coerce.number().optional(),
                })
                .nullish(),
              lat: zod.coerce
                .number()
                .min(
                  timeTrackingControllerEndResponseProjectProjectManagersItemAddressLatMin
                )
                .max(
                  timeTrackingControllerEndResponseProjectProjectManagersItemAddressLatMax
                )
                .optional(),
              lon: zod.coerce
                .number()
                .min(
                  timeTrackingControllerEndResponseProjectProjectManagersItemAddressLonMin
                )
                .max(
                  timeTrackingControllerEndResponseProjectProjectManagersItemAddressLonMax
                )
                .optional(),
              uuid: zod.coerce.string(),
              country: zod.coerce.string().optional(),
              city: zod.coerce.string().optional(),
              street: zod.coerce.string().optional(),
              postcode: zod.coerce.string().optional(),
            })
            .optional(),
          profilePicture: zod.coerce.string(),
          fullName: zod.coerce.string(),
          leaveTypes: zod.array(
            zod.object({
              uuid: zod.coerce.string().uuid(),
              name: zod.coerce
                .string()
                .min(1)
                .max(
                  timeTrackingControllerEndResponseProjectProjectManagersItemLeaveTypesItemNameMax
                ),
              description: zod.coerce
                .string()
                .max(
                  timeTrackingControllerEndResponseProjectProjectManagersItemLeaveTypesItemDescriptionMax
                )
                .optional(),
              color: zod.coerce
                .string()
                .min(
                  timeTrackingControllerEndResponseProjectProjectManagersItemLeaveTypesItemColorMin
                )
                .max(
                  timeTrackingControllerEndResponseProjectProjectManagersItemLeaveTypesItemColorMax
                )
                .optional(),
              allowedPerYear: zod.object({
                days: zod.coerce.number(),
                hours: zod.coerce.number(),
                minutes: zod.coerce.number(),
                seconds: zod.coerce.number(),
                milliseconds: zod.coerce.number(),
              }),
              isFreebie: zod.coerce.boolean().optional(),
              isWithAttachment: zod.coerce.boolean().optional(),
            })
          ),
          layouts: zod.array(
            zod.object({
              uuid: zod.coerce.string(),
              user: zod.any(),
              layouts: zod.array(
                zod.object({
                  i: zod.coerce.string(),
                  x: zod.coerce.number(),
                  y: zod.coerce.number(),
                  w: zod.coerce.number(),
                  h: zod.coerce.number(),
                  minW: zod.coerce.number().optional(),
                  maxW: zod.coerce.number().optional(),
                  minH: zod.coerce.number().optional(),
                  maxH: zod.coerce.number().optional(),
                  static: zod.coerce.boolean().optional(),
                  isBounded: zod.coerce.boolean().optional(),
                  isDraggable: zod.coerce.boolean().optional(),
                  isResizable: zod.coerce.boolean().optional(),
                })
              ),
            })
          ),
        })
      ),
      totalAllocations: zod.coerce
        .number()
        .min(timeTrackingControllerEndResponseProjectTotalAllocationsMin),
      uuid: zod.coerce.string(),
      name: zod.coerce.string(),
      address: zod.object({
        geoLocation: zod
          .object({
            x: zod.coerce.number().optional(),
            y: zod.coerce.number().optional(),
          })
          .nullish(),
        lat: zod.coerce
          .number()
          .min(timeTrackingControllerEndResponseProjectAddressLatMin)
          .max(timeTrackingControllerEndResponseProjectAddressLatMax)
          .optional(),
        lon: zod.coerce
          .number()
          .min(timeTrackingControllerEndResponseProjectAddressLonMin)
          .max(timeTrackingControllerEndResponseProjectAddressLonMax)
          .optional(),
        uuid: zod.coerce.string(),
        country: zod.coerce.string().optional(),
        city: zod.coerce.string().optional(),
        street: zod.coerce.string().optional(),
        postcode: zod.coerce.string().optional(),
      }),
      createdAt: zod.coerce.string().datetime(),
      updatedAt: zod.coerce.string().datetime(),
    })
    .optional(),
  type: zod
    .enum(['home_work', 'office_work', 'site_work', 'transit'])
    .optional(),
  isClockOut: zod.coerce.boolean().optional(),
  status: zod.enum(['approved', 'pending', 'draft']),
  timeType: zod.enum(['work', 'break', 'time-off']).optional(),
  deletedAt: zod.coerce.string().datetime(),
  createdAt: zod.coerce.string().datetime(),
  updatedAt: zod.coerce.string().datetime(),
  duration: zod.coerce.number(),
  user: zod.object({
    uuid: zod.coerce.string().uuid(),
    email: zod.coerce.string().email(),
    firstName: zod.coerce
      .string()
      .max(timeTrackingControllerEndResponseUserFirstNameMax),
    lastName: zod.coerce
      .string()
      .max(timeTrackingControllerEndResponseUserLastNameMax),
    dateOfBirth: zod.coerce.date().optional(),
    phoneNumber: zod.coerce.string().optional(),
    status: zod.enum(['Active', 'Terminated']),
    permissions: zod.array(
      zod.enum(['admin', 'human-resource', 'user']).optional()
    ),
    role: zod
      .object({
        uuid: zod.coerce.string().uuid(),
        name: zod.coerce.string(),
      })
      .optional(),
    department: zod
      .object({
        uuid: zod.coerce.string().uuid(),
        name: zod.coerce.string(),
      })
      .optional(),
    createdAt: zod.coerce.string().datetime(),
    updatedAt: zod.coerce.string().datetime(),
    address: zod
      .object({
        geoLocation: zod
          .object({
            x: zod.coerce.number().optional(),
            y: zod.coerce.number().optional(),
          })
          .nullish(),
        lat: zod.coerce
          .number()
          .min(timeTrackingControllerEndResponseUserAddressLatMin)
          .max(timeTrackingControllerEndResponseUserAddressLatMax)
          .optional(),
        lon: zod.coerce
          .number()
          .min(timeTrackingControllerEndResponseUserAddressLonMin)
          .max(timeTrackingControllerEndResponseUserAddressLonMax)
          .optional(),
        uuid: zod.coerce.string(),
        country: zod.coerce.string().optional(),
        city: zod.coerce.string().optional(),
        street: zod.coerce.string().optional(),
        postcode: zod.coerce.string().optional(),
      })
      .optional(),
    profilePicture: zod.coerce.string(),
    fullName: zod.coerce.string(),
    leaveTypes: zod.array(
      zod.object({
        uuid: zod.coerce.string().uuid(),
        name: zod.coerce
          .string()
          .min(1)
          .max(timeTrackingControllerEndResponseUserLeaveTypesItemNameMax),
        description: zod.coerce
          .string()
          .max(
            timeTrackingControllerEndResponseUserLeaveTypesItemDescriptionMax
          )
          .optional(),
        color: zod.coerce
          .string()
          .min(timeTrackingControllerEndResponseUserLeaveTypesItemColorMin)
          .max(timeTrackingControllerEndResponseUserLeaveTypesItemColorMax)
          .optional(),
        allowedPerYear: zod.object({
          days: zod.coerce.number(),
          hours: zod.coerce.number(),
          minutes: zod.coerce.number(),
          seconds: zod.coerce.number(),
          milliseconds: zod.coerce.number(),
        }),
        isFreebie: zod.coerce.boolean().optional(),
        isWithAttachment: zod.coerce.boolean().optional(),
      })
    ),
    layouts: zod.array(
      zod.object({
        uuid: zod.coerce.string(),
        user: zod.any(),
        layouts: zod.array(
          zod.object({
            i: zod.coerce.string(),
            x: zod.coerce.number(),
            y: zod.coerce.number(),
            w: zod.coerce.number(),
            h: zod.coerce.number(),
            minW: zod.coerce.number().optional(),
            maxW: zod.coerce.number().optional(),
            minH: zod.coerce.number().optional(),
            maxH: zod.coerce.number().optional(),
            static: zod.coerce.boolean().optional(),
            isBounded: zod.coerce.boolean().optional(),
            isDraggable: zod.coerce.boolean().optional(),
            isResizable: zod.coerce.boolean().optional(),
          })
        ),
      })
    ),
  }),
  timeZone: zod.coerce.string(),
})

/**
 * @summary Get last time tracking item
 */
export const timeTrackingControllerLastHeader = zod.object({
  'x-time-zone': zod.coerce.string(),
})

export const timeTrackingControllerLastResponseProjectSerialMax = 50
export const timeTrackingControllerLastResponseProjectCompletionPercentageMin = 0

export const timeTrackingControllerLastResponseProjectCompletionPercentageMax = 100
export const timeTrackingControllerLastResponseProjectRoadmapsItemTagNameMax = 50
export const timeTrackingControllerLastResponseProjectRoadmapsItemColorMin = 7

export const timeTrackingControllerLastResponseProjectRoadmapsItemColorMax = 7
export const timeTrackingControllerLastResponseProjectRoadmapsItemItemsItemColorMin = 7

export const timeTrackingControllerLastResponseProjectRoadmapsItemItemsItemColorMax = 7
export const timeTrackingControllerLastResponseProjectProjectManagersItemFirstNameMax = 100
export const timeTrackingControllerLastResponseProjectProjectManagersItemLastNameMax = 100
export const timeTrackingControllerLastResponseProjectProjectManagersItemAddressLatMin =
  -90

export const timeTrackingControllerLastResponseProjectProjectManagersItemAddressLatMax = 90
export const timeTrackingControllerLastResponseProjectProjectManagersItemAddressLonMin =
  -180

export const timeTrackingControllerLastResponseProjectProjectManagersItemAddressLonMax = 180
export const timeTrackingControllerLastResponseProjectProjectManagersItemLeaveTypesItemNameMax = 255
export const timeTrackingControllerLastResponseProjectProjectManagersItemLeaveTypesItemDescriptionMax = 1024
export const timeTrackingControllerLastResponseProjectProjectManagersItemLeaveTypesItemColorMin = 7

export const timeTrackingControllerLastResponseProjectProjectManagersItemLeaveTypesItemColorMax = 7
export const timeTrackingControllerLastResponseProjectTotalAllocationsMin = 0
export const timeTrackingControllerLastResponseProjectAddressLatMin = -90

export const timeTrackingControllerLastResponseProjectAddressLatMax = 90
export const timeTrackingControllerLastResponseProjectAddressLonMin = -180

export const timeTrackingControllerLastResponseProjectAddressLonMax = 180
export const timeTrackingControllerLastResponseUserFirstNameMax = 100
export const timeTrackingControllerLastResponseUserLastNameMax = 100
export const timeTrackingControllerLastResponseUserAddressLatMin = -90

export const timeTrackingControllerLastResponseUserAddressLatMax = 90
export const timeTrackingControllerLastResponseUserAddressLonMin = -180

export const timeTrackingControllerLastResponseUserAddressLonMax = 180
export const timeTrackingControllerLastResponseUserLeaveTypesItemNameMax = 255
export const timeTrackingControllerLastResponseUserLeaveTypesItemDescriptionMax = 1024
export const timeTrackingControllerLastResponseUserLeaveTypesItemColorMin = 7

export const timeTrackingControllerLastResponseUserLeaveTypesItemColorMax = 7

export const timeTrackingControllerLastResponse = zod.object({
  uuid: zod.coerce.string().uuid(),
  startAt: zod.coerce.string().datetime(),
  endAt: zod.coerce.string().datetime().optional(),
  project: zod
    .object({
      serial: zod.coerce
        .string()
        .max(timeTrackingControllerLastResponseProjectSerialMax)
        .optional(),
      projectSize: zod.coerce.number(),
      guaranteedUntil: zod.coerce.date().optional(),
      projectType: zod.object({
        name: zod.coerce.string(),
        uuid: zod.coerce.string(),
      }),
      completionPercentage: zod.coerce
        .number()
        .min(timeTrackingControllerLastResponseProjectCompletionPercentageMin)
        .max(timeTrackingControllerLastResponseProjectCompletionPercentageMax)
        .optional(),
      startedAt: zod.coerce.date().optional(),
      estimatedEndAt: zod.coerce.date().optional(),
      endAt: zod.coerce.date().optional(),
      status: zod.enum(['Active', 'In Active', 'Potential']).optional(),
      milestones: zod
        .array(
          zod.object({
            uuid: zod.coerce.string().uuid(),
            date: zod.coerce.date(),
            iconUrl: zod.coerce.string().nullable(),
            description: zod.coerce.string().nullable(),
            projectUuid: zod.coerce.string().uuid(),
            createdAt: zod.coerce.string().datetime(),
            updatedAt: zod.coerce.string().datetime(),
            project: zod.any(),
          })
        )
        .optional(),
      roadmaps: zod
        .array(
          zod.object({
            uuid: zod.coerce.string().uuid(),
            startAt: zod.coerce.date(),
            endAt: zod.coerce.date(),
            isVisible: zod.coerce.boolean().optional(),
            project: zod.any(),
            tag: zod.object({
              uuid: zod.coerce.string().uuid(),
              parent: zod.any().nullish(),
              name: zod.coerce
                .string()
                .max(
                  timeTrackingControllerLastResponseProjectRoadmapsItemTagNameMax
                ),
              companyType: zod
                .enum(['Client', 'Contractor', 'Other'])
                .optional(),
            }),
            color: zod.coerce
              .string()
              .min(
                timeTrackingControllerLastResponseProjectRoadmapsItemColorMin
              )
              .max(
                timeTrackingControllerLastResponseProjectRoadmapsItemColorMax
              )
              .nullish(),
            items: zod.array(
              zod.object({
                uuid: zod.coerce.string().uuid(),
                color: zod.coerce
                  .string()
                  .min(
                    timeTrackingControllerLastResponseProjectRoadmapsItemItemsItemColorMin
                  )
                  .max(
                    timeTrackingControllerLastResponseProjectRoadmapsItemItemsItemColorMax
                  )
                  .nullish(),
                startAt: zod.coerce.date(),
                endAt: zod.coerce.date(),
                description: zod.coerce.string().nullable(),
                roadmap: zod.any().optional(),
              })
            ),
          })
        )
        .optional(),
      projectManagers: zod.array(
        zod.object({
          uuid: zod.coerce.string().uuid(),
          email: zod.coerce.string().email(),
          firstName: zod.coerce
            .string()
            .max(
              timeTrackingControllerLastResponseProjectProjectManagersItemFirstNameMax
            ),
          lastName: zod.coerce
            .string()
            .max(
              timeTrackingControllerLastResponseProjectProjectManagersItemLastNameMax
            ),
          dateOfBirth: zod.coerce.date().optional(),
          phoneNumber: zod.coerce.string().optional(),
          status: zod.enum(['Active', 'Terminated']),
          permissions: zod.array(
            zod.enum(['admin', 'human-resource', 'user']).optional()
          ),
          role: zod
            .object({
              uuid: zod.coerce.string().uuid(),
              name: zod.coerce.string(),
            })
            .optional(),
          department: zod
            .object({
              uuid: zod.coerce.string().uuid(),
              name: zod.coerce.string(),
            })
            .optional(),
          createdAt: zod.coerce.string().datetime(),
          updatedAt: zod.coerce.string().datetime(),
          address: zod
            .object({
              geoLocation: zod
                .object({
                  x: zod.coerce.number().optional(),
                  y: zod.coerce.number().optional(),
                })
                .nullish(),
              lat: zod.coerce
                .number()
                .min(
                  timeTrackingControllerLastResponseProjectProjectManagersItemAddressLatMin
                )
                .max(
                  timeTrackingControllerLastResponseProjectProjectManagersItemAddressLatMax
                )
                .optional(),
              lon: zod.coerce
                .number()
                .min(
                  timeTrackingControllerLastResponseProjectProjectManagersItemAddressLonMin
                )
                .max(
                  timeTrackingControllerLastResponseProjectProjectManagersItemAddressLonMax
                )
                .optional(),
              uuid: zod.coerce.string(),
              country: zod.coerce.string().optional(),
              city: zod.coerce.string().optional(),
              street: zod.coerce.string().optional(),
              postcode: zod.coerce.string().optional(),
            })
            .optional(),
          profilePicture: zod.coerce.string(),
          fullName: zod.coerce.string(),
          leaveTypes: zod.array(
            zod.object({
              uuid: zod.coerce.string().uuid(),
              name: zod.coerce
                .string()
                .min(1)
                .max(
                  timeTrackingControllerLastResponseProjectProjectManagersItemLeaveTypesItemNameMax
                ),
              description: zod.coerce
                .string()
                .max(
                  timeTrackingControllerLastResponseProjectProjectManagersItemLeaveTypesItemDescriptionMax
                )
                .optional(),
              color: zod.coerce
                .string()
                .min(
                  timeTrackingControllerLastResponseProjectProjectManagersItemLeaveTypesItemColorMin
                )
                .max(
                  timeTrackingControllerLastResponseProjectProjectManagersItemLeaveTypesItemColorMax
                )
                .optional(),
              allowedPerYear: zod.object({
                days: zod.coerce.number(),
                hours: zod.coerce.number(),
                minutes: zod.coerce.number(),
                seconds: zod.coerce.number(),
                milliseconds: zod.coerce.number(),
              }),
              isFreebie: zod.coerce.boolean().optional(),
              isWithAttachment: zod.coerce.boolean().optional(),
            })
          ),
          layouts: zod.array(
            zod.object({
              uuid: zod.coerce.string(),
              user: zod.any(),
              layouts: zod.array(
                zod.object({
                  i: zod.coerce.string(),
                  x: zod.coerce.number(),
                  y: zod.coerce.number(),
                  w: zod.coerce.number(),
                  h: zod.coerce.number(),
                  minW: zod.coerce.number().optional(),
                  maxW: zod.coerce.number().optional(),
                  minH: zod.coerce.number().optional(),
                  maxH: zod.coerce.number().optional(),
                  static: zod.coerce.boolean().optional(),
                  isBounded: zod.coerce.boolean().optional(),
                  isDraggable: zod.coerce.boolean().optional(),
                  isResizable: zod.coerce.boolean().optional(),
                })
              ),
            })
          ),
        })
      ),
      totalAllocations: zod.coerce
        .number()
        .min(timeTrackingControllerLastResponseProjectTotalAllocationsMin),
      uuid: zod.coerce.string(),
      name: zod.coerce.string(),
      address: zod.object({
        geoLocation: zod
          .object({
            x: zod.coerce.number().optional(),
            y: zod.coerce.number().optional(),
          })
          .nullish(),
        lat: zod.coerce
          .number()
          .min(timeTrackingControllerLastResponseProjectAddressLatMin)
          .max(timeTrackingControllerLastResponseProjectAddressLatMax)
          .optional(),
        lon: zod.coerce
          .number()
          .min(timeTrackingControllerLastResponseProjectAddressLonMin)
          .max(timeTrackingControllerLastResponseProjectAddressLonMax)
          .optional(),
        uuid: zod.coerce.string(),
        country: zod.coerce.string().optional(),
        city: zod.coerce.string().optional(),
        street: zod.coerce.string().optional(),
        postcode: zod.coerce.string().optional(),
      }),
      createdAt: zod.coerce.string().datetime(),
      updatedAt: zod.coerce.string().datetime(),
    })
    .optional(),
  type: zod
    .enum(['home_work', 'office_work', 'site_work', 'transit'])
    .optional(),
  isClockOut: zod.coerce.boolean().optional(),
  status: zod.enum(['approved', 'pending', 'draft']),
  timeType: zod.enum(['work', 'break', 'time-off']).optional(),
  deletedAt: zod.coerce.string().datetime(),
  createdAt: zod.coerce.string().datetime(),
  updatedAt: zod.coerce.string().datetime(),
  duration: zod.coerce.number(),
  user: zod.object({
    uuid: zod.coerce.string().uuid(),
    email: zod.coerce.string().email(),
    firstName: zod.coerce
      .string()
      .max(timeTrackingControllerLastResponseUserFirstNameMax),
    lastName: zod.coerce
      .string()
      .max(timeTrackingControllerLastResponseUserLastNameMax),
    dateOfBirth: zod.coerce.date().optional(),
    phoneNumber: zod.coerce.string().optional(),
    status: zod.enum(['Active', 'Terminated']),
    permissions: zod.array(
      zod.enum(['admin', 'human-resource', 'user']).optional()
    ),
    role: zod
      .object({
        uuid: zod.coerce.string().uuid(),
        name: zod.coerce.string(),
      })
      .optional(),
    department: zod
      .object({
        uuid: zod.coerce.string().uuid(),
        name: zod.coerce.string(),
      })
      .optional(),
    createdAt: zod.coerce.string().datetime(),
    updatedAt: zod.coerce.string().datetime(),
    address: zod
      .object({
        geoLocation: zod
          .object({
            x: zod.coerce.number().optional(),
            y: zod.coerce.number().optional(),
          })
          .nullish(),
        lat: zod.coerce
          .number()
          .min(timeTrackingControllerLastResponseUserAddressLatMin)
          .max(timeTrackingControllerLastResponseUserAddressLatMax)
          .optional(),
        lon: zod.coerce
          .number()
          .min(timeTrackingControllerLastResponseUserAddressLonMin)
          .max(timeTrackingControllerLastResponseUserAddressLonMax)
          .optional(),
        uuid: zod.coerce.string(),
        country: zod.coerce.string().optional(),
        city: zod.coerce.string().optional(),
        street: zod.coerce.string().optional(),
        postcode: zod.coerce.string().optional(),
      })
      .optional(),
    profilePicture: zod.coerce.string(),
    fullName: zod.coerce.string(),
    leaveTypes: zod.array(
      zod.object({
        uuid: zod.coerce.string().uuid(),
        name: zod.coerce
          .string()
          .min(1)
          .max(timeTrackingControllerLastResponseUserLeaveTypesItemNameMax),
        description: zod.coerce
          .string()
          .max(
            timeTrackingControllerLastResponseUserLeaveTypesItemDescriptionMax
          )
          .optional(),
        color: zod.coerce
          .string()
          .min(timeTrackingControllerLastResponseUserLeaveTypesItemColorMin)
          .max(timeTrackingControllerLastResponseUserLeaveTypesItemColorMax)
          .optional(),
        allowedPerYear: zod.object({
          days: zod.coerce.number(),
          hours: zod.coerce.number(),
          minutes: zod.coerce.number(),
          seconds: zod.coerce.number(),
          milliseconds: zod.coerce.number(),
        }),
        isFreebie: zod.coerce.boolean().optional(),
        isWithAttachment: zod.coerce.boolean().optional(),
      })
    ),
    layouts: zod.array(
      zod.object({
        uuid: zod.coerce.string(),
        user: zod.any(),
        layouts: zod.array(
          zod.object({
            i: zod.coerce.string(),
            x: zod.coerce.number(),
            y: zod.coerce.number(),
            w: zod.coerce.number(),
            h: zod.coerce.number(),
            minW: zod.coerce.number().optional(),
            maxW: zod.coerce.number().optional(),
            minH: zod.coerce.number().optional(),
            maxH: zod.coerce.number().optional(),
            static: zod.coerce.boolean().optional(),
            isBounded: zod.coerce.boolean().optional(),
            isDraggable: zod.coerce.boolean().optional(),
            isResizable: zod.coerce.boolean().optional(),
          })
        ),
      })
    ),
  }),
  timeZone: zod.coerce.string(),
})

/**
 * @summary Get last work information
 */
export const timeTrackingControllerWorkInfoHeader = zod.object({
  'x-time-zone': zod.coerce.string(),
})

export const timeTrackingControllerWorkInfoResponseUserFirstNameMax = 100
export const timeTrackingControllerWorkInfoResponseUserLastNameMax = 100
export const timeTrackingControllerWorkInfoResponseUserAddressLatMin = -90

export const timeTrackingControllerWorkInfoResponseUserAddressLatMax = 90
export const timeTrackingControllerWorkInfoResponseUserAddressLonMin = -180

export const timeTrackingControllerWorkInfoResponseUserAddressLonMax = 180
export const timeTrackingControllerWorkInfoResponseUserLeaveTypesItemNameMax = 255
export const timeTrackingControllerWorkInfoResponseUserLeaveTypesItemDescriptionMax = 1024
export const timeTrackingControllerWorkInfoResponseUserLeaveTypesItemColorMin = 7

export const timeTrackingControllerWorkInfoResponseUserLeaveTypesItemColorMax = 7
export const timeTrackingControllerWorkInfoResponseProjectSerialMax = 50
export const timeTrackingControllerWorkInfoResponseProjectCompletionPercentageMin = 0

export const timeTrackingControllerWorkInfoResponseProjectCompletionPercentageMax = 100
export const timeTrackingControllerWorkInfoResponseProjectRoadmapsItemTagNameMax = 50
export const timeTrackingControllerWorkInfoResponseProjectRoadmapsItemColorMin = 7

export const timeTrackingControllerWorkInfoResponseProjectRoadmapsItemColorMax = 7
export const timeTrackingControllerWorkInfoResponseProjectRoadmapsItemItemsItemColorMin = 7

export const timeTrackingControllerWorkInfoResponseProjectRoadmapsItemItemsItemColorMax = 7
export const timeTrackingControllerWorkInfoResponseProjectProjectManagersItemFirstNameMax = 100
export const timeTrackingControllerWorkInfoResponseProjectProjectManagersItemLastNameMax = 100
export const timeTrackingControllerWorkInfoResponseProjectProjectManagersItemAddressLatMin =
  -90

export const timeTrackingControllerWorkInfoResponseProjectProjectManagersItemAddressLatMax = 90
export const timeTrackingControllerWorkInfoResponseProjectProjectManagersItemAddressLonMin =
  -180

export const timeTrackingControllerWorkInfoResponseProjectProjectManagersItemAddressLonMax = 180
export const timeTrackingControllerWorkInfoResponseProjectProjectManagersItemLeaveTypesItemNameMax = 255
export const timeTrackingControllerWorkInfoResponseProjectProjectManagersItemLeaveTypesItemDescriptionMax = 1024
export const timeTrackingControllerWorkInfoResponseProjectProjectManagersItemLeaveTypesItemColorMin = 7

export const timeTrackingControllerWorkInfoResponseProjectProjectManagersItemLeaveTypesItemColorMax = 7
export const timeTrackingControllerWorkInfoResponseProjectTotalAllocationsMin = 0
export const timeTrackingControllerWorkInfoResponseProjectAddressLatMin = -90

export const timeTrackingControllerWorkInfoResponseProjectAddressLatMax = 90
export const timeTrackingControllerWorkInfoResponseProjectAddressLonMin = -180

export const timeTrackingControllerWorkInfoResponseProjectAddressLonMax = 180

export const timeTrackingControllerWorkInfoResponse = zod.object({
  date: zod.coerce.string().datetime(),
  startAt: zod.coerce.string().datetime(),
  endAt: zod.coerce.string().datetime(),
  totalDuration: zod.object({
    days: zod.coerce.number(),
    hours: zod.coerce.number(),
    minutes: zod.coerce.number(),
    seconds: zod.coerce.number(),
    milliseconds: zod.coerce.number(),
  }),
  breakDuration: zod.object({
    days: zod.coerce.number(),
    hours: zod.coerce.number(),
    minutes: zod.coerce.number(),
    seconds: zod.coerce.number(),
    milliseconds: zod.coerce.number(),
  }),
  workDuration: zod.object({
    days: zod.coerce.number(),
    hours: zod.coerce.number(),
    minutes: zod.coerce.number(),
    seconds: zod.coerce.number(),
    milliseconds: zod.coerce.number(),
  }),
  userUuid: zod.coerce.string().uuid(),
  user: zod.object({
    uuid: zod.coerce.string().uuid(),
    email: zod.coerce.string().email(),
    firstName: zod.coerce
      .string()
      .max(timeTrackingControllerWorkInfoResponseUserFirstNameMax),
    lastName: zod.coerce
      .string()
      .max(timeTrackingControllerWorkInfoResponseUserLastNameMax),
    dateOfBirth: zod.coerce.date().optional(),
    phoneNumber: zod.coerce.string().optional(),
    status: zod.enum(['Active', 'Terminated']),
    permissions: zod.array(
      zod.enum(['admin', 'human-resource', 'user']).optional()
    ),
    role: zod
      .object({
        uuid: zod.coerce.string().uuid(),
        name: zod.coerce.string(),
      })
      .optional(),
    department: zod
      .object({
        uuid: zod.coerce.string().uuid(),
        name: zod.coerce.string(),
      })
      .optional(),
    createdAt: zod.coerce.string().datetime(),
    updatedAt: zod.coerce.string().datetime(),
    address: zod
      .object({
        geoLocation: zod
          .object({
            x: zod.coerce.number().optional(),
            y: zod.coerce.number().optional(),
          })
          .nullish(),
        lat: zod.coerce
          .number()
          .min(timeTrackingControllerWorkInfoResponseUserAddressLatMin)
          .max(timeTrackingControllerWorkInfoResponseUserAddressLatMax)
          .optional(),
        lon: zod.coerce
          .number()
          .min(timeTrackingControllerWorkInfoResponseUserAddressLonMin)
          .max(timeTrackingControllerWorkInfoResponseUserAddressLonMax)
          .optional(),
        uuid: zod.coerce.string(),
        country: zod.coerce.string().optional(),
        city: zod.coerce.string().optional(),
        street: zod.coerce.string().optional(),
        postcode: zod.coerce.string().optional(),
      })
      .optional(),
    profilePicture: zod.coerce.string(),
    fullName: zod.coerce.string(),
    leaveTypes: zod.array(
      zod.object({
        uuid: zod.coerce.string().uuid(),
        name: zod.coerce
          .string()
          .min(1)
          .max(timeTrackingControllerWorkInfoResponseUserLeaveTypesItemNameMax),
        description: zod.coerce
          .string()
          .max(
            timeTrackingControllerWorkInfoResponseUserLeaveTypesItemDescriptionMax
          )
          .optional(),
        color: zod.coerce
          .string()
          .min(timeTrackingControllerWorkInfoResponseUserLeaveTypesItemColorMin)
          .max(timeTrackingControllerWorkInfoResponseUserLeaveTypesItemColorMax)
          .optional(),
        allowedPerYear: zod.object({
          days: zod.coerce.number(),
          hours: zod.coerce.number(),
          minutes: zod.coerce.number(),
          seconds: zod.coerce.number(),
          milliseconds: zod.coerce.number(),
        }),
        isFreebie: zod.coerce.boolean().optional(),
        isWithAttachment: zod.coerce.boolean().optional(),
      })
    ),
    layouts: zod.array(
      zod.object({
        uuid: zod.coerce.string(),
        user: zod.any(),
        layouts: zod.array(
          zod.object({
            i: zod.coerce.string(),
            x: zod.coerce.number(),
            y: zod.coerce.number(),
            w: zod.coerce.number(),
            h: zod.coerce.number(),
            minW: zod.coerce.number().optional(),
            maxW: zod.coerce.number().optional(),
            minH: zod.coerce.number().optional(),
            maxH: zod.coerce.number().optional(),
            static: zod.coerce.boolean().optional(),
            isBounded: zod.coerce.boolean().optional(),
            isDraggable: zod.coerce.boolean().optional(),
            isResizable: zod.coerce.boolean().optional(),
          })
        ),
      })
    ),
  }),
  status: zod.enum(['NotStarted', 'OnWork', 'OnBreak', 'OnTimeOff', 'Ended']),
  project: zod
    .object({
      serial: zod.coerce
        .string()
        .max(timeTrackingControllerWorkInfoResponseProjectSerialMax)
        .optional(),
      projectSize: zod.coerce.number(),
      guaranteedUntil: zod.coerce.date().optional(),
      projectType: zod.object({
        name: zod.coerce.string(),
        uuid: zod.coerce.string(),
      }),
      completionPercentage: zod.coerce
        .number()
        .min(
          timeTrackingControllerWorkInfoResponseProjectCompletionPercentageMin
        )
        .max(
          timeTrackingControllerWorkInfoResponseProjectCompletionPercentageMax
        )
        .optional(),
      startedAt: zod.coerce.date().optional(),
      estimatedEndAt: zod.coerce.date().optional(),
      endAt: zod.coerce.date().optional(),
      status: zod.enum(['Active', 'In Active', 'Potential']).optional(),
      milestones: zod
        .array(
          zod.object({
            uuid: zod.coerce.string().uuid(),
            date: zod.coerce.date(),
            iconUrl: zod.coerce.string().nullable(),
            description: zod.coerce.string().nullable(),
            projectUuid: zod.coerce.string().uuid(),
            createdAt: zod.coerce.string().datetime(),
            updatedAt: zod.coerce.string().datetime(),
            project: zod.any(),
          })
        )
        .optional(),
      roadmaps: zod
        .array(
          zod.object({
            uuid: zod.coerce.string().uuid(),
            startAt: zod.coerce.date(),
            endAt: zod.coerce.date(),
            isVisible: zod.coerce.boolean().optional(),
            project: zod.any(),
            tag: zod.object({
              uuid: zod.coerce.string().uuid(),
              parent: zod.any().nullish(),
              name: zod.coerce
                .string()
                .max(
                  timeTrackingControllerWorkInfoResponseProjectRoadmapsItemTagNameMax
                ),
              companyType: zod
                .enum(['Client', 'Contractor', 'Other'])
                .optional(),
            }),
            color: zod.coerce
              .string()
              .min(
                timeTrackingControllerWorkInfoResponseProjectRoadmapsItemColorMin
              )
              .max(
                timeTrackingControllerWorkInfoResponseProjectRoadmapsItemColorMax
              )
              .nullish(),
            items: zod.array(
              zod.object({
                uuid: zod.coerce.string().uuid(),
                color: zod.coerce
                  .string()
                  .min(
                    timeTrackingControllerWorkInfoResponseProjectRoadmapsItemItemsItemColorMin
                  )
                  .max(
                    timeTrackingControllerWorkInfoResponseProjectRoadmapsItemItemsItemColorMax
                  )
                  .nullish(),
                startAt: zod.coerce.date(),
                endAt: zod.coerce.date(),
                description: zod.coerce.string().nullable(),
                roadmap: zod.any().optional(),
              })
            ),
          })
        )
        .optional(),
      projectManagers: zod.array(
        zod.object({
          uuid: zod.coerce.string().uuid(),
          email: zod.coerce.string().email(),
          firstName: zod.coerce
            .string()
            .max(
              timeTrackingControllerWorkInfoResponseProjectProjectManagersItemFirstNameMax
            ),
          lastName: zod.coerce
            .string()
            .max(
              timeTrackingControllerWorkInfoResponseProjectProjectManagersItemLastNameMax
            ),
          dateOfBirth: zod.coerce.date().optional(),
          phoneNumber: zod.coerce.string().optional(),
          status: zod.enum(['Active', 'Terminated']),
          permissions: zod.array(
            zod.enum(['admin', 'human-resource', 'user']).optional()
          ),
          role: zod
            .object({
              uuid: zod.coerce.string().uuid(),
              name: zod.coerce.string(),
            })
            .optional(),
          department: zod
            .object({
              uuid: zod.coerce.string().uuid(),
              name: zod.coerce.string(),
            })
            .optional(),
          createdAt: zod.coerce.string().datetime(),
          updatedAt: zod.coerce.string().datetime(),
          address: zod
            .object({
              geoLocation: zod
                .object({
                  x: zod.coerce.number().optional(),
                  y: zod.coerce.number().optional(),
                })
                .nullish(),
              lat: zod.coerce
                .number()
                .min(
                  timeTrackingControllerWorkInfoResponseProjectProjectManagersItemAddressLatMin
                )
                .max(
                  timeTrackingControllerWorkInfoResponseProjectProjectManagersItemAddressLatMax
                )
                .optional(),
              lon: zod.coerce
                .number()
                .min(
                  timeTrackingControllerWorkInfoResponseProjectProjectManagersItemAddressLonMin
                )
                .max(
                  timeTrackingControllerWorkInfoResponseProjectProjectManagersItemAddressLonMax
                )
                .optional(),
              uuid: zod.coerce.string(),
              country: zod.coerce.string().optional(),
              city: zod.coerce.string().optional(),
              street: zod.coerce.string().optional(),
              postcode: zod.coerce.string().optional(),
            })
            .optional(),
          profilePicture: zod.coerce.string(),
          fullName: zod.coerce.string(),
          leaveTypes: zod.array(
            zod.object({
              uuid: zod.coerce.string().uuid(),
              name: zod.coerce
                .string()
                .min(1)
                .max(
                  timeTrackingControllerWorkInfoResponseProjectProjectManagersItemLeaveTypesItemNameMax
                ),
              description: zod.coerce
                .string()
                .max(
                  timeTrackingControllerWorkInfoResponseProjectProjectManagersItemLeaveTypesItemDescriptionMax
                )
                .optional(),
              color: zod.coerce
                .string()
                .min(
                  timeTrackingControllerWorkInfoResponseProjectProjectManagersItemLeaveTypesItemColorMin
                )
                .max(
                  timeTrackingControllerWorkInfoResponseProjectProjectManagersItemLeaveTypesItemColorMax
                )
                .optional(),
              allowedPerYear: zod.object({
                days: zod.coerce.number(),
                hours: zod.coerce.number(),
                minutes: zod.coerce.number(),
                seconds: zod.coerce.number(),
                milliseconds: zod.coerce.number(),
              }),
              isFreebie: zod.coerce.boolean().optional(),
              isWithAttachment: zod.coerce.boolean().optional(),
            })
          ),
          layouts: zod.array(
            zod.object({
              uuid: zod.coerce.string(),
              user: zod.any(),
              layouts: zod.array(
                zod.object({
                  i: zod.coerce.string(),
                  x: zod.coerce.number(),
                  y: zod.coerce.number(),
                  w: zod.coerce.number(),
                  h: zod.coerce.number(),
                  minW: zod.coerce.number().optional(),
                  maxW: zod.coerce.number().optional(),
                  minH: zod.coerce.number().optional(),
                  maxH: zod.coerce.number().optional(),
                  static: zod.coerce.boolean().optional(),
                  isBounded: zod.coerce.boolean().optional(),
                  isDraggable: zod.coerce.boolean().optional(),
                  isResizable: zod.coerce.boolean().optional(),
                })
              ),
            })
          ),
        })
      ),
      totalAllocations: zod.coerce
        .number()
        .min(timeTrackingControllerWorkInfoResponseProjectTotalAllocationsMin),
      uuid: zod.coerce.string(),
      name: zod.coerce.string(),
      address: zod.object({
        geoLocation: zod
          .object({
            x: zod.coerce.number().optional(),
            y: zod.coerce.number().optional(),
          })
          .nullish(),
        lat: zod.coerce
          .number()
          .min(timeTrackingControllerWorkInfoResponseProjectAddressLatMin)
          .max(timeTrackingControllerWorkInfoResponseProjectAddressLatMax)
          .optional(),
        lon: zod.coerce
          .number()
          .min(timeTrackingControllerWorkInfoResponseProjectAddressLonMin)
          .max(timeTrackingControllerWorkInfoResponseProjectAddressLonMax)
          .optional(),
        uuid: zod.coerce.string(),
        country: zod.coerce.string().optional(),
        city: zod.coerce.string().optional(),
        street: zod.coerce.string().optional(),
        postcode: zod.coerce.string().optional(),
      }),
      createdAt: zod.coerce.string().datetime(),
      updatedAt: zod.coerce.string().datetime(),
    })
    .nullish(),
  workType: zod.enum(['home_work', 'office_work', 'site_work', 'transit']),
})

/**
 * @summary Create new time tracking record
 */
export const timeTrackingControllerCreateHeader = zod.object({
  'x-time-zone': zod.coerce.string(),
})

export const timeTrackingControllerCreateBody = zod.object({
  type: zod
    .enum(['home_work', 'office_work', 'site_work', 'transit'])
    .optional(),
  project: zod
    .object({
      uuid: zod.coerce.string().uuid(),
    })
    .nullish(),
  startAt: zod.coerce.string().datetime(),
  endAt: zod.coerce.string().datetime(),
  timeType: zod.enum(['work', 'break', 'time-off']).optional(),
  isClockOut: zod.coerce.boolean().optional(),
})

/**
 * @summary Get list of time tracking records for current logged-in user
 */
export const timeTrackingControllerFindAllQueryParams = zod.object({
  page: zod.coerce.number().optional(),
  limit: zod.coerce.number().optional(),
  'filter.startAt': zod.array(zod.coerce.string()).optional(),
  'filter.endAt': zod.array(zod.coerce.string()).optional(),
  sortBy: zod
    .array(
      zod.enum([
        'uuid:ASC',
        'uuid:DESC',
        'type:ASC',
        'type:DESC',
        'startAt:ASC',
        'startAt:DESC',
        'endAt:ASC',
        'endAt:DESC',
        'createdAt:ASC',
        'createdAt:DESC',
        'updatedAt:ASC',
        'updatedAt:DESC',
        'project.name:ASC',
        'project.name:DESC',
      ])
    )
    .optional(),
})

export const timeTrackingControllerFindAllHeader = zod.object({
  'x-time-zone': zod.coerce.string(),
})

export const timeTrackingControllerFindAllResponseDataItemProjectSerialMax = 50
export const timeTrackingControllerFindAllResponseDataItemProjectCompletionPercentageMin = 0

export const timeTrackingControllerFindAllResponseDataItemProjectCompletionPercentageMax = 100
export const timeTrackingControllerFindAllResponseDataItemProjectRoadmapsItemTagNameMax = 50
export const timeTrackingControllerFindAllResponseDataItemProjectRoadmapsItemColorMin = 7

export const timeTrackingControllerFindAllResponseDataItemProjectRoadmapsItemColorMax = 7
export const timeTrackingControllerFindAllResponseDataItemProjectRoadmapsItemItemsItemColorMin = 7

export const timeTrackingControllerFindAllResponseDataItemProjectRoadmapsItemItemsItemColorMax = 7
export const timeTrackingControllerFindAllResponseDataItemProjectProjectManagersItemFirstNameMax = 100
export const timeTrackingControllerFindAllResponseDataItemProjectProjectManagersItemLastNameMax = 100
export const timeTrackingControllerFindAllResponseDataItemProjectProjectManagersItemAddressLatMin =
  -90

export const timeTrackingControllerFindAllResponseDataItemProjectProjectManagersItemAddressLatMax = 90
export const timeTrackingControllerFindAllResponseDataItemProjectProjectManagersItemAddressLonMin =
  -180

export const timeTrackingControllerFindAllResponseDataItemProjectProjectManagersItemAddressLonMax = 180
export const timeTrackingControllerFindAllResponseDataItemProjectProjectManagersItemLeaveTypesItemNameMax = 255
export const timeTrackingControllerFindAllResponseDataItemProjectProjectManagersItemLeaveTypesItemDescriptionMax = 1024
export const timeTrackingControllerFindAllResponseDataItemProjectProjectManagersItemLeaveTypesItemColorMin = 7

export const timeTrackingControllerFindAllResponseDataItemProjectProjectManagersItemLeaveTypesItemColorMax = 7
export const timeTrackingControllerFindAllResponseDataItemProjectTotalAllocationsMin = 0
export const timeTrackingControllerFindAllResponseDataItemProjectAddressLatMin =
  -90

export const timeTrackingControllerFindAllResponseDataItemProjectAddressLatMax = 90
export const timeTrackingControllerFindAllResponseDataItemProjectAddressLonMin =
  -180

export const timeTrackingControllerFindAllResponseDataItemProjectAddressLonMax = 180
export const timeTrackingControllerFindAllResponseDataItemUserFirstNameMax = 100
export const timeTrackingControllerFindAllResponseDataItemUserLastNameMax = 100
export const timeTrackingControllerFindAllResponseDataItemUserAddressLatMin =
  -90

export const timeTrackingControllerFindAllResponseDataItemUserAddressLatMax = 90
export const timeTrackingControllerFindAllResponseDataItemUserAddressLonMin =
  -180

export const timeTrackingControllerFindAllResponseDataItemUserAddressLonMax = 180
export const timeTrackingControllerFindAllResponseDataItemUserLeaveTypesItemNameMax = 255
export const timeTrackingControllerFindAllResponseDataItemUserLeaveTypesItemDescriptionMax = 1024
export const timeTrackingControllerFindAllResponseDataItemUserLeaveTypesItemColorMin = 7

export const timeTrackingControllerFindAllResponseDataItemUserLeaveTypesItemColorMax = 7

export const timeTrackingControllerFindAllResponse = zod
  .object({
    data: zod.array(zod.object({})),
    meta: zod.object({
      itemsPerPage: zod.coerce.number(),
      totalItems: zod.coerce.number(),
      currentPage: zod.coerce.number(),
      totalPages: zod.coerce.number(),
      sortBy: zod
        .array(zod.array(zod.coerce.string().or(zod.enum(['ASC', 'DESC']))))
        .optional(),
      searchBy: zod.array(zod.coerce.string()).optional(),
      search: zod.coerce.string().optional(),
      select: zod.array(zod.coerce.string()).optional(),
      filter: zod.object({}).optional(),
    }),
    links: zod.object({
      first: zod.coerce.string().optional(),
      previous: zod.coerce.string().optional(),
      current: zod.coerce.string().optional(),
      next: zod.coerce.string().optional(),
      last: zod.coerce.string().optional(),
    }),
  })
  .and(
    zod.object({
      data: zod
        .array(
          zod.object({
            uuid: zod.coerce.string().uuid(),
            startAt: zod.coerce.string().datetime(),
            endAt: zod.coerce.string().datetime().optional(),
            project: zod
              .object({
                serial: zod.coerce
                  .string()
                  .max(
                    timeTrackingControllerFindAllResponseDataItemProjectSerialMax
                  )
                  .optional(),
                projectSize: zod.coerce.number(),
                guaranteedUntil: zod.coerce.date().optional(),
                projectType: zod.object({
                  name: zod.coerce.string(),
                  uuid: zod.coerce.string(),
                }),
                completionPercentage: zod.coerce
                  .number()
                  .min(
                    timeTrackingControllerFindAllResponseDataItemProjectCompletionPercentageMin
                  )
                  .max(
                    timeTrackingControllerFindAllResponseDataItemProjectCompletionPercentageMax
                  )
                  .optional(),
                startedAt: zod.coerce.date().optional(),
                estimatedEndAt: zod.coerce.date().optional(),
                endAt: zod.coerce.date().optional(),
                status: zod
                  .enum(['Active', 'In Active', 'Potential'])
                  .optional(),
                milestones: zod
                  .array(
                    zod.object({
                      uuid: zod.coerce.string().uuid(),
                      date: zod.coerce.date(),
                      iconUrl: zod.coerce.string().nullable(),
                      description: zod.coerce.string().nullable(),
                      projectUuid: zod.coerce.string().uuid(),
                      createdAt: zod.coerce.string().datetime(),
                      updatedAt: zod.coerce.string().datetime(),
                      project: zod.any(),
                    })
                  )
                  .optional(),
                roadmaps: zod
                  .array(
                    zod.object({
                      uuid: zod.coerce.string().uuid(),
                      startAt: zod.coerce.date(),
                      endAt: zod.coerce.date(),
                      isVisible: zod.coerce.boolean().optional(),
                      project: zod.any(),
                      tag: zod.object({
                        uuid: zod.coerce.string().uuid(),
                        parent: zod.any().nullish(),
                        name: zod.coerce
                          .string()
                          .max(
                            timeTrackingControllerFindAllResponseDataItemProjectRoadmapsItemTagNameMax
                          ),
                        companyType: zod
                          .enum(['Client', 'Contractor', 'Other'])
                          .optional(),
                      }),
                      color: zod.coerce
                        .string()
                        .min(
                          timeTrackingControllerFindAllResponseDataItemProjectRoadmapsItemColorMin
                        )
                        .max(
                          timeTrackingControllerFindAllResponseDataItemProjectRoadmapsItemColorMax
                        )
                        .nullish(),
                      items: zod.array(
                        zod.object({
                          uuid: zod.coerce.string().uuid(),
                          color: zod.coerce
                            .string()
                            .min(
                              timeTrackingControllerFindAllResponseDataItemProjectRoadmapsItemItemsItemColorMin
                            )
                            .max(
                              timeTrackingControllerFindAllResponseDataItemProjectRoadmapsItemItemsItemColorMax
                            )
                            .nullish(),
                          startAt: zod.coerce.date(),
                          endAt: zod.coerce.date(),
                          description: zod.coerce.string().nullable(),
                          roadmap: zod.any().optional(),
                        })
                      ),
                    })
                  )
                  .optional(),
                projectManagers: zod.array(
                  zod.object({
                    uuid: zod.coerce.string().uuid(),
                    email: zod.coerce.string().email(),
                    firstName: zod.coerce
                      .string()
                      .max(
                        timeTrackingControllerFindAllResponseDataItemProjectProjectManagersItemFirstNameMax
                      ),
                    lastName: zod.coerce
                      .string()
                      .max(
                        timeTrackingControllerFindAllResponseDataItemProjectProjectManagersItemLastNameMax
                      ),
                    dateOfBirth: zod.coerce.date().optional(),
                    phoneNumber: zod.coerce.string().optional(),
                    status: zod.enum(['Active', 'Terminated']),
                    permissions: zod.array(
                      zod.enum(['admin', 'human-resource', 'user']).optional()
                    ),
                    role: zod
                      .object({
                        uuid: zod.coerce.string().uuid(),
                        name: zod.coerce.string(),
                      })
                      .optional(),
                    department: zod
                      .object({
                        uuid: zod.coerce.string().uuid(),
                        name: zod.coerce.string(),
                      })
                      .optional(),
                    createdAt: zod.coerce.string().datetime(),
                    updatedAt: zod.coerce.string().datetime(),
                    address: zod
                      .object({
                        geoLocation: zod
                          .object({
                            x: zod.coerce.number().optional(),
                            y: zod.coerce.number().optional(),
                          })
                          .nullish(),
                        lat: zod.coerce
                          .number()
                          .min(
                            timeTrackingControllerFindAllResponseDataItemProjectProjectManagersItemAddressLatMin
                          )
                          .max(
                            timeTrackingControllerFindAllResponseDataItemProjectProjectManagersItemAddressLatMax
                          )
                          .optional(),
                        lon: zod.coerce
                          .number()
                          .min(
                            timeTrackingControllerFindAllResponseDataItemProjectProjectManagersItemAddressLonMin
                          )
                          .max(
                            timeTrackingControllerFindAllResponseDataItemProjectProjectManagersItemAddressLonMax
                          )
                          .optional(),
                        uuid: zod.coerce.string(),
                        country: zod.coerce.string().optional(),
                        city: zod.coerce.string().optional(),
                        street: zod.coerce.string().optional(),
                        postcode: zod.coerce.string().optional(),
                      })
                      .optional(),
                    profilePicture: zod.coerce.string(),
                    fullName: zod.coerce.string(),
                    leaveTypes: zod.array(
                      zod.object({
                        uuid: zod.coerce.string().uuid(),
                        name: zod.coerce
                          .string()
                          .min(1)
                          .max(
                            timeTrackingControllerFindAllResponseDataItemProjectProjectManagersItemLeaveTypesItemNameMax
                          ),
                        description: zod.coerce
                          .string()
                          .max(
                            timeTrackingControllerFindAllResponseDataItemProjectProjectManagersItemLeaveTypesItemDescriptionMax
                          )
                          .optional(),
                        color: zod.coerce
                          .string()
                          .min(
                            timeTrackingControllerFindAllResponseDataItemProjectProjectManagersItemLeaveTypesItemColorMin
                          )
                          .max(
                            timeTrackingControllerFindAllResponseDataItemProjectProjectManagersItemLeaveTypesItemColorMax
                          )
                          .optional(),
                        allowedPerYear: zod.object({
                          days: zod.coerce.number(),
                          hours: zod.coerce.number(),
                          minutes: zod.coerce.number(),
                          seconds: zod.coerce.number(),
                          milliseconds: zod.coerce.number(),
                        }),
                        isFreebie: zod.coerce.boolean().optional(),
                        isWithAttachment: zod.coerce.boolean().optional(),
                      })
                    ),
                    layouts: zod.array(
                      zod.object({
                        uuid: zod.coerce.string(),
                        user: zod.any(),
                        layouts: zod.array(
                          zod.object({
                            i: zod.coerce.string(),
                            x: zod.coerce.number(),
                            y: zod.coerce.number(),
                            w: zod.coerce.number(),
                            h: zod.coerce.number(),
                            minW: zod.coerce.number().optional(),
                            maxW: zod.coerce.number().optional(),
                            minH: zod.coerce.number().optional(),
                            maxH: zod.coerce.number().optional(),
                            static: zod.coerce.boolean().optional(),
                            isBounded: zod.coerce.boolean().optional(),
                            isDraggable: zod.coerce.boolean().optional(),
                            isResizable: zod.coerce.boolean().optional(),
                          })
                        ),
                      })
                    ),
                  })
                ),
                totalAllocations: zod.coerce
                  .number()
                  .min(
                    timeTrackingControllerFindAllResponseDataItemProjectTotalAllocationsMin
                  ),
                uuid: zod.coerce.string(),
                name: zod.coerce.string(),
                address: zod.object({
                  geoLocation: zod
                    .object({
                      x: zod.coerce.number().optional(),
                      y: zod.coerce.number().optional(),
                    })
                    .nullish(),
                  lat: zod.coerce
                    .number()
                    .min(
                      timeTrackingControllerFindAllResponseDataItemProjectAddressLatMin
                    )
                    .max(
                      timeTrackingControllerFindAllResponseDataItemProjectAddressLatMax
                    )
                    .optional(),
                  lon: zod.coerce
                    .number()
                    .min(
                      timeTrackingControllerFindAllResponseDataItemProjectAddressLonMin
                    )
                    .max(
                      timeTrackingControllerFindAllResponseDataItemProjectAddressLonMax
                    )
                    .optional(),
                  uuid: zod.coerce.string(),
                  country: zod.coerce.string().optional(),
                  city: zod.coerce.string().optional(),
                  street: zod.coerce.string().optional(),
                  postcode: zod.coerce.string().optional(),
                }),
                createdAt: zod.coerce.string().datetime(),
                updatedAt: zod.coerce.string().datetime(),
              })
              .optional(),
            type: zod
              .enum(['home_work', 'office_work', 'site_work', 'transit'])
              .optional(),
            isClockOut: zod.coerce.boolean().optional(),
            status: zod.enum(['approved', 'pending', 'draft']),
            timeType: zod.enum(['work', 'break', 'time-off']).optional(),
            deletedAt: zod.coerce.string().datetime(),
            createdAt: zod.coerce.string().datetime(),
            updatedAt: zod.coerce.string().datetime(),
            duration: zod.coerce.number(),
            user: zod.object({
              uuid: zod.coerce.string().uuid(),
              email: zod.coerce.string().email(),
              firstName: zod.coerce
                .string()
                .max(
                  timeTrackingControllerFindAllResponseDataItemUserFirstNameMax
                ),
              lastName: zod.coerce
                .string()
                .max(
                  timeTrackingControllerFindAllResponseDataItemUserLastNameMax
                ),
              dateOfBirth: zod.coerce.date().optional(),
              phoneNumber: zod.coerce.string().optional(),
              status: zod.enum(['Active', 'Terminated']),
              permissions: zod.array(
                zod.enum(['admin', 'human-resource', 'user']).optional()
              ),
              role: zod
                .object({
                  uuid: zod.coerce.string().uuid(),
                  name: zod.coerce.string(),
                })
                .optional(),
              department: zod
                .object({
                  uuid: zod.coerce.string().uuid(),
                  name: zod.coerce.string(),
                })
                .optional(),
              createdAt: zod.coerce.string().datetime(),
              updatedAt: zod.coerce.string().datetime(),
              address: zod
                .object({
                  geoLocation: zod
                    .object({
                      x: zod.coerce.number().optional(),
                      y: zod.coerce.number().optional(),
                    })
                    .nullish(),
                  lat: zod.coerce
                    .number()
                    .min(
                      timeTrackingControllerFindAllResponseDataItemUserAddressLatMin
                    )
                    .max(
                      timeTrackingControllerFindAllResponseDataItemUserAddressLatMax
                    )
                    .optional(),
                  lon: zod.coerce
                    .number()
                    .min(
                      timeTrackingControllerFindAllResponseDataItemUserAddressLonMin
                    )
                    .max(
                      timeTrackingControllerFindAllResponseDataItemUserAddressLonMax
                    )
                    .optional(),
                  uuid: zod.coerce.string(),
                  country: zod.coerce.string().optional(),
                  city: zod.coerce.string().optional(),
                  street: zod.coerce.string().optional(),
                  postcode: zod.coerce.string().optional(),
                })
                .optional(),
              profilePicture: zod.coerce.string(),
              fullName: zod.coerce.string(),
              leaveTypes: zod.array(
                zod.object({
                  uuid: zod.coerce.string().uuid(),
                  name: zod.coerce
                    .string()
                    .min(1)
                    .max(
                      timeTrackingControllerFindAllResponseDataItemUserLeaveTypesItemNameMax
                    ),
                  description: zod.coerce
                    .string()
                    .max(
                      timeTrackingControllerFindAllResponseDataItemUserLeaveTypesItemDescriptionMax
                    )
                    .optional(),
                  color: zod.coerce
                    .string()
                    .min(
                      timeTrackingControllerFindAllResponseDataItemUserLeaveTypesItemColorMin
                    )
                    .max(
                      timeTrackingControllerFindAllResponseDataItemUserLeaveTypesItemColorMax
                    )
                    .optional(),
                  allowedPerYear: zod.object({
                    days: zod.coerce.number(),
                    hours: zod.coerce.number(),
                    minutes: zod.coerce.number(),
                    seconds: zod.coerce.number(),
                    milliseconds: zod.coerce.number(),
                  }),
                  isFreebie: zod.coerce.boolean().optional(),
                  isWithAttachment: zod.coerce.boolean().optional(),
                })
              ),
              layouts: zod.array(
                zod.object({
                  uuid: zod.coerce.string(),
                  user: zod.any(),
                  layouts: zod.array(
                    zod.object({
                      i: zod.coerce.string(),
                      x: zod.coerce.number(),
                      y: zod.coerce.number(),
                      w: zod.coerce.number(),
                      h: zod.coerce.number(),
                      minW: zod.coerce.number().optional(),
                      maxW: zod.coerce.number().optional(),
                      minH: zod.coerce.number().optional(),
                      maxH: zod.coerce.number().optional(),
                      static: zod.coerce.boolean().optional(),
                      isBounded: zod.coerce.boolean().optional(),
                      isDraggable: zod.coerce.boolean().optional(),
                      isResizable: zod.coerce.boolean().optional(),
                    })
                  ),
                })
              ),
            }),
            timeZone: zod.coerce.string(),
          })
        )
        .optional(),
      meta: zod
        .object({
          select: zod.array(zod.coerce.string()).optional(),
          filter: zod
            .object({
              startAt: zod.coerce
                .string()
                .or(zod.array(zod.coerce.string()))
                .optional(),
              endAt: zod.coerce
                .string()
                .or(zod.array(zod.coerce.string()))
                .optional(),
            })
            .optional(),
        })
        .optional(),
    })
  )

/**
 * @summary Preview new time tracking record changes
 */
export const timeTrackingControllerPreviewHeader = zod.object({
  'x-time-zone': zod.coerce.string(),
})

export const timeTrackingControllerPreviewBody = zod.object({
  type: zod
    .enum(['home_work', 'office_work', 'site_work', 'transit'])
    .optional(),
  project: zod
    .object({
      uuid: zod.coerce.string().uuid(),
    })
    .nullish(),
  startAt: zod.coerce.string().datetime(),
  endAt: zod.coerce.string().datetime(),
  timeType: zod.enum(['work', 'break', 'time-off']).optional(),
  isClockOut: zod.coerce.boolean().optional(),
})

export const timeTrackingControllerPreviewResponseNewRecordsItemProjectSerialMax = 50
export const timeTrackingControllerPreviewResponseNewRecordsItemProjectCompletionPercentageMin = 0

export const timeTrackingControllerPreviewResponseNewRecordsItemProjectCompletionPercentageMax = 100
export const timeTrackingControllerPreviewResponseNewRecordsItemProjectRoadmapsItemTagNameMax = 50
export const timeTrackingControllerPreviewResponseNewRecordsItemProjectRoadmapsItemColorMin = 7

export const timeTrackingControllerPreviewResponseNewRecordsItemProjectRoadmapsItemColorMax = 7
export const timeTrackingControllerPreviewResponseNewRecordsItemProjectRoadmapsItemItemsItemColorMin = 7

export const timeTrackingControllerPreviewResponseNewRecordsItemProjectRoadmapsItemItemsItemColorMax = 7
export const timeTrackingControllerPreviewResponseNewRecordsItemProjectProjectManagersItemFirstNameMax = 100
export const timeTrackingControllerPreviewResponseNewRecordsItemProjectProjectManagersItemLastNameMax = 100
export const timeTrackingControllerPreviewResponseNewRecordsItemProjectProjectManagersItemAddressLatMin =
  -90

export const timeTrackingControllerPreviewResponseNewRecordsItemProjectProjectManagersItemAddressLatMax = 90
export const timeTrackingControllerPreviewResponseNewRecordsItemProjectProjectManagersItemAddressLonMin =
  -180

export const timeTrackingControllerPreviewResponseNewRecordsItemProjectProjectManagersItemAddressLonMax = 180
export const timeTrackingControllerPreviewResponseNewRecordsItemProjectProjectManagersItemLeaveTypesItemNameMax = 255
export const timeTrackingControllerPreviewResponseNewRecordsItemProjectProjectManagersItemLeaveTypesItemDescriptionMax = 1024
export const timeTrackingControllerPreviewResponseNewRecordsItemProjectProjectManagersItemLeaveTypesItemColorMin = 7

export const timeTrackingControllerPreviewResponseNewRecordsItemProjectProjectManagersItemLeaveTypesItemColorMax = 7
export const timeTrackingControllerPreviewResponseNewRecordsItemProjectTotalAllocationsMin = 0
export const timeTrackingControllerPreviewResponseNewRecordsItemProjectAddressLatMin =
  -90

export const timeTrackingControllerPreviewResponseNewRecordsItemProjectAddressLatMax = 90
export const timeTrackingControllerPreviewResponseNewRecordsItemProjectAddressLonMin =
  -180

export const timeTrackingControllerPreviewResponseNewRecordsItemProjectAddressLonMax = 180
export const timeTrackingControllerPreviewResponseNewRecordsItemUserFirstNameMax = 100
export const timeTrackingControllerPreviewResponseNewRecordsItemUserLastNameMax = 100
export const timeTrackingControllerPreviewResponseNewRecordsItemUserAddressLatMin =
  -90

export const timeTrackingControllerPreviewResponseNewRecordsItemUserAddressLatMax = 90
export const timeTrackingControllerPreviewResponseNewRecordsItemUserAddressLonMin =
  -180

export const timeTrackingControllerPreviewResponseNewRecordsItemUserAddressLonMax = 180
export const timeTrackingControllerPreviewResponseNewRecordsItemUserLeaveTypesItemNameMax = 255
export const timeTrackingControllerPreviewResponseNewRecordsItemUserLeaveTypesItemDescriptionMax = 1024
export const timeTrackingControllerPreviewResponseNewRecordsItemUserLeaveTypesItemColorMin = 7

export const timeTrackingControllerPreviewResponseNewRecordsItemUserLeaveTypesItemColorMax = 7
export const timeTrackingControllerPreviewResponseAffectedRecordsItemProjectSerialMax = 50
export const timeTrackingControllerPreviewResponseAffectedRecordsItemProjectCompletionPercentageMin = 0

export const timeTrackingControllerPreviewResponseAffectedRecordsItemProjectCompletionPercentageMax = 100
export const timeTrackingControllerPreviewResponseAffectedRecordsItemProjectRoadmapsItemTagNameMax = 50
export const timeTrackingControllerPreviewResponseAffectedRecordsItemProjectRoadmapsItemColorMin = 7

export const timeTrackingControllerPreviewResponseAffectedRecordsItemProjectRoadmapsItemColorMax = 7
export const timeTrackingControllerPreviewResponseAffectedRecordsItemProjectRoadmapsItemItemsItemColorMin = 7

export const timeTrackingControllerPreviewResponseAffectedRecordsItemProjectRoadmapsItemItemsItemColorMax = 7
export const timeTrackingControllerPreviewResponseAffectedRecordsItemProjectProjectManagersItemFirstNameMax = 100
export const timeTrackingControllerPreviewResponseAffectedRecordsItemProjectProjectManagersItemLastNameMax = 100
export const timeTrackingControllerPreviewResponseAffectedRecordsItemProjectProjectManagersItemAddressLatMin =
  -90

export const timeTrackingControllerPreviewResponseAffectedRecordsItemProjectProjectManagersItemAddressLatMax = 90
export const timeTrackingControllerPreviewResponseAffectedRecordsItemProjectProjectManagersItemAddressLonMin =
  -180

export const timeTrackingControllerPreviewResponseAffectedRecordsItemProjectProjectManagersItemAddressLonMax = 180
export const timeTrackingControllerPreviewResponseAffectedRecordsItemProjectProjectManagersItemLeaveTypesItemNameMax = 255
export const timeTrackingControllerPreviewResponseAffectedRecordsItemProjectProjectManagersItemLeaveTypesItemDescriptionMax = 1024
export const timeTrackingControllerPreviewResponseAffectedRecordsItemProjectProjectManagersItemLeaveTypesItemColorMin = 7

export const timeTrackingControllerPreviewResponseAffectedRecordsItemProjectProjectManagersItemLeaveTypesItemColorMax = 7
export const timeTrackingControllerPreviewResponseAffectedRecordsItemProjectTotalAllocationsMin = 0
export const timeTrackingControllerPreviewResponseAffectedRecordsItemProjectAddressLatMin =
  -90

export const timeTrackingControllerPreviewResponseAffectedRecordsItemProjectAddressLatMax = 90
export const timeTrackingControllerPreviewResponseAffectedRecordsItemProjectAddressLonMin =
  -180

export const timeTrackingControllerPreviewResponseAffectedRecordsItemProjectAddressLonMax = 180
export const timeTrackingControllerPreviewResponseAffectedRecordsItemUserFirstNameMax = 100
export const timeTrackingControllerPreviewResponseAffectedRecordsItemUserLastNameMax = 100
export const timeTrackingControllerPreviewResponseAffectedRecordsItemUserAddressLatMin =
  -90

export const timeTrackingControllerPreviewResponseAffectedRecordsItemUserAddressLatMax = 90
export const timeTrackingControllerPreviewResponseAffectedRecordsItemUserAddressLonMin =
  -180

export const timeTrackingControllerPreviewResponseAffectedRecordsItemUserAddressLonMax = 180
export const timeTrackingControllerPreviewResponseAffectedRecordsItemUserLeaveTypesItemNameMax = 255
export const timeTrackingControllerPreviewResponseAffectedRecordsItemUserLeaveTypesItemDescriptionMax = 1024
export const timeTrackingControllerPreviewResponseAffectedRecordsItemUserLeaveTypesItemColorMin = 7

export const timeTrackingControllerPreviewResponseAffectedRecordsItemUserLeaveTypesItemColorMax = 7
export const timeTrackingControllerPreviewResponseRecordsToRemoveItemProjectSerialMax = 50
export const timeTrackingControllerPreviewResponseRecordsToRemoveItemProjectCompletionPercentageMin = 0

export const timeTrackingControllerPreviewResponseRecordsToRemoveItemProjectCompletionPercentageMax = 100
export const timeTrackingControllerPreviewResponseRecordsToRemoveItemProjectRoadmapsItemTagNameMax = 50
export const timeTrackingControllerPreviewResponseRecordsToRemoveItemProjectRoadmapsItemColorMin = 7

export const timeTrackingControllerPreviewResponseRecordsToRemoveItemProjectRoadmapsItemColorMax = 7
export const timeTrackingControllerPreviewResponseRecordsToRemoveItemProjectRoadmapsItemItemsItemColorMin = 7

export const timeTrackingControllerPreviewResponseRecordsToRemoveItemProjectRoadmapsItemItemsItemColorMax = 7
export const timeTrackingControllerPreviewResponseRecordsToRemoveItemProjectProjectManagersItemFirstNameMax = 100
export const timeTrackingControllerPreviewResponseRecordsToRemoveItemProjectProjectManagersItemLastNameMax = 100
export const timeTrackingControllerPreviewResponseRecordsToRemoveItemProjectProjectManagersItemAddressLatMin =
  -90

export const timeTrackingControllerPreviewResponseRecordsToRemoveItemProjectProjectManagersItemAddressLatMax = 90
export const timeTrackingControllerPreviewResponseRecordsToRemoveItemProjectProjectManagersItemAddressLonMin =
  -180

export const timeTrackingControllerPreviewResponseRecordsToRemoveItemProjectProjectManagersItemAddressLonMax = 180
export const timeTrackingControllerPreviewResponseRecordsToRemoveItemProjectProjectManagersItemLeaveTypesItemNameMax = 255
export const timeTrackingControllerPreviewResponseRecordsToRemoveItemProjectProjectManagersItemLeaveTypesItemDescriptionMax = 1024
export const timeTrackingControllerPreviewResponseRecordsToRemoveItemProjectProjectManagersItemLeaveTypesItemColorMin = 7

export const timeTrackingControllerPreviewResponseRecordsToRemoveItemProjectProjectManagersItemLeaveTypesItemColorMax = 7
export const timeTrackingControllerPreviewResponseRecordsToRemoveItemProjectTotalAllocationsMin = 0
export const timeTrackingControllerPreviewResponseRecordsToRemoveItemProjectAddressLatMin =
  -90

export const timeTrackingControllerPreviewResponseRecordsToRemoveItemProjectAddressLatMax = 90
export const timeTrackingControllerPreviewResponseRecordsToRemoveItemProjectAddressLonMin =
  -180

export const timeTrackingControllerPreviewResponseRecordsToRemoveItemProjectAddressLonMax = 180
export const timeTrackingControllerPreviewResponseRecordsToRemoveItemUserFirstNameMax = 100
export const timeTrackingControllerPreviewResponseRecordsToRemoveItemUserLastNameMax = 100
export const timeTrackingControllerPreviewResponseRecordsToRemoveItemUserAddressLatMin =
  -90

export const timeTrackingControllerPreviewResponseRecordsToRemoveItemUserAddressLatMax = 90
export const timeTrackingControllerPreviewResponseRecordsToRemoveItemUserAddressLonMin =
  -180

export const timeTrackingControllerPreviewResponseRecordsToRemoveItemUserAddressLonMax = 180
export const timeTrackingControllerPreviewResponseRecordsToRemoveItemUserLeaveTypesItemNameMax = 255
export const timeTrackingControllerPreviewResponseRecordsToRemoveItemUserLeaveTypesItemDescriptionMax = 1024
export const timeTrackingControllerPreviewResponseRecordsToRemoveItemUserLeaveTypesItemColorMin = 7

export const timeTrackingControllerPreviewResponseRecordsToRemoveItemUserLeaveTypesItemColorMax = 7
export const timeTrackingControllerPreviewResponseTotalAffectedMin = 0
export const timeTrackingControllerPreviewResponseTotalRemovedMin = 0
export const timeTrackingControllerPreviewResponseTotalNewRecordsMin = 0

export const timeTrackingControllerPreviewResponse = zod.object({
  newRecords: zod.array(
    zod.object({
      uuid: zod.coerce.string().uuid(),
      startAt: zod.coerce.string().datetime(),
      endAt: zod.coerce.string().datetime().optional(),
      project: zod
        .object({
          serial: zod.coerce
            .string()
            .max(
              timeTrackingControllerPreviewResponseNewRecordsItemProjectSerialMax
            )
            .optional(),
          projectSize: zod.coerce.number(),
          guaranteedUntil: zod.coerce.date().optional(),
          projectType: zod.object({
            name: zod.coerce.string(),
            uuid: zod.coerce.string(),
          }),
          completionPercentage: zod.coerce
            .number()
            .min(
              timeTrackingControllerPreviewResponseNewRecordsItemProjectCompletionPercentageMin
            )
            .max(
              timeTrackingControllerPreviewResponseNewRecordsItemProjectCompletionPercentageMax
            )
            .optional(),
          startedAt: zod.coerce.date().optional(),
          estimatedEndAt: zod.coerce.date().optional(),
          endAt: zod.coerce.date().optional(),
          status: zod.enum(['Active', 'In Active', 'Potential']).optional(),
          milestones: zod
            .array(
              zod.object({
                uuid: zod.coerce.string().uuid(),
                date: zod.coerce.date(),
                iconUrl: zod.coerce.string().nullable(),
                description: zod.coerce.string().nullable(),
                projectUuid: zod.coerce.string().uuid(),
                createdAt: zod.coerce.string().datetime(),
                updatedAt: zod.coerce.string().datetime(),
                project: zod.any(),
              })
            )
            .optional(),
          roadmaps: zod
            .array(
              zod.object({
                uuid: zod.coerce.string().uuid(),
                startAt: zod.coerce.date(),
                endAt: zod.coerce.date(),
                isVisible: zod.coerce.boolean().optional(),
                project: zod.any(),
                tag: zod.object({
                  uuid: zod.coerce.string().uuid(),
                  parent: zod.any().nullish(),
                  name: zod.coerce
                    .string()
                    .max(
                      timeTrackingControllerPreviewResponseNewRecordsItemProjectRoadmapsItemTagNameMax
                    ),
                  companyType: zod
                    .enum(['Client', 'Contractor', 'Other'])
                    .optional(),
                }),
                color: zod.coerce
                  .string()
                  .min(
                    timeTrackingControllerPreviewResponseNewRecordsItemProjectRoadmapsItemColorMin
                  )
                  .max(
                    timeTrackingControllerPreviewResponseNewRecordsItemProjectRoadmapsItemColorMax
                  )
                  .nullish(),
                items: zod.array(
                  zod.object({
                    uuid: zod.coerce.string().uuid(),
                    color: zod.coerce
                      .string()
                      .min(
                        timeTrackingControllerPreviewResponseNewRecordsItemProjectRoadmapsItemItemsItemColorMin
                      )
                      .max(
                        timeTrackingControllerPreviewResponseNewRecordsItemProjectRoadmapsItemItemsItemColorMax
                      )
                      .nullish(),
                    startAt: zod.coerce.date(),
                    endAt: zod.coerce.date(),
                    description: zod.coerce.string().nullable(),
                    roadmap: zod.any().optional(),
                  })
                ),
              })
            )
            .optional(),
          projectManagers: zod.array(
            zod.object({
              uuid: zod.coerce.string().uuid(),
              email: zod.coerce.string().email(),
              firstName: zod.coerce
                .string()
                .max(
                  timeTrackingControllerPreviewResponseNewRecordsItemProjectProjectManagersItemFirstNameMax
                ),
              lastName: zod.coerce
                .string()
                .max(
                  timeTrackingControllerPreviewResponseNewRecordsItemProjectProjectManagersItemLastNameMax
                ),
              dateOfBirth: zod.coerce.date().optional(),
              phoneNumber: zod.coerce.string().optional(),
              status: zod.enum(['Active', 'Terminated']),
              permissions: zod.array(
                zod.enum(['admin', 'human-resource', 'user']).optional()
              ),
              role: zod
                .object({
                  uuid: zod.coerce.string().uuid(),
                  name: zod.coerce.string(),
                })
                .optional(),
              department: zod
                .object({
                  uuid: zod.coerce.string().uuid(),
                  name: zod.coerce.string(),
                })
                .optional(),
              createdAt: zod.coerce.string().datetime(),
              updatedAt: zod.coerce.string().datetime(),
              address: zod
                .object({
                  geoLocation: zod
                    .object({
                      x: zod.coerce.number().optional(),
                      y: zod.coerce.number().optional(),
                    })
                    .nullish(),
                  lat: zod.coerce
                    .number()
                    .min(
                      timeTrackingControllerPreviewResponseNewRecordsItemProjectProjectManagersItemAddressLatMin
                    )
                    .max(
                      timeTrackingControllerPreviewResponseNewRecordsItemProjectProjectManagersItemAddressLatMax
                    )
                    .optional(),
                  lon: zod.coerce
                    .number()
                    .min(
                      timeTrackingControllerPreviewResponseNewRecordsItemProjectProjectManagersItemAddressLonMin
                    )
                    .max(
                      timeTrackingControllerPreviewResponseNewRecordsItemProjectProjectManagersItemAddressLonMax
                    )
                    .optional(),
                  uuid: zod.coerce.string(),
                  country: zod.coerce.string().optional(),
                  city: zod.coerce.string().optional(),
                  street: zod.coerce.string().optional(),
                  postcode: zod.coerce.string().optional(),
                })
                .optional(),
              profilePicture: zod.coerce.string(),
              fullName: zod.coerce.string(),
              leaveTypes: zod.array(
                zod.object({
                  uuid: zod.coerce.string().uuid(),
                  name: zod.coerce
                    .string()
                    .min(1)
                    .max(
                      timeTrackingControllerPreviewResponseNewRecordsItemProjectProjectManagersItemLeaveTypesItemNameMax
                    ),
                  description: zod.coerce
                    .string()
                    .max(
                      timeTrackingControllerPreviewResponseNewRecordsItemProjectProjectManagersItemLeaveTypesItemDescriptionMax
                    )
                    .optional(),
                  color: zod.coerce
                    .string()
                    .min(
                      timeTrackingControllerPreviewResponseNewRecordsItemProjectProjectManagersItemLeaveTypesItemColorMin
                    )
                    .max(
                      timeTrackingControllerPreviewResponseNewRecordsItemProjectProjectManagersItemLeaveTypesItemColorMax
                    )
                    .optional(),
                  allowedPerYear: zod.object({
                    days: zod.coerce.number(),
                    hours: zod.coerce.number(),
                    minutes: zod.coerce.number(),
                    seconds: zod.coerce.number(),
                    milliseconds: zod.coerce.number(),
                  }),
                  isFreebie: zod.coerce.boolean().optional(),
                  isWithAttachment: zod.coerce.boolean().optional(),
                })
              ),
              layouts: zod.array(
                zod.object({
                  uuid: zod.coerce.string(),
                  user: zod.any(),
                  layouts: zod.array(
                    zod.object({
                      i: zod.coerce.string(),
                      x: zod.coerce.number(),
                      y: zod.coerce.number(),
                      w: zod.coerce.number(),
                      h: zod.coerce.number(),
                      minW: zod.coerce.number().optional(),
                      maxW: zod.coerce.number().optional(),
                      minH: zod.coerce.number().optional(),
                      maxH: zod.coerce.number().optional(),
                      static: zod.coerce.boolean().optional(),
                      isBounded: zod.coerce.boolean().optional(),
                      isDraggable: zod.coerce.boolean().optional(),
                      isResizable: zod.coerce.boolean().optional(),
                    })
                  ),
                })
              ),
            })
          ),
          totalAllocations: zod.coerce
            .number()
            .min(
              timeTrackingControllerPreviewResponseNewRecordsItemProjectTotalAllocationsMin
            ),
          uuid: zod.coerce.string(),
          name: zod.coerce.string(),
          address: zod.object({
            geoLocation: zod
              .object({
                x: zod.coerce.number().optional(),
                y: zod.coerce.number().optional(),
              })
              .nullish(),
            lat: zod.coerce
              .number()
              .min(
                timeTrackingControllerPreviewResponseNewRecordsItemProjectAddressLatMin
              )
              .max(
                timeTrackingControllerPreviewResponseNewRecordsItemProjectAddressLatMax
              )
              .optional(),
            lon: zod.coerce
              .number()
              .min(
                timeTrackingControllerPreviewResponseNewRecordsItemProjectAddressLonMin
              )
              .max(
                timeTrackingControllerPreviewResponseNewRecordsItemProjectAddressLonMax
              )
              .optional(),
            uuid: zod.coerce.string(),
            country: zod.coerce.string().optional(),
            city: zod.coerce.string().optional(),
            street: zod.coerce.string().optional(),
            postcode: zod.coerce.string().optional(),
          }),
          createdAt: zod.coerce.string().datetime(),
          updatedAt: zod.coerce.string().datetime(),
        })
        .optional(),
      type: zod
        .enum(['home_work', 'office_work', 'site_work', 'transit'])
        .optional(),
      isClockOut: zod.coerce.boolean().optional(),
      status: zod.enum(['approved', 'pending', 'draft']),
      timeType: zod.enum(['work', 'break', 'time-off']).optional(),
      deletedAt: zod.coerce.string().datetime(),
      createdAt: zod.coerce.string().datetime(),
      updatedAt: zod.coerce.string().datetime(),
      duration: zod.coerce.number(),
      user: zod.object({
        uuid: zod.coerce.string().uuid(),
        email: zod.coerce.string().email(),
        firstName: zod.coerce
          .string()
          .max(
            timeTrackingControllerPreviewResponseNewRecordsItemUserFirstNameMax
          ),
        lastName: zod.coerce
          .string()
          .max(
            timeTrackingControllerPreviewResponseNewRecordsItemUserLastNameMax
          ),
        dateOfBirth: zod.coerce.date().optional(),
        phoneNumber: zod.coerce.string().optional(),
        status: zod.enum(['Active', 'Terminated']),
        permissions: zod.array(
          zod.enum(['admin', 'human-resource', 'user']).optional()
        ),
        role: zod
          .object({
            uuid: zod.coerce.string().uuid(),
            name: zod.coerce.string(),
          })
          .optional(),
        department: zod
          .object({
            uuid: zod.coerce.string().uuid(),
            name: zod.coerce.string(),
          })
          .optional(),
        createdAt: zod.coerce.string().datetime(),
        updatedAt: zod.coerce.string().datetime(),
        address: zod
          .object({
            geoLocation: zod
              .object({
                x: zod.coerce.number().optional(),
                y: zod.coerce.number().optional(),
              })
              .nullish(),
            lat: zod.coerce
              .number()
              .min(
                timeTrackingControllerPreviewResponseNewRecordsItemUserAddressLatMin
              )
              .max(
                timeTrackingControllerPreviewResponseNewRecordsItemUserAddressLatMax
              )
              .optional(),
            lon: zod.coerce
              .number()
              .min(
                timeTrackingControllerPreviewResponseNewRecordsItemUserAddressLonMin
              )
              .max(
                timeTrackingControllerPreviewResponseNewRecordsItemUserAddressLonMax
              )
              .optional(),
            uuid: zod.coerce.string(),
            country: zod.coerce.string().optional(),
            city: zod.coerce.string().optional(),
            street: zod.coerce.string().optional(),
            postcode: zod.coerce.string().optional(),
          })
          .optional(),
        profilePicture: zod.coerce.string(),
        fullName: zod.coerce.string(),
        leaveTypes: zod.array(
          zod.object({
            uuid: zod.coerce.string().uuid(),
            name: zod.coerce
              .string()
              .min(1)
              .max(
                timeTrackingControllerPreviewResponseNewRecordsItemUserLeaveTypesItemNameMax
              ),
            description: zod.coerce
              .string()
              .max(
                timeTrackingControllerPreviewResponseNewRecordsItemUserLeaveTypesItemDescriptionMax
              )
              .optional(),
            color: zod.coerce
              .string()
              .min(
                timeTrackingControllerPreviewResponseNewRecordsItemUserLeaveTypesItemColorMin
              )
              .max(
                timeTrackingControllerPreviewResponseNewRecordsItemUserLeaveTypesItemColorMax
              )
              .optional(),
            allowedPerYear: zod.object({
              days: zod.coerce.number(),
              hours: zod.coerce.number(),
              minutes: zod.coerce.number(),
              seconds: zod.coerce.number(),
              milliseconds: zod.coerce.number(),
            }),
            isFreebie: zod.coerce.boolean().optional(),
            isWithAttachment: zod.coerce.boolean().optional(),
          })
        ),
        layouts: zod.array(
          zod.object({
            uuid: zod.coerce.string(),
            user: zod.any(),
            layouts: zod.array(
              zod.object({
                i: zod.coerce.string(),
                x: zod.coerce.number(),
                y: zod.coerce.number(),
                w: zod.coerce.number(),
                h: zod.coerce.number(),
                minW: zod.coerce.number().optional(),
                maxW: zod.coerce.number().optional(),
                minH: zod.coerce.number().optional(),
                maxH: zod.coerce.number().optional(),
                static: zod.coerce.boolean().optional(),
                isBounded: zod.coerce.boolean().optional(),
                isDraggable: zod.coerce.boolean().optional(),
                isResizable: zod.coerce.boolean().optional(),
              })
            ),
          })
        ),
      }),
      timeZone: zod.coerce.string(),
    })
  ),
  affectedRecords: zod.array(
    zod.object({
      uuid: zod.coerce.string().uuid(),
      startAt: zod.coerce.string().datetime(),
      endAt: zod.coerce.string().datetime().optional(),
      project: zod
        .object({
          serial: zod.coerce
            .string()
            .max(
              timeTrackingControllerPreviewResponseAffectedRecordsItemProjectSerialMax
            )
            .optional(),
          projectSize: zod.coerce.number(),
          guaranteedUntil: zod.coerce.date().optional(),
          projectType: zod.object({
            name: zod.coerce.string(),
            uuid: zod.coerce.string(),
          }),
          completionPercentage: zod.coerce
            .number()
            .min(
              timeTrackingControllerPreviewResponseAffectedRecordsItemProjectCompletionPercentageMin
            )
            .max(
              timeTrackingControllerPreviewResponseAffectedRecordsItemProjectCompletionPercentageMax
            )
            .optional(),
          startedAt: zod.coerce.date().optional(),
          estimatedEndAt: zod.coerce.date().optional(),
          endAt: zod.coerce.date().optional(),
          status: zod.enum(['Active', 'In Active', 'Potential']).optional(),
          milestones: zod
            .array(
              zod.object({
                uuid: zod.coerce.string().uuid(),
                date: zod.coerce.date(),
                iconUrl: zod.coerce.string().nullable(),
                description: zod.coerce.string().nullable(),
                projectUuid: zod.coerce.string().uuid(),
                createdAt: zod.coerce.string().datetime(),
                updatedAt: zod.coerce.string().datetime(),
                project: zod.any(),
              })
            )
            .optional(),
          roadmaps: zod
            .array(
              zod.object({
                uuid: zod.coerce.string().uuid(),
                startAt: zod.coerce.date(),
                endAt: zod.coerce.date(),
                isVisible: zod.coerce.boolean().optional(),
                project: zod.any(),
                tag: zod.object({
                  uuid: zod.coerce.string().uuid(),
                  parent: zod.any().nullish(),
                  name: zod.coerce
                    .string()
                    .max(
                      timeTrackingControllerPreviewResponseAffectedRecordsItemProjectRoadmapsItemTagNameMax
                    ),
                  companyType: zod
                    .enum(['Client', 'Contractor', 'Other'])
                    .optional(),
                }),
                color: zod.coerce
                  .string()
                  .min(
                    timeTrackingControllerPreviewResponseAffectedRecordsItemProjectRoadmapsItemColorMin
                  )
                  .max(
                    timeTrackingControllerPreviewResponseAffectedRecordsItemProjectRoadmapsItemColorMax
                  )
                  .nullish(),
                items: zod.array(
                  zod.object({
                    uuid: zod.coerce.string().uuid(),
                    color: zod.coerce
                      .string()
                      .min(
                        timeTrackingControllerPreviewResponseAffectedRecordsItemProjectRoadmapsItemItemsItemColorMin
                      )
                      .max(
                        timeTrackingControllerPreviewResponseAffectedRecordsItemProjectRoadmapsItemItemsItemColorMax
                      )
                      .nullish(),
                    startAt: zod.coerce.date(),
                    endAt: zod.coerce.date(),
                    description: zod.coerce.string().nullable(),
                    roadmap: zod.any().optional(),
                  })
                ),
              })
            )
            .optional(),
          projectManagers: zod.array(
            zod.object({
              uuid: zod.coerce.string().uuid(),
              email: zod.coerce.string().email(),
              firstName: zod.coerce
                .string()
                .max(
                  timeTrackingControllerPreviewResponseAffectedRecordsItemProjectProjectManagersItemFirstNameMax
                ),
              lastName: zod.coerce
                .string()
                .max(
                  timeTrackingControllerPreviewResponseAffectedRecordsItemProjectProjectManagersItemLastNameMax
                ),
              dateOfBirth: zod.coerce.date().optional(),
              phoneNumber: zod.coerce.string().optional(),
              status: zod.enum(['Active', 'Terminated']),
              permissions: zod.array(
                zod.enum(['admin', 'human-resource', 'user']).optional()
              ),
              role: zod
                .object({
                  uuid: zod.coerce.string().uuid(),
                  name: zod.coerce.string(),
                })
                .optional(),
              department: zod
                .object({
                  uuid: zod.coerce.string().uuid(),
                  name: zod.coerce.string(),
                })
                .optional(),
              createdAt: zod.coerce.string().datetime(),
              updatedAt: zod.coerce.string().datetime(),
              address: zod
                .object({
                  geoLocation: zod
                    .object({
                      x: zod.coerce.number().optional(),
                      y: zod.coerce.number().optional(),
                    })
                    .nullish(),
                  lat: zod.coerce
                    .number()
                    .min(
                      timeTrackingControllerPreviewResponseAffectedRecordsItemProjectProjectManagersItemAddressLatMin
                    )
                    .max(
                      timeTrackingControllerPreviewResponseAffectedRecordsItemProjectProjectManagersItemAddressLatMax
                    )
                    .optional(),
                  lon: zod.coerce
                    .number()
                    .min(
                      timeTrackingControllerPreviewResponseAffectedRecordsItemProjectProjectManagersItemAddressLonMin
                    )
                    .max(
                      timeTrackingControllerPreviewResponseAffectedRecordsItemProjectProjectManagersItemAddressLonMax
                    )
                    .optional(),
                  uuid: zod.coerce.string(),
                  country: zod.coerce.string().optional(),
                  city: zod.coerce.string().optional(),
                  street: zod.coerce.string().optional(),
                  postcode: zod.coerce.string().optional(),
                })
                .optional(),
              profilePicture: zod.coerce.string(),
              fullName: zod.coerce.string(),
              leaveTypes: zod.array(
                zod.object({
                  uuid: zod.coerce.string().uuid(),
                  name: zod.coerce
                    .string()
                    .min(1)
                    .max(
                      timeTrackingControllerPreviewResponseAffectedRecordsItemProjectProjectManagersItemLeaveTypesItemNameMax
                    ),
                  description: zod.coerce
                    .string()
                    .max(
                      timeTrackingControllerPreviewResponseAffectedRecordsItemProjectProjectManagersItemLeaveTypesItemDescriptionMax
                    )
                    .optional(),
                  color: zod.coerce
                    .string()
                    .min(
                      timeTrackingControllerPreviewResponseAffectedRecordsItemProjectProjectManagersItemLeaveTypesItemColorMin
                    )
                    .max(
                      timeTrackingControllerPreviewResponseAffectedRecordsItemProjectProjectManagersItemLeaveTypesItemColorMax
                    )
                    .optional(),
                  allowedPerYear: zod.object({
                    days: zod.coerce.number(),
                    hours: zod.coerce.number(),
                    minutes: zod.coerce.number(),
                    seconds: zod.coerce.number(),
                    milliseconds: zod.coerce.number(),
                  }),
                  isFreebie: zod.coerce.boolean().optional(),
                  isWithAttachment: zod.coerce.boolean().optional(),
                })
              ),
              layouts: zod.array(
                zod.object({
                  uuid: zod.coerce.string(),
                  user: zod.any(),
                  layouts: zod.array(
                    zod.object({
                      i: zod.coerce.string(),
                      x: zod.coerce.number(),
                      y: zod.coerce.number(),
                      w: zod.coerce.number(),
                      h: zod.coerce.number(),
                      minW: zod.coerce.number().optional(),
                      maxW: zod.coerce.number().optional(),
                      minH: zod.coerce.number().optional(),
                      maxH: zod.coerce.number().optional(),
                      static: zod.coerce.boolean().optional(),
                      isBounded: zod.coerce.boolean().optional(),
                      isDraggable: zod.coerce.boolean().optional(),
                      isResizable: zod.coerce.boolean().optional(),
                    })
                  ),
                })
              ),
            })
          ),
          totalAllocations: zod.coerce
            .number()
            .min(
              timeTrackingControllerPreviewResponseAffectedRecordsItemProjectTotalAllocationsMin
            ),
          uuid: zod.coerce.string(),
          name: zod.coerce.string(),
          address: zod.object({
            geoLocation: zod
              .object({
                x: zod.coerce.number().optional(),
                y: zod.coerce.number().optional(),
              })
              .nullish(),
            lat: zod.coerce
              .number()
              .min(
                timeTrackingControllerPreviewResponseAffectedRecordsItemProjectAddressLatMin
              )
              .max(
                timeTrackingControllerPreviewResponseAffectedRecordsItemProjectAddressLatMax
              )
              .optional(),
            lon: zod.coerce
              .number()
              .min(
                timeTrackingControllerPreviewResponseAffectedRecordsItemProjectAddressLonMin
              )
              .max(
                timeTrackingControllerPreviewResponseAffectedRecordsItemProjectAddressLonMax
              )
              .optional(),
            uuid: zod.coerce.string(),
            country: zod.coerce.string().optional(),
            city: zod.coerce.string().optional(),
            street: zod.coerce.string().optional(),
            postcode: zod.coerce.string().optional(),
          }),
          createdAt: zod.coerce.string().datetime(),
          updatedAt: zod.coerce.string().datetime(),
        })
        .optional(),
      type: zod
        .enum(['home_work', 'office_work', 'site_work', 'transit'])
        .optional(),
      isClockOut: zod.coerce.boolean().optional(),
      status: zod.enum(['approved', 'pending', 'draft']),
      timeType: zod.enum(['work', 'break', 'time-off']).optional(),
      deletedAt: zod.coerce.string().datetime(),
      createdAt: zod.coerce.string().datetime(),
      updatedAt: zod.coerce.string().datetime(),
      duration: zod.coerce.number(),
      user: zod.object({
        uuid: zod.coerce.string().uuid(),
        email: zod.coerce.string().email(),
        firstName: zod.coerce
          .string()
          .max(
            timeTrackingControllerPreviewResponseAffectedRecordsItemUserFirstNameMax
          ),
        lastName: zod.coerce
          .string()
          .max(
            timeTrackingControllerPreviewResponseAffectedRecordsItemUserLastNameMax
          ),
        dateOfBirth: zod.coerce.date().optional(),
        phoneNumber: zod.coerce.string().optional(),
        status: zod.enum(['Active', 'Terminated']),
        permissions: zod.array(
          zod.enum(['admin', 'human-resource', 'user']).optional()
        ),
        role: zod
          .object({
            uuid: zod.coerce.string().uuid(),
            name: zod.coerce.string(),
          })
          .optional(),
        department: zod
          .object({
            uuid: zod.coerce.string().uuid(),
            name: zod.coerce.string(),
          })
          .optional(),
        createdAt: zod.coerce.string().datetime(),
        updatedAt: zod.coerce.string().datetime(),
        address: zod
          .object({
            geoLocation: zod
              .object({
                x: zod.coerce.number().optional(),
                y: zod.coerce.number().optional(),
              })
              .nullish(),
            lat: zod.coerce
              .number()
              .min(
                timeTrackingControllerPreviewResponseAffectedRecordsItemUserAddressLatMin
              )
              .max(
                timeTrackingControllerPreviewResponseAffectedRecordsItemUserAddressLatMax
              )
              .optional(),
            lon: zod.coerce
              .number()
              .min(
                timeTrackingControllerPreviewResponseAffectedRecordsItemUserAddressLonMin
              )
              .max(
                timeTrackingControllerPreviewResponseAffectedRecordsItemUserAddressLonMax
              )
              .optional(),
            uuid: zod.coerce.string(),
            country: zod.coerce.string().optional(),
            city: zod.coerce.string().optional(),
            street: zod.coerce.string().optional(),
            postcode: zod.coerce.string().optional(),
          })
          .optional(),
        profilePicture: zod.coerce.string(),
        fullName: zod.coerce.string(),
        leaveTypes: zod.array(
          zod.object({
            uuid: zod.coerce.string().uuid(),
            name: zod.coerce
              .string()
              .min(1)
              .max(
                timeTrackingControllerPreviewResponseAffectedRecordsItemUserLeaveTypesItemNameMax
              ),
            description: zod.coerce
              .string()
              .max(
                timeTrackingControllerPreviewResponseAffectedRecordsItemUserLeaveTypesItemDescriptionMax
              )
              .optional(),
            color: zod.coerce
              .string()
              .min(
                timeTrackingControllerPreviewResponseAffectedRecordsItemUserLeaveTypesItemColorMin
              )
              .max(
                timeTrackingControllerPreviewResponseAffectedRecordsItemUserLeaveTypesItemColorMax
              )
              .optional(),
            allowedPerYear: zod.object({
              days: zod.coerce.number(),
              hours: zod.coerce.number(),
              minutes: zod.coerce.number(),
              seconds: zod.coerce.number(),
              milliseconds: zod.coerce.number(),
            }),
            isFreebie: zod.coerce.boolean().optional(),
            isWithAttachment: zod.coerce.boolean().optional(),
          })
        ),
        layouts: zod.array(
          zod.object({
            uuid: zod.coerce.string(),
            user: zod.any(),
            layouts: zod.array(
              zod.object({
                i: zod.coerce.string(),
                x: zod.coerce.number(),
                y: zod.coerce.number(),
                w: zod.coerce.number(),
                h: zod.coerce.number(),
                minW: zod.coerce.number().optional(),
                maxW: zod.coerce.number().optional(),
                minH: zod.coerce.number().optional(),
                maxH: zod.coerce.number().optional(),
                static: zod.coerce.boolean().optional(),
                isBounded: zod.coerce.boolean().optional(),
                isDraggable: zod.coerce.boolean().optional(),
                isResizable: zod.coerce.boolean().optional(),
              })
            ),
          })
        ),
      }),
      timeZone: zod.coerce.string(),
    })
  ),
  recordsToRemove: zod.array(
    zod.object({
      uuid: zod.coerce.string().uuid(),
      startAt: zod.coerce.string().datetime(),
      endAt: zod.coerce.string().datetime().optional(),
      project: zod
        .object({
          serial: zod.coerce
            .string()
            .max(
              timeTrackingControllerPreviewResponseRecordsToRemoveItemProjectSerialMax
            )
            .optional(),
          projectSize: zod.coerce.number(),
          guaranteedUntil: zod.coerce.date().optional(),
          projectType: zod.object({
            name: zod.coerce.string(),
            uuid: zod.coerce.string(),
          }),
          completionPercentage: zod.coerce
            .number()
            .min(
              timeTrackingControllerPreviewResponseRecordsToRemoveItemProjectCompletionPercentageMin
            )
            .max(
              timeTrackingControllerPreviewResponseRecordsToRemoveItemProjectCompletionPercentageMax
            )
            .optional(),
          startedAt: zod.coerce.date().optional(),
          estimatedEndAt: zod.coerce.date().optional(),
          endAt: zod.coerce.date().optional(),
          status: zod.enum(['Active', 'In Active', 'Potential']).optional(),
          milestones: zod
            .array(
              zod.object({
                uuid: zod.coerce.string().uuid(),
                date: zod.coerce.date(),
                iconUrl: zod.coerce.string().nullable(),
                description: zod.coerce.string().nullable(),
                projectUuid: zod.coerce.string().uuid(),
                createdAt: zod.coerce.string().datetime(),
                updatedAt: zod.coerce.string().datetime(),
                project: zod.any(),
              })
            )
            .optional(),
          roadmaps: zod
            .array(
              zod.object({
                uuid: zod.coerce.string().uuid(),
                startAt: zod.coerce.date(),
                endAt: zod.coerce.date(),
                isVisible: zod.coerce.boolean().optional(),
                project: zod.any(),
                tag: zod.object({
                  uuid: zod.coerce.string().uuid(),
                  parent: zod.any().nullish(),
                  name: zod.coerce
                    .string()
                    .max(
                      timeTrackingControllerPreviewResponseRecordsToRemoveItemProjectRoadmapsItemTagNameMax
                    ),
                  companyType: zod
                    .enum(['Client', 'Contractor', 'Other'])
                    .optional(),
                }),
                color: zod.coerce
                  .string()
                  .min(
                    timeTrackingControllerPreviewResponseRecordsToRemoveItemProjectRoadmapsItemColorMin
                  )
                  .max(
                    timeTrackingControllerPreviewResponseRecordsToRemoveItemProjectRoadmapsItemColorMax
                  )
                  .nullish(),
                items: zod.array(
                  zod.object({
                    uuid: zod.coerce.string().uuid(),
                    color: zod.coerce
                      .string()
                      .min(
                        timeTrackingControllerPreviewResponseRecordsToRemoveItemProjectRoadmapsItemItemsItemColorMin
                      )
                      .max(
                        timeTrackingControllerPreviewResponseRecordsToRemoveItemProjectRoadmapsItemItemsItemColorMax
                      )
                      .nullish(),
                    startAt: zod.coerce.date(),
                    endAt: zod.coerce.date(),
                    description: zod.coerce.string().nullable(),
                    roadmap: zod.any().optional(),
                  })
                ),
              })
            )
            .optional(),
          projectManagers: zod.array(
            zod.object({
              uuid: zod.coerce.string().uuid(),
              email: zod.coerce.string().email(),
              firstName: zod.coerce
                .string()
                .max(
                  timeTrackingControllerPreviewResponseRecordsToRemoveItemProjectProjectManagersItemFirstNameMax
                ),
              lastName: zod.coerce
                .string()
                .max(
                  timeTrackingControllerPreviewResponseRecordsToRemoveItemProjectProjectManagersItemLastNameMax
                ),
              dateOfBirth: zod.coerce.date().optional(),
              phoneNumber: zod.coerce.string().optional(),
              status: zod.enum(['Active', 'Terminated']),
              permissions: zod.array(
                zod.enum(['admin', 'human-resource', 'user']).optional()
              ),
              role: zod
                .object({
                  uuid: zod.coerce.string().uuid(),
                  name: zod.coerce.string(),
                })
                .optional(),
              department: zod
                .object({
                  uuid: zod.coerce.string().uuid(),
                  name: zod.coerce.string(),
                })
                .optional(),
              createdAt: zod.coerce.string().datetime(),
              updatedAt: zod.coerce.string().datetime(),
              address: zod
                .object({
                  geoLocation: zod
                    .object({
                      x: zod.coerce.number().optional(),
                      y: zod.coerce.number().optional(),
                    })
                    .nullish(),
                  lat: zod.coerce
                    .number()
                    .min(
                      timeTrackingControllerPreviewResponseRecordsToRemoveItemProjectProjectManagersItemAddressLatMin
                    )
                    .max(
                      timeTrackingControllerPreviewResponseRecordsToRemoveItemProjectProjectManagersItemAddressLatMax
                    )
                    .optional(),
                  lon: zod.coerce
                    .number()
                    .min(
                      timeTrackingControllerPreviewResponseRecordsToRemoveItemProjectProjectManagersItemAddressLonMin
                    )
                    .max(
                      timeTrackingControllerPreviewResponseRecordsToRemoveItemProjectProjectManagersItemAddressLonMax
                    )
                    .optional(),
                  uuid: zod.coerce.string(),
                  country: zod.coerce.string().optional(),
                  city: zod.coerce.string().optional(),
                  street: zod.coerce.string().optional(),
                  postcode: zod.coerce.string().optional(),
                })
                .optional(),
              profilePicture: zod.coerce.string(),
              fullName: zod.coerce.string(),
              leaveTypes: zod.array(
                zod.object({
                  uuid: zod.coerce.string().uuid(),
                  name: zod.coerce
                    .string()
                    .min(1)
                    .max(
                      timeTrackingControllerPreviewResponseRecordsToRemoveItemProjectProjectManagersItemLeaveTypesItemNameMax
                    ),
                  description: zod.coerce
                    .string()
                    .max(
                      timeTrackingControllerPreviewResponseRecordsToRemoveItemProjectProjectManagersItemLeaveTypesItemDescriptionMax
                    )
                    .optional(),
                  color: zod.coerce
                    .string()
                    .min(
                      timeTrackingControllerPreviewResponseRecordsToRemoveItemProjectProjectManagersItemLeaveTypesItemColorMin
                    )
                    .max(
                      timeTrackingControllerPreviewResponseRecordsToRemoveItemProjectProjectManagersItemLeaveTypesItemColorMax
                    )
                    .optional(),
                  allowedPerYear: zod.object({
                    days: zod.coerce.number(),
                    hours: zod.coerce.number(),
                    minutes: zod.coerce.number(),
                    seconds: zod.coerce.number(),
                    milliseconds: zod.coerce.number(),
                  }),
                  isFreebie: zod.coerce.boolean().optional(),
                  isWithAttachment: zod.coerce.boolean().optional(),
                })
              ),
              layouts: zod.array(
                zod.object({
                  uuid: zod.coerce.string(),
                  user: zod.any(),
                  layouts: zod.array(
                    zod.object({
                      i: zod.coerce.string(),
                      x: zod.coerce.number(),
                      y: zod.coerce.number(),
                      w: zod.coerce.number(),
                      h: zod.coerce.number(),
                      minW: zod.coerce.number().optional(),
                      maxW: zod.coerce.number().optional(),
                      minH: zod.coerce.number().optional(),
                      maxH: zod.coerce.number().optional(),
                      static: zod.coerce.boolean().optional(),
                      isBounded: zod.coerce.boolean().optional(),
                      isDraggable: zod.coerce.boolean().optional(),
                      isResizable: zod.coerce.boolean().optional(),
                    })
                  ),
                })
              ),
            })
          ),
          totalAllocations: zod.coerce
            .number()
            .min(
              timeTrackingControllerPreviewResponseRecordsToRemoveItemProjectTotalAllocationsMin
            ),
          uuid: zod.coerce.string(),
          name: zod.coerce.string(),
          address: zod.object({
            geoLocation: zod
              .object({
                x: zod.coerce.number().optional(),
                y: zod.coerce.number().optional(),
              })
              .nullish(),
            lat: zod.coerce
              .number()
              .min(
                timeTrackingControllerPreviewResponseRecordsToRemoveItemProjectAddressLatMin
              )
              .max(
                timeTrackingControllerPreviewResponseRecordsToRemoveItemProjectAddressLatMax
              )
              .optional(),
            lon: zod.coerce
              .number()
              .min(
                timeTrackingControllerPreviewResponseRecordsToRemoveItemProjectAddressLonMin
              )
              .max(
                timeTrackingControllerPreviewResponseRecordsToRemoveItemProjectAddressLonMax
              )
              .optional(),
            uuid: zod.coerce.string(),
            country: zod.coerce.string().optional(),
            city: zod.coerce.string().optional(),
            street: zod.coerce.string().optional(),
            postcode: zod.coerce.string().optional(),
          }),
          createdAt: zod.coerce.string().datetime(),
          updatedAt: zod.coerce.string().datetime(),
        })
        .optional(),
      type: zod
        .enum(['home_work', 'office_work', 'site_work', 'transit'])
        .optional(),
      isClockOut: zod.coerce.boolean().optional(),
      status: zod.enum(['approved', 'pending', 'draft']),
      timeType: zod.enum(['work', 'break', 'time-off']).optional(),
      deletedAt: zod.coerce.string().datetime(),
      createdAt: zod.coerce.string().datetime(),
      updatedAt: zod.coerce.string().datetime(),
      duration: zod.coerce.number(),
      user: zod.object({
        uuid: zod.coerce.string().uuid(),
        email: zod.coerce.string().email(),
        firstName: zod.coerce
          .string()
          .max(
            timeTrackingControllerPreviewResponseRecordsToRemoveItemUserFirstNameMax
          ),
        lastName: zod.coerce
          .string()
          .max(
            timeTrackingControllerPreviewResponseRecordsToRemoveItemUserLastNameMax
          ),
        dateOfBirth: zod.coerce.date().optional(),
        phoneNumber: zod.coerce.string().optional(),
        status: zod.enum(['Active', 'Terminated']),
        permissions: zod.array(
          zod.enum(['admin', 'human-resource', 'user']).optional()
        ),
        role: zod
          .object({
            uuid: zod.coerce.string().uuid(),
            name: zod.coerce.string(),
          })
          .optional(),
        department: zod
          .object({
            uuid: zod.coerce.string().uuid(),
            name: zod.coerce.string(),
          })
          .optional(),
        createdAt: zod.coerce.string().datetime(),
        updatedAt: zod.coerce.string().datetime(),
        address: zod
          .object({
            geoLocation: zod
              .object({
                x: zod.coerce.number().optional(),
                y: zod.coerce.number().optional(),
              })
              .nullish(),
            lat: zod.coerce
              .number()
              .min(
                timeTrackingControllerPreviewResponseRecordsToRemoveItemUserAddressLatMin
              )
              .max(
                timeTrackingControllerPreviewResponseRecordsToRemoveItemUserAddressLatMax
              )
              .optional(),
            lon: zod.coerce
              .number()
              .min(
                timeTrackingControllerPreviewResponseRecordsToRemoveItemUserAddressLonMin
              )
              .max(
                timeTrackingControllerPreviewResponseRecordsToRemoveItemUserAddressLonMax
              )
              .optional(),
            uuid: zod.coerce.string(),
            country: zod.coerce.string().optional(),
            city: zod.coerce.string().optional(),
            street: zod.coerce.string().optional(),
            postcode: zod.coerce.string().optional(),
          })
          .optional(),
        profilePicture: zod.coerce.string(),
        fullName: zod.coerce.string(),
        leaveTypes: zod.array(
          zod.object({
            uuid: zod.coerce.string().uuid(),
            name: zod.coerce
              .string()
              .min(1)
              .max(
                timeTrackingControllerPreviewResponseRecordsToRemoveItemUserLeaveTypesItemNameMax
              ),
            description: zod.coerce
              .string()
              .max(
                timeTrackingControllerPreviewResponseRecordsToRemoveItemUserLeaveTypesItemDescriptionMax
              )
              .optional(),
            color: zod.coerce
              .string()
              .min(
                timeTrackingControllerPreviewResponseRecordsToRemoveItemUserLeaveTypesItemColorMin
              )
              .max(
                timeTrackingControllerPreviewResponseRecordsToRemoveItemUserLeaveTypesItemColorMax
              )
              .optional(),
            allowedPerYear: zod.object({
              days: zod.coerce.number(),
              hours: zod.coerce.number(),
              minutes: zod.coerce.number(),
              seconds: zod.coerce.number(),
              milliseconds: zod.coerce.number(),
            }),
            isFreebie: zod.coerce.boolean().optional(),
            isWithAttachment: zod.coerce.boolean().optional(),
          })
        ),
        layouts: zod.array(
          zod.object({
            uuid: zod.coerce.string(),
            user: zod.any(),
            layouts: zod.array(
              zod.object({
                i: zod.coerce.string(),
                x: zod.coerce.number(),
                y: zod.coerce.number(),
                w: zod.coerce.number(),
                h: zod.coerce.number(),
                minW: zod.coerce.number().optional(),
                maxW: zod.coerce.number().optional(),
                minH: zod.coerce.number().optional(),
                maxH: zod.coerce.number().optional(),
                static: zod.coerce.boolean().optional(),
                isBounded: zod.coerce.boolean().optional(),
                isDraggable: zod.coerce.boolean().optional(),
                isResizable: zod.coerce.boolean().optional(),
              })
            ),
          })
        ),
      }),
      timeZone: zod.coerce.string(),
    })
  ),
  hasConflict: zod.coerce.boolean().optional(),
  totalAffected: zod.coerce
    .number()
    .min(timeTrackingControllerPreviewResponseTotalAffectedMin),
  totalRemoved: zod.coerce
    .number()
    .min(timeTrackingControllerPreviewResponseTotalRemovedMin),
  totalNewRecords: zod.coerce
    .number()
    .min(timeTrackingControllerPreviewResponseTotalNewRecordsMin),
})

/**
 * @summary Selected time tracking information
 */
export const timeTrackingControllerFindParams = zod.object({
  id: zod.coerce.string().uuid(),
})

export const timeTrackingControllerFindHeader = zod.object({
  'x-time-zone': zod.coerce.string(),
})

export const timeTrackingControllerFindResponseProjectSerialMax = 50
export const timeTrackingControllerFindResponseProjectCompletionPercentageMin = 0

export const timeTrackingControllerFindResponseProjectCompletionPercentageMax = 100
export const timeTrackingControllerFindResponseProjectRoadmapsItemTagNameMax = 50
export const timeTrackingControllerFindResponseProjectRoadmapsItemColorMin = 7

export const timeTrackingControllerFindResponseProjectRoadmapsItemColorMax = 7
export const timeTrackingControllerFindResponseProjectRoadmapsItemItemsItemColorMin = 7

export const timeTrackingControllerFindResponseProjectRoadmapsItemItemsItemColorMax = 7
export const timeTrackingControllerFindResponseProjectProjectManagersItemFirstNameMax = 100
export const timeTrackingControllerFindResponseProjectProjectManagersItemLastNameMax = 100
export const timeTrackingControllerFindResponseProjectProjectManagersItemAddressLatMin =
  -90

export const timeTrackingControllerFindResponseProjectProjectManagersItemAddressLatMax = 90
export const timeTrackingControllerFindResponseProjectProjectManagersItemAddressLonMin =
  -180

export const timeTrackingControllerFindResponseProjectProjectManagersItemAddressLonMax = 180
export const timeTrackingControllerFindResponseProjectProjectManagersItemLeaveTypesItemNameMax = 255
export const timeTrackingControllerFindResponseProjectProjectManagersItemLeaveTypesItemDescriptionMax = 1024
export const timeTrackingControllerFindResponseProjectProjectManagersItemLeaveTypesItemColorMin = 7

export const timeTrackingControllerFindResponseProjectProjectManagersItemLeaveTypesItemColorMax = 7
export const timeTrackingControllerFindResponseProjectTotalAllocationsMin = 0
export const timeTrackingControllerFindResponseProjectAddressLatMin = -90

export const timeTrackingControllerFindResponseProjectAddressLatMax = 90
export const timeTrackingControllerFindResponseProjectAddressLonMin = -180

export const timeTrackingControllerFindResponseProjectAddressLonMax = 180
export const timeTrackingControllerFindResponseUserFirstNameMax = 100
export const timeTrackingControllerFindResponseUserLastNameMax = 100
export const timeTrackingControllerFindResponseUserAddressLatMin = -90

export const timeTrackingControllerFindResponseUserAddressLatMax = 90
export const timeTrackingControllerFindResponseUserAddressLonMin = -180

export const timeTrackingControllerFindResponseUserAddressLonMax = 180
export const timeTrackingControllerFindResponseUserLeaveTypesItemNameMax = 255
export const timeTrackingControllerFindResponseUserLeaveTypesItemDescriptionMax = 1024
export const timeTrackingControllerFindResponseUserLeaveTypesItemColorMin = 7

export const timeTrackingControllerFindResponseUserLeaveTypesItemColorMax = 7

export const timeTrackingControllerFindResponse = zod.object({
  uuid: zod.coerce.string().uuid(),
  startAt: zod.coerce.string().datetime(),
  endAt: zod.coerce.string().datetime().optional(),
  project: zod
    .object({
      serial: zod.coerce
        .string()
        .max(timeTrackingControllerFindResponseProjectSerialMax)
        .optional(),
      projectSize: zod.coerce.number(),
      guaranteedUntil: zod.coerce.date().optional(),
      projectType: zod.object({
        name: zod.coerce.string(),
        uuid: zod.coerce.string(),
      }),
      completionPercentage: zod.coerce
        .number()
        .min(timeTrackingControllerFindResponseProjectCompletionPercentageMin)
        .max(timeTrackingControllerFindResponseProjectCompletionPercentageMax)
        .optional(),
      startedAt: zod.coerce.date().optional(),
      estimatedEndAt: zod.coerce.date().optional(),
      endAt: zod.coerce.date().optional(),
      status: zod.enum(['Active', 'In Active', 'Potential']).optional(),
      milestones: zod
        .array(
          zod.object({
            uuid: zod.coerce.string().uuid(),
            date: zod.coerce.date(),
            iconUrl: zod.coerce.string().nullable(),
            description: zod.coerce.string().nullable(),
            projectUuid: zod.coerce.string().uuid(),
            createdAt: zod.coerce.string().datetime(),
            updatedAt: zod.coerce.string().datetime(),
            project: zod.any(),
          })
        )
        .optional(),
      roadmaps: zod
        .array(
          zod.object({
            uuid: zod.coerce.string().uuid(),
            startAt: zod.coerce.date(),
            endAt: zod.coerce.date(),
            isVisible: zod.coerce.boolean().optional(),
            project: zod.any(),
            tag: zod.object({
              uuid: zod.coerce.string().uuid(),
              parent: zod.any().nullish(),
              name: zod.coerce
                .string()
                .max(
                  timeTrackingControllerFindResponseProjectRoadmapsItemTagNameMax
                ),
              companyType: zod
                .enum(['Client', 'Contractor', 'Other'])
                .optional(),
            }),
            color: zod.coerce
              .string()
              .min(
                timeTrackingControllerFindResponseProjectRoadmapsItemColorMin
              )
              .max(
                timeTrackingControllerFindResponseProjectRoadmapsItemColorMax
              )
              .nullish(),
            items: zod.array(
              zod.object({
                uuid: zod.coerce.string().uuid(),
                color: zod.coerce
                  .string()
                  .min(
                    timeTrackingControllerFindResponseProjectRoadmapsItemItemsItemColorMin
                  )
                  .max(
                    timeTrackingControllerFindResponseProjectRoadmapsItemItemsItemColorMax
                  )
                  .nullish(),
                startAt: zod.coerce.date(),
                endAt: zod.coerce.date(),
                description: zod.coerce.string().nullable(),
                roadmap: zod.any().optional(),
              })
            ),
          })
        )
        .optional(),
      projectManagers: zod.array(
        zod.object({
          uuid: zod.coerce.string().uuid(),
          email: zod.coerce.string().email(),
          firstName: zod.coerce
            .string()
            .max(
              timeTrackingControllerFindResponseProjectProjectManagersItemFirstNameMax
            ),
          lastName: zod.coerce
            .string()
            .max(
              timeTrackingControllerFindResponseProjectProjectManagersItemLastNameMax
            ),
          dateOfBirth: zod.coerce.date().optional(),
          phoneNumber: zod.coerce.string().optional(),
          status: zod.enum(['Active', 'Terminated']),
          permissions: zod.array(
            zod.enum(['admin', 'human-resource', 'user']).optional()
          ),
          role: zod
            .object({
              uuid: zod.coerce.string().uuid(),
              name: zod.coerce.string(),
            })
            .optional(),
          department: zod
            .object({
              uuid: zod.coerce.string().uuid(),
              name: zod.coerce.string(),
            })
            .optional(),
          createdAt: zod.coerce.string().datetime(),
          updatedAt: zod.coerce.string().datetime(),
          address: zod
            .object({
              geoLocation: zod
                .object({
                  x: zod.coerce.number().optional(),
                  y: zod.coerce.number().optional(),
                })
                .nullish(),
              lat: zod.coerce
                .number()
                .min(
                  timeTrackingControllerFindResponseProjectProjectManagersItemAddressLatMin
                )
                .max(
                  timeTrackingControllerFindResponseProjectProjectManagersItemAddressLatMax
                )
                .optional(),
              lon: zod.coerce
                .number()
                .min(
                  timeTrackingControllerFindResponseProjectProjectManagersItemAddressLonMin
                )
                .max(
                  timeTrackingControllerFindResponseProjectProjectManagersItemAddressLonMax
                )
                .optional(),
              uuid: zod.coerce.string(),
              country: zod.coerce.string().optional(),
              city: zod.coerce.string().optional(),
              street: zod.coerce.string().optional(),
              postcode: zod.coerce.string().optional(),
            })
            .optional(),
          profilePicture: zod.coerce.string(),
          fullName: zod.coerce.string(),
          leaveTypes: zod.array(
            zod.object({
              uuid: zod.coerce.string().uuid(),
              name: zod.coerce
                .string()
                .min(1)
                .max(
                  timeTrackingControllerFindResponseProjectProjectManagersItemLeaveTypesItemNameMax
                ),
              description: zod.coerce
                .string()
                .max(
                  timeTrackingControllerFindResponseProjectProjectManagersItemLeaveTypesItemDescriptionMax
                )
                .optional(),
              color: zod.coerce
                .string()
                .min(
                  timeTrackingControllerFindResponseProjectProjectManagersItemLeaveTypesItemColorMin
                )
                .max(
                  timeTrackingControllerFindResponseProjectProjectManagersItemLeaveTypesItemColorMax
                )
                .optional(),
              allowedPerYear: zod.object({
                days: zod.coerce.number(),
                hours: zod.coerce.number(),
                minutes: zod.coerce.number(),
                seconds: zod.coerce.number(),
                milliseconds: zod.coerce.number(),
              }),
              isFreebie: zod.coerce.boolean().optional(),
              isWithAttachment: zod.coerce.boolean().optional(),
            })
          ),
          layouts: zod.array(
            zod.object({
              uuid: zod.coerce.string(),
              user: zod.any(),
              layouts: zod.array(
                zod.object({
                  i: zod.coerce.string(),
                  x: zod.coerce.number(),
                  y: zod.coerce.number(),
                  w: zod.coerce.number(),
                  h: zod.coerce.number(),
                  minW: zod.coerce.number().optional(),
                  maxW: zod.coerce.number().optional(),
                  minH: zod.coerce.number().optional(),
                  maxH: zod.coerce.number().optional(),
                  static: zod.coerce.boolean().optional(),
                  isBounded: zod.coerce.boolean().optional(),
                  isDraggable: zod.coerce.boolean().optional(),
                  isResizable: zod.coerce.boolean().optional(),
                })
              ),
            })
          ),
        })
      ),
      totalAllocations: zod.coerce
        .number()
        .min(timeTrackingControllerFindResponseProjectTotalAllocationsMin),
      uuid: zod.coerce.string(),
      name: zod.coerce.string(),
      address: zod.object({
        geoLocation: zod
          .object({
            x: zod.coerce.number().optional(),
            y: zod.coerce.number().optional(),
          })
          .nullish(),
        lat: zod.coerce
          .number()
          .min(timeTrackingControllerFindResponseProjectAddressLatMin)
          .max(timeTrackingControllerFindResponseProjectAddressLatMax)
          .optional(),
        lon: zod.coerce
          .number()
          .min(timeTrackingControllerFindResponseProjectAddressLonMin)
          .max(timeTrackingControllerFindResponseProjectAddressLonMax)
          .optional(),
        uuid: zod.coerce.string(),
        country: zod.coerce.string().optional(),
        city: zod.coerce.string().optional(),
        street: zod.coerce.string().optional(),
        postcode: zod.coerce.string().optional(),
      }),
      createdAt: zod.coerce.string().datetime(),
      updatedAt: zod.coerce.string().datetime(),
    })
    .optional(),
  type: zod
    .enum(['home_work', 'office_work', 'site_work', 'transit'])
    .optional(),
  isClockOut: zod.coerce.boolean().optional(),
  status: zod.enum(['approved', 'pending', 'draft']),
  timeType: zod.enum(['work', 'break', 'time-off']).optional(),
  deletedAt: zod.coerce.string().datetime(),
  createdAt: zod.coerce.string().datetime(),
  updatedAt: zod.coerce.string().datetime(),
  duration: zod.coerce.number(),
  user: zod.object({
    uuid: zod.coerce.string().uuid(),
    email: zod.coerce.string().email(),
    firstName: zod.coerce
      .string()
      .max(timeTrackingControllerFindResponseUserFirstNameMax),
    lastName: zod.coerce
      .string()
      .max(timeTrackingControllerFindResponseUserLastNameMax),
    dateOfBirth: zod.coerce.date().optional(),
    phoneNumber: zod.coerce.string().optional(),
    status: zod.enum(['Active', 'Terminated']),
    permissions: zod.array(
      zod.enum(['admin', 'human-resource', 'user']).optional()
    ),
    role: zod
      .object({
        uuid: zod.coerce.string().uuid(),
        name: zod.coerce.string(),
      })
      .optional(),
    department: zod
      .object({
        uuid: zod.coerce.string().uuid(),
        name: zod.coerce.string(),
      })
      .optional(),
    createdAt: zod.coerce.string().datetime(),
    updatedAt: zod.coerce.string().datetime(),
    address: zod
      .object({
        geoLocation: zod
          .object({
            x: zod.coerce.number().optional(),
            y: zod.coerce.number().optional(),
          })
          .nullish(),
        lat: zod.coerce
          .number()
          .min(timeTrackingControllerFindResponseUserAddressLatMin)
          .max(timeTrackingControllerFindResponseUserAddressLatMax)
          .optional(),
        lon: zod.coerce
          .number()
          .min(timeTrackingControllerFindResponseUserAddressLonMin)
          .max(timeTrackingControllerFindResponseUserAddressLonMax)
          .optional(),
        uuid: zod.coerce.string(),
        country: zod.coerce.string().optional(),
        city: zod.coerce.string().optional(),
        street: zod.coerce.string().optional(),
        postcode: zod.coerce.string().optional(),
      })
      .optional(),
    profilePicture: zod.coerce.string(),
    fullName: zod.coerce.string(),
    leaveTypes: zod.array(
      zod.object({
        uuid: zod.coerce.string().uuid(),
        name: zod.coerce
          .string()
          .min(1)
          .max(timeTrackingControllerFindResponseUserLeaveTypesItemNameMax),
        description: zod.coerce
          .string()
          .max(
            timeTrackingControllerFindResponseUserLeaveTypesItemDescriptionMax
          )
          .optional(),
        color: zod.coerce
          .string()
          .min(timeTrackingControllerFindResponseUserLeaveTypesItemColorMin)
          .max(timeTrackingControllerFindResponseUserLeaveTypesItemColorMax)
          .optional(),
        allowedPerYear: zod.object({
          days: zod.coerce.number(),
          hours: zod.coerce.number(),
          minutes: zod.coerce.number(),
          seconds: zod.coerce.number(),
          milliseconds: zod.coerce.number(),
        }),
        isFreebie: zod.coerce.boolean().optional(),
        isWithAttachment: zod.coerce.boolean().optional(),
      })
    ),
    layouts: zod.array(
      zod.object({
        uuid: zod.coerce.string(),
        user: zod.any(),
        layouts: zod.array(
          zod.object({
            i: zod.coerce.string(),
            x: zod.coerce.number(),
            y: zod.coerce.number(),
            w: zod.coerce.number(),
            h: zod.coerce.number(),
            minW: zod.coerce.number().optional(),
            maxW: zod.coerce.number().optional(),
            minH: zod.coerce.number().optional(),
            maxH: zod.coerce.number().optional(),
            static: zod.coerce.boolean().optional(),
            isBounded: zod.coerce.boolean().optional(),
            isDraggable: zod.coerce.boolean().optional(),
            isResizable: zod.coerce.boolean().optional(),
          })
        ),
      })
    ),
  }),
  timeZone: zod.coerce.string(),
})

/**
 * @summary Update time tracking record
 */
export const timeTrackingControllerUpdateParams = zod.object({
  id: zod.coerce.string().uuid(),
})

export const timeTrackingControllerUpdateHeader = zod.object({
  'x-time-zone': zod.coerce.string(),
})

export const timeTrackingControllerUpdateBody = zod.object({
  type: zod
    .enum(['home_work', 'office_work', 'site_work', 'transit'])
    .optional(),
  project: zod
    .object({
      uuid: zod.coerce.string().uuid(),
    })
    .nullish(),
  startAt: zod.coerce.string().datetime(),
  endAt: zod.coerce.string().datetime(),
  timeType: zod.enum(['work', 'break', 'time-off']).optional(),
  isClockOut: zod.coerce.boolean().optional(),
})

export const timeTrackingControllerUpdateResponseProjectSerialMax = 50
export const timeTrackingControllerUpdateResponseProjectCompletionPercentageMin = 0

export const timeTrackingControllerUpdateResponseProjectCompletionPercentageMax = 100
export const timeTrackingControllerUpdateResponseProjectRoadmapsItemTagNameMax = 50
export const timeTrackingControllerUpdateResponseProjectRoadmapsItemColorMin = 7

export const timeTrackingControllerUpdateResponseProjectRoadmapsItemColorMax = 7
export const timeTrackingControllerUpdateResponseProjectRoadmapsItemItemsItemColorMin = 7

export const timeTrackingControllerUpdateResponseProjectRoadmapsItemItemsItemColorMax = 7
export const timeTrackingControllerUpdateResponseProjectProjectManagersItemFirstNameMax = 100
export const timeTrackingControllerUpdateResponseProjectProjectManagersItemLastNameMax = 100
export const timeTrackingControllerUpdateResponseProjectProjectManagersItemAddressLatMin =
  -90

export const timeTrackingControllerUpdateResponseProjectProjectManagersItemAddressLatMax = 90
export const timeTrackingControllerUpdateResponseProjectProjectManagersItemAddressLonMin =
  -180

export const timeTrackingControllerUpdateResponseProjectProjectManagersItemAddressLonMax = 180
export const timeTrackingControllerUpdateResponseProjectProjectManagersItemLeaveTypesItemNameMax = 255
export const timeTrackingControllerUpdateResponseProjectProjectManagersItemLeaveTypesItemDescriptionMax = 1024
export const timeTrackingControllerUpdateResponseProjectProjectManagersItemLeaveTypesItemColorMin = 7

export const timeTrackingControllerUpdateResponseProjectProjectManagersItemLeaveTypesItemColorMax = 7
export const timeTrackingControllerUpdateResponseProjectTotalAllocationsMin = 0
export const timeTrackingControllerUpdateResponseProjectAddressLatMin = -90

export const timeTrackingControllerUpdateResponseProjectAddressLatMax = 90
export const timeTrackingControllerUpdateResponseProjectAddressLonMin = -180

export const timeTrackingControllerUpdateResponseProjectAddressLonMax = 180
export const timeTrackingControllerUpdateResponseUserFirstNameMax = 100
export const timeTrackingControllerUpdateResponseUserLastNameMax = 100
export const timeTrackingControllerUpdateResponseUserAddressLatMin = -90

export const timeTrackingControllerUpdateResponseUserAddressLatMax = 90
export const timeTrackingControllerUpdateResponseUserAddressLonMin = -180

export const timeTrackingControllerUpdateResponseUserAddressLonMax = 180
export const timeTrackingControllerUpdateResponseUserLeaveTypesItemNameMax = 255
export const timeTrackingControllerUpdateResponseUserLeaveTypesItemDescriptionMax = 1024
export const timeTrackingControllerUpdateResponseUserLeaveTypesItemColorMin = 7

export const timeTrackingControllerUpdateResponseUserLeaveTypesItemColorMax = 7

export const timeTrackingControllerUpdateResponse = zod.object({
  uuid: zod.coerce.string().uuid(),
  startAt: zod.coerce.string().datetime(),
  endAt: zod.coerce.string().datetime().optional(),
  project: zod
    .object({
      serial: zod.coerce
        .string()
        .max(timeTrackingControllerUpdateResponseProjectSerialMax)
        .optional(),
      projectSize: zod.coerce.number(),
      guaranteedUntil: zod.coerce.date().optional(),
      projectType: zod.object({
        name: zod.coerce.string(),
        uuid: zod.coerce.string(),
      }),
      completionPercentage: zod.coerce
        .number()
        .min(timeTrackingControllerUpdateResponseProjectCompletionPercentageMin)
        .max(timeTrackingControllerUpdateResponseProjectCompletionPercentageMax)
        .optional(),
      startedAt: zod.coerce.date().optional(),
      estimatedEndAt: zod.coerce.date().optional(),
      endAt: zod.coerce.date().optional(),
      status: zod.enum(['Active', 'In Active', 'Potential']).optional(),
      milestones: zod
        .array(
          zod.object({
            uuid: zod.coerce.string().uuid(),
            date: zod.coerce.date(),
            iconUrl: zod.coerce.string().nullable(),
            description: zod.coerce.string().nullable(),
            projectUuid: zod.coerce.string().uuid(),
            createdAt: zod.coerce.string().datetime(),
            updatedAt: zod.coerce.string().datetime(),
            project: zod.any(),
          })
        )
        .optional(),
      roadmaps: zod
        .array(
          zod.object({
            uuid: zod.coerce.string().uuid(),
            startAt: zod.coerce.date(),
            endAt: zod.coerce.date(),
            isVisible: zod.coerce.boolean().optional(),
            project: zod.any(),
            tag: zod.object({
              uuid: zod.coerce.string().uuid(),
              parent: zod.any().nullish(),
              name: zod.coerce
                .string()
                .max(
                  timeTrackingControllerUpdateResponseProjectRoadmapsItemTagNameMax
                ),
              companyType: zod
                .enum(['Client', 'Contractor', 'Other'])
                .optional(),
            }),
            color: zod.coerce
              .string()
              .min(
                timeTrackingControllerUpdateResponseProjectRoadmapsItemColorMin
              )
              .max(
                timeTrackingControllerUpdateResponseProjectRoadmapsItemColorMax
              )
              .nullish(),
            items: zod.array(
              zod.object({
                uuid: zod.coerce.string().uuid(),
                color: zod.coerce
                  .string()
                  .min(
                    timeTrackingControllerUpdateResponseProjectRoadmapsItemItemsItemColorMin
                  )
                  .max(
                    timeTrackingControllerUpdateResponseProjectRoadmapsItemItemsItemColorMax
                  )
                  .nullish(),
                startAt: zod.coerce.date(),
                endAt: zod.coerce.date(),
                description: zod.coerce.string().nullable(),
                roadmap: zod.any().optional(),
              })
            ),
          })
        )
        .optional(),
      projectManagers: zod.array(
        zod.object({
          uuid: zod.coerce.string().uuid(),
          email: zod.coerce.string().email(),
          firstName: zod.coerce
            .string()
            .max(
              timeTrackingControllerUpdateResponseProjectProjectManagersItemFirstNameMax
            ),
          lastName: zod.coerce
            .string()
            .max(
              timeTrackingControllerUpdateResponseProjectProjectManagersItemLastNameMax
            ),
          dateOfBirth: zod.coerce.date().optional(),
          phoneNumber: zod.coerce.string().optional(),
          status: zod.enum(['Active', 'Terminated']),
          permissions: zod.array(
            zod.enum(['admin', 'human-resource', 'user']).optional()
          ),
          role: zod
            .object({
              uuid: zod.coerce.string().uuid(),
              name: zod.coerce.string(),
            })
            .optional(),
          department: zod
            .object({
              uuid: zod.coerce.string().uuid(),
              name: zod.coerce.string(),
            })
            .optional(),
          createdAt: zod.coerce.string().datetime(),
          updatedAt: zod.coerce.string().datetime(),
          address: zod
            .object({
              geoLocation: zod
                .object({
                  x: zod.coerce.number().optional(),
                  y: zod.coerce.number().optional(),
                })
                .nullish(),
              lat: zod.coerce
                .number()
                .min(
                  timeTrackingControllerUpdateResponseProjectProjectManagersItemAddressLatMin
                )
                .max(
                  timeTrackingControllerUpdateResponseProjectProjectManagersItemAddressLatMax
                )
                .optional(),
              lon: zod.coerce
                .number()
                .min(
                  timeTrackingControllerUpdateResponseProjectProjectManagersItemAddressLonMin
                )
                .max(
                  timeTrackingControllerUpdateResponseProjectProjectManagersItemAddressLonMax
                )
                .optional(),
              uuid: zod.coerce.string(),
              country: zod.coerce.string().optional(),
              city: zod.coerce.string().optional(),
              street: zod.coerce.string().optional(),
              postcode: zod.coerce.string().optional(),
            })
            .optional(),
          profilePicture: zod.coerce.string(),
          fullName: zod.coerce.string(),
          leaveTypes: zod.array(
            zod.object({
              uuid: zod.coerce.string().uuid(),
              name: zod.coerce
                .string()
                .min(1)
                .max(
                  timeTrackingControllerUpdateResponseProjectProjectManagersItemLeaveTypesItemNameMax
                ),
              description: zod.coerce
                .string()
                .max(
                  timeTrackingControllerUpdateResponseProjectProjectManagersItemLeaveTypesItemDescriptionMax
                )
                .optional(),
              color: zod.coerce
                .string()
                .min(
                  timeTrackingControllerUpdateResponseProjectProjectManagersItemLeaveTypesItemColorMin
                )
                .max(
                  timeTrackingControllerUpdateResponseProjectProjectManagersItemLeaveTypesItemColorMax
                )
                .optional(),
              allowedPerYear: zod.object({
                days: zod.coerce.number(),
                hours: zod.coerce.number(),
                minutes: zod.coerce.number(),
                seconds: zod.coerce.number(),
                milliseconds: zod.coerce.number(),
              }),
              isFreebie: zod.coerce.boolean().optional(),
              isWithAttachment: zod.coerce.boolean().optional(),
            })
          ),
          layouts: zod.array(
            zod.object({
              uuid: zod.coerce.string(),
              user: zod.any(),
              layouts: zod.array(
                zod.object({
                  i: zod.coerce.string(),
                  x: zod.coerce.number(),
                  y: zod.coerce.number(),
                  w: zod.coerce.number(),
                  h: zod.coerce.number(),
                  minW: zod.coerce.number().optional(),
                  maxW: zod.coerce.number().optional(),
                  minH: zod.coerce.number().optional(),
                  maxH: zod.coerce.number().optional(),
                  static: zod.coerce.boolean().optional(),
                  isBounded: zod.coerce.boolean().optional(),
                  isDraggable: zod.coerce.boolean().optional(),
                  isResizable: zod.coerce.boolean().optional(),
                })
              ),
            })
          ),
        })
      ),
      totalAllocations: zod.coerce
        .number()
        .min(timeTrackingControllerUpdateResponseProjectTotalAllocationsMin),
      uuid: zod.coerce.string(),
      name: zod.coerce.string(),
      address: zod.object({
        geoLocation: zod
          .object({
            x: zod.coerce.number().optional(),
            y: zod.coerce.number().optional(),
          })
          .nullish(),
        lat: zod.coerce
          .number()
          .min(timeTrackingControllerUpdateResponseProjectAddressLatMin)
          .max(timeTrackingControllerUpdateResponseProjectAddressLatMax)
          .optional(),
        lon: zod.coerce
          .number()
          .min(timeTrackingControllerUpdateResponseProjectAddressLonMin)
          .max(timeTrackingControllerUpdateResponseProjectAddressLonMax)
          .optional(),
        uuid: zod.coerce.string(),
        country: zod.coerce.string().optional(),
        city: zod.coerce.string().optional(),
        street: zod.coerce.string().optional(),
        postcode: zod.coerce.string().optional(),
      }),
      createdAt: zod.coerce.string().datetime(),
      updatedAt: zod.coerce.string().datetime(),
    })
    .optional(),
  type: zod
    .enum(['home_work', 'office_work', 'site_work', 'transit'])
    .optional(),
  isClockOut: zod.coerce.boolean().optional(),
  status: zod.enum(['approved', 'pending', 'draft']),
  timeType: zod.enum(['work', 'break', 'time-off']).optional(),
  deletedAt: zod.coerce.string().datetime(),
  createdAt: zod.coerce.string().datetime(),
  updatedAt: zod.coerce.string().datetime(),
  duration: zod.coerce.number(),
  user: zod.object({
    uuid: zod.coerce.string().uuid(),
    email: zod.coerce.string().email(),
    firstName: zod.coerce
      .string()
      .max(timeTrackingControllerUpdateResponseUserFirstNameMax),
    lastName: zod.coerce
      .string()
      .max(timeTrackingControllerUpdateResponseUserLastNameMax),
    dateOfBirth: zod.coerce.date().optional(),
    phoneNumber: zod.coerce.string().optional(),
    status: zod.enum(['Active', 'Terminated']),
    permissions: zod.array(
      zod.enum(['admin', 'human-resource', 'user']).optional()
    ),
    role: zod
      .object({
        uuid: zod.coerce.string().uuid(),
        name: zod.coerce.string(),
      })
      .optional(),
    department: zod
      .object({
        uuid: zod.coerce.string().uuid(),
        name: zod.coerce.string(),
      })
      .optional(),
    createdAt: zod.coerce.string().datetime(),
    updatedAt: zod.coerce.string().datetime(),
    address: zod
      .object({
        geoLocation: zod
          .object({
            x: zod.coerce.number().optional(),
            y: zod.coerce.number().optional(),
          })
          .nullish(),
        lat: zod.coerce
          .number()
          .min(timeTrackingControllerUpdateResponseUserAddressLatMin)
          .max(timeTrackingControllerUpdateResponseUserAddressLatMax)
          .optional(),
        lon: zod.coerce
          .number()
          .min(timeTrackingControllerUpdateResponseUserAddressLonMin)
          .max(timeTrackingControllerUpdateResponseUserAddressLonMax)
          .optional(),
        uuid: zod.coerce.string(),
        country: zod.coerce.string().optional(),
        city: zod.coerce.string().optional(),
        street: zod.coerce.string().optional(),
        postcode: zod.coerce.string().optional(),
      })
      .optional(),
    profilePicture: zod.coerce.string(),
    fullName: zod.coerce.string(),
    leaveTypes: zod.array(
      zod.object({
        uuid: zod.coerce.string().uuid(),
        name: zod.coerce
          .string()
          .min(1)
          .max(timeTrackingControllerUpdateResponseUserLeaveTypesItemNameMax),
        description: zod.coerce
          .string()
          .max(
            timeTrackingControllerUpdateResponseUserLeaveTypesItemDescriptionMax
          )
          .optional(),
        color: zod.coerce
          .string()
          .min(timeTrackingControllerUpdateResponseUserLeaveTypesItemColorMin)
          .max(timeTrackingControllerUpdateResponseUserLeaveTypesItemColorMax)
          .optional(),
        allowedPerYear: zod.object({
          days: zod.coerce.number(),
          hours: zod.coerce.number(),
          minutes: zod.coerce.number(),
          seconds: zod.coerce.number(),
          milliseconds: zod.coerce.number(),
        }),
        isFreebie: zod.coerce.boolean().optional(),
        isWithAttachment: zod.coerce.boolean().optional(),
      })
    ),
    layouts: zod.array(
      zod.object({
        uuid: zod.coerce.string(),
        user: zod.any(),
        layouts: zod.array(
          zod.object({
            i: zod.coerce.string(),
            x: zod.coerce.number(),
            y: zod.coerce.number(),
            w: zod.coerce.number(),
            h: zod.coerce.number(),
            minW: zod.coerce.number().optional(),
            maxW: zod.coerce.number().optional(),
            minH: zod.coerce.number().optional(),
            maxH: zod.coerce.number().optional(),
            static: zod.coerce.boolean().optional(),
            isBounded: zod.coerce.boolean().optional(),
            isDraggable: zod.coerce.boolean().optional(),
            isResizable: zod.coerce.boolean().optional(),
          })
        ),
      })
    ),
  }),
  timeZone: zod.coerce.string(),
})

/**
 * @summary Delete time tracking record
 */
export const timeTrackingControllerDeleteParams = zod.object({
  id: zod.coerce.string().uuid(),
})

export const timeTrackingControllerDeleteHeader = zod.object({
  'x-time-zone': zod.coerce.string(),
})
