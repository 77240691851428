import {
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  useIonViewWillEnter,
} from '@ionic/react'
import { Redirect, type RouteComponentProps } from 'react-router-dom'
import Timer from '@/components/tabs/timer'
import Reports from '@/components/tabs/reports'
import Profile from '@/components/tabs/profile'
import { Sessions } from '@/components/tabs/sessions'
import { SessionEdit } from '@/components/tabs/sessions/edit'
import { SessionAdd } from '@/components/tabs/sessions/add'
import {
  personCircleOutline,
  pieChartOutline,
  pulseOutline,
  timerOutline,
} from 'ionicons/icons'
import type React from 'react'
import { useCallback, useMemo } from 'react'
import { SentryRoute } from '@/App'
import { NotFound } from '@/components/not-found'
import { useTimeSessions } from '@/hooks/useTimeSessions'
import { DateTime, Interval } from 'luxon'
import { useLocalNotification } from '@/hooks/use-local-notification'
import { NotificationActionType } from '@/enums/notification-action-type.enum'
import { NotificationId } from '@/enums/notification-id.enum'

export const Tabs: React.FC<RouteComponentProps> = ({ match }) => {
  /** Start calculate notifications */
  const startDate = DateTime.now()
  const startDateIso = startDate.toISODate()
  const [clockInDates, clockOutDates] = useMemo(() => {
    let clockInDate = startDate.set({
      hour: 8,
      minute: 0,
      second: 0,
      millisecond: 0,
    })
    let clockOutDate = startDate.set({
      hour: 19,
      minute: 0,
      second: 0,
      millisecond: 0,
    })
    if (clockInDate.diffNow().toMillis() < 0) {
      clockInDate = startDate.plus({ day: 1 })
    }
    if (clockOutDate.diffNow().toMillis() < 0) {
      clockOutDate = startDate.plus({ day: 1 })
    }
    const clockInDates = Interval.after(clockInDate, {
      day: 7,
    })
      .splitBy({ day: 1 })
      .map((d) => d.start)
      .filter((value) => !value?.isWeekend)
    const clockOutDates = Interval.after(clockOutDate, {
      day: 7,
    })
      .splitBy({ day: 1 })
      .map((d) => d.start)
      .filter((value) => !value?.isWeekend)

    return [clockInDates, clockOutDates]
  }, [startDateIso])

  const { send: sendNotification } = useLocalNotification({
    onListen: () => {
      // console.log(action) TODO: do something
    },
  })
  const handleScheduleNotifications = useCallback(() => {
    clockInDates.map((date) => {
      if (!date) {
        return
      }
      void sendNotification({
        actionTypeId: NotificationActionType.CLOCK_IN_REMINDER,
        id: NotificationId.CLOCK_IN_REMINDER + date.ordinal,
        title: 'Clock in reminder',
        body: "Good morning! It's time to start work. Did you forget to clock in?",
        schedule: {
          allowWhileIdle: true,
          at: date.toJSDate(),
        },
      })
    })
    clockOutDates.map((date) => {
      if (!date) {
        return
      }
      void sendNotification({
        actionTypeId: NotificationActionType.CLOCK_OUT_REMINDER,
        id: NotificationId.CLOCK_OUT_REMINDER + date.ordinal,
        title: 'Clock out reminder',
        body: 'Work hours are over! Have you forgotten to clock out?',
        schedule: {
          allowWhileIdle: true,
          at: date.toJSDate(),
        },
      })
    })
  }, [clockInDates, clockOutDates])

  useIonViewWillEnter(() => {
    handleScheduleNotifications()
  }, [])
  /** end calculate notifications */
  const { setSelectedDate } = useTimeSessions()
  const tabChange = (
    event: CustomEvent<{
      tab: string
    }>
  ) => {
    if (event.detail.tab === 'sessions') {
      setSelectedDate(DateTime.now().toISODate())
    }
  }
  return (
    <IonTabs>
      <IonRouterOutlet>
        <Redirect exact path={match.url} to="/tabs/timer" />
        <SentryRoute path={`${match.url}/timer`} component={Timer} />
        <SentryRoute path={`${match.url}/reports`} component={Reports} />
        <SentryRoute path={`${match.url}/profile`} component={Profile} />
        <SentryRoute
          exact
          path={`${match.url}/sessions`}
          component={Sessions}
        />
        <SentryRoute
          path={`${match.url}/sessions/:id/edit`}
          component={SessionEdit}
        />
        <SentryRoute
          path={`${match.url}/sessions/new/:type(work|break)`}
          component={SessionAdd}
        />
        <SentryRoute component={NotFound} />
      </IonRouterOutlet>
      <IonTabBar slot="bottom" onIonTabsWillChange={tabChange}>
        <IonTabButton tab="reports" href={`${match.url}/reports`}>
          <IonIcon aria-hidden="true" icon={pieChartOutline} />
          <IonLabel>Reports</IonLabel>
        </IonTabButton>
        <IonTabButton tab="sessions" href={`${match.url}/sessions`}>
          <IonIcon aria-hidden="true" icon={pulseOutline} />
          <IonLabel>Sessions</IonLabel>
        </IonTabButton>
        <IonTabButton tab="timer" href={`${match.url}/timer`}>
          <IonIcon aria-hidden="true" icon={timerOutline} />
          <IonLabel>Timer</IonLabel>
        </IonTabButton>
        <IonTabButton tab="profile" href={`${match.url}/profile`}>
          <IonIcon aria-hidden="true" icon={personCircleOutline} />
          <IonLabel>Profile</IonLabel>
        </IonTabButton>
      </IonTabBar>
    </IonTabs>
  )
}
