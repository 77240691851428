import { type Reducer } from 'react'
import type React from 'react'
import {
  nutritionOutline,
  pause,
  play,
  rocketOutline,
  stop,
} from 'ionicons/icons'
import { IonIcon } from '@ionic/react'

export enum TimerButtonState {
  Stopped = 'ready-to-start',
  Running = 'started',
  Paused = 'on-break',
  ReadyToEnd = 'ready-to-end',
  Ended = 'ended',
}

export type ItemCssClasses = {
  stateValue: TimerButtonState
  buttonClass: string
  textClass: string
  text: React.ReactNode
  icon: React.ReactNode
  theme?: string
  message?: {
    content: string
    icon?: string
    delay?: number
  }
}

export const timerInitialState = {
  stateValue: TimerButtonState.Stopped,
  icon: <IonIcon icon={play} size={'large'} />,
  textClass: 'text-secondary',
  text: 'Start',
  buttonClass: '!rounded-full w-3/6 aspect-square border-0 shadow-lg !bg-white',
}
export const timerReducer: Reducer<
  ItemCssClasses,
  { type: TimerButtonState; submit?: any }
> = (prevState, action): ItemCssClasses => {
  let newState: ItemCssClasses
  switch (action.type) {
    case TimerButtonState.Stopped:
      newState = {
        stateValue: action.type,
        icon: <IonIcon icon={play} size={'large'} />,
        theme: 'dark',
        textClass: 'text-secondary',
        text: 'Start',
        buttonClass:
          '!rounded-full w-3/6 aspect-square border-0 shadow-lg !bg-white',
        message: undefined,
      }
      if (prevState.stateValue === TimerButtonState.ReadyToEnd) {
        newState.message = {
          content: 'You have finished work',
          delay: 3000,
        }
      }
      return newState

    case TimerButtonState.Ended:
      return {
        stateValue: action.type,
        icon: <IonIcon icon={play} size={'large'} />,
        textClass: 'text-secondary',
        text: 'Start',
        buttonClass:
          '!rounded-full w-3/6 aspect-square border-0 shadow-lg !bg-white',
        message: undefined,
      }
    case TimerButtonState.Running:
      newState = {
        stateValue: TimerButtonState.Running,
        icon: <IonIcon icon={pause} size={'large'} />,
        theme: 'custom-01',
        textClass: 'text-white',
        text: 'Break',
        buttonClass:
          '!rounded-full w-3/6 aspect-square border-0 shadow-lg !bg-[#3FAAE5]',
        message: undefined,
      }
      if (prevState.stateValue === TimerButtonState.Stopped) {
        newState.message = {
          content: 'You have started work',
          delay: 3000,
        }
      } else if (prevState.stateValue === TimerButtonState.Paused) {
        newState.message = {
          content: 'You have resumed work',
          icon: rocketOutline,
          delay: 3000,
        }
      }
      return newState
    case TimerButtonState.Paused:
      newState = {
        stateValue: TimerButtonState.Paused,
        icon: <IonIcon icon={play} size={'large'} />,
        theme: 'custom-02',
        textClass: 'text-white',
        text: 'Resume',
        buttonClass:
          '!rounded-full w-3/6 aspect-square border-0 shadow-lg !bg-primary',
        message: {
          content: 'You’re on break, press play to resume work',
          icon: nutritionOutline,
        },
      }
      return newState
    case TimerButtonState.ReadyToEnd:
      return {
        stateValue: TimerButtonState.ReadyToEnd,
        icon: <IonIcon icon={stop} size={'large'} />,
        textClass: 'text-white',
        text: 'Finish',
        buttonClass:
          '!rounded-full w-3/6 aspect-square border-0 shadow-lg !bg-[#F4B09B] touchable',
        message: undefined,
      }
    default:
      return prevState
  }
}
