import {
  IonCol,
  IonContent,
  IonGrid,
  IonImg,
  IonPage,
  IonRow,
  IonSpinner,
} from '@ionic/react'
import type React from 'react'

export const AppLoading: React.FC = () => {
  return (
    <IonPage>
      <IonContent scrollY={false} scrollX={false}>
        <IonGrid className="ion-padding" style={{ height: '100%' }}>
          <IonRow className="ion-justify-content-center ion-align-items-center">
            <IonCol size="auto">
              <IonImg
                src="assets/img/trium-novem-logo.svg"
                style={{ width: 187, height: 89 }}
              />
            </IonCol>
          </IonRow>
          <IonRow
            className="ion-justify-content-center ion-align-items-center"
            style={{ height: '60%' }}
          >
            <IonCol size="auto">
              <IonSpinner></IonSpinner>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  )
}
