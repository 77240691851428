import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query'
import type {
  InfiniteData,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query'
import { useCallback } from 'react'
import type {
  AuthControllerResetPasswordPagePathParameters,
  AuthControllerResetPasswordPathParameters,
  AuthControllerValidateResetPasswordPathParameters,
  AuthUpdateUserProfileDto,
  ErrorDto,
  LoginResponse,
  ResetPasswordDto,
  SignInDto,
  UpdateUserProfilePictureDto,
  User,
} from '../model'
import { useCustomInstance } from '../mutator/use-custom-instance'
import type { ErrorType } from '../mutator/use-custom-instance'

/**
 * @summary Login user into the platform
 */
export const useAuthControllerSignInHook = () => {
  const authControllerSignIn = useCustomInstance<LoginResponse>()

  return useCallback(
    (signInDto: SignInDto) => {
      return authControllerSignIn({
        url: `/auth/login`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: signInDto,
      })
    },
    [authControllerSignIn]
  )
}

export const useAuthControllerSignInMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAuthControllerSignInHook>>>,
    TError,
    { data: SignInDto },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useAuthControllerSignInHook>>>,
  TError,
  { data: SignInDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const authControllerSignIn = useAuthControllerSignInHook()

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useAuthControllerSignInHook>>>,
    { data: SignInDto }
  > = (props) => {
    const { data } = props ?? {}

    return authControllerSignIn(data)
  }

  return { mutationFn, ...mutationOptions }
}

export type AuthControllerSignInMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useAuthControllerSignInHook>>>
>
export type AuthControllerSignInMutationBody = SignInDto
export type AuthControllerSignInMutationError = ErrorType<void>

/**
 * @summary Login user into the platform
 */
export const useAuthControllerSignIn = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAuthControllerSignInHook>>>,
    TError,
    { data: SignInDto },
    TContext
  >
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useAuthControllerSignInHook>>>,
  TError,
  { data: SignInDto },
  TContext
> => {
  const mutationOptions = useAuthControllerSignInMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * @summary Get current logged-in user profile information
 */
export const useAuthControllerGetProfileHook = () => {
  const authControllerGetProfile = useCustomInstance<User>()

  return useCallback(() => {
    return authControllerGetProfile({ url: `/auth/profile`, method: 'GET' })
  }, [authControllerGetProfile])
}

export const getAuthControllerGetProfileQueryKey = () => {
  return [`/auth/profile`] as const
}

export const useAuthControllerGetProfileInfiniteQueryOptions = <
  TData = InfiniteData<
    Awaited<ReturnType<ReturnType<typeof useAuthControllerGetProfileHook>>>
  >,
  TError = ErrorType<ErrorDto>,
>(options?: {
  query?: Partial<
    UseInfiniteQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useAuthControllerGetProfileHook>>>,
      TError,
      TData
    >
  >
}) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getAuthControllerGetProfileQueryKey()

  const authControllerGetProfile = useAuthControllerGetProfileHook()

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useAuthControllerGetProfileHook>>>
  > = () => authControllerGetProfile()

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useAuthControllerGetProfileHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type AuthControllerGetProfileInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useAuthControllerGetProfileHook>>>
>
export type AuthControllerGetProfileInfiniteQueryError = ErrorType<ErrorDto>

/**
 * @summary Get current logged-in user profile information
 */
export const useAuthControllerGetProfileInfinite = <
  TData = InfiniteData<
    Awaited<ReturnType<ReturnType<typeof useAuthControllerGetProfileHook>>>
  >,
  TError = ErrorType<ErrorDto>,
>(options?: {
  query?: Partial<
    UseInfiniteQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useAuthControllerGetProfileHook>>>,
      TError,
      TData
    >
  >
}): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useAuthControllerGetProfileInfiniteQueryOptions(options)

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const useAuthControllerGetProfileQueryOptions = <
  TData = Awaited<
    ReturnType<ReturnType<typeof useAuthControllerGetProfileHook>>
  >,
  TError = ErrorType<ErrorDto>,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useAuthControllerGetProfileHook>>>,
      TError,
      TData
    >
  >
}) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getAuthControllerGetProfileQueryKey()

  const authControllerGetProfile = useAuthControllerGetProfileHook()

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useAuthControllerGetProfileHook>>>
  > = () => authControllerGetProfile()

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useAuthControllerGetProfileHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type AuthControllerGetProfileQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useAuthControllerGetProfileHook>>>
>
export type AuthControllerGetProfileQueryError = ErrorType<ErrorDto>

/**
 * @summary Get current logged-in user profile information
 */
export const useAuthControllerGetProfile = <
  TData = Awaited<
    ReturnType<ReturnType<typeof useAuthControllerGetProfileHook>>
  >,
  TError = ErrorType<ErrorDto>,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useAuthControllerGetProfileHook>>>,
      TError,
      TData
    >
  >
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useAuthControllerGetProfileQueryOptions(options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * @summary Update current logged-in user profile information
 */
export const useAuthControllerUpdateHook = () => {
  const authControllerUpdate = useCustomInstance<User>()

  return useCallback(
    (authUpdateUserProfileDto: AuthUpdateUserProfileDto) => {
      return authControllerUpdate({
        url: `/auth/profile`,
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json' },
        data: authUpdateUserProfileDto,
      })
    },
    [authControllerUpdate]
  )
}

export const useAuthControllerUpdateMutationOptions = <
  TError = ErrorType<ErrorDto>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAuthControllerUpdateHook>>>,
    TError,
    { data: AuthUpdateUserProfileDto },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useAuthControllerUpdateHook>>>,
  TError,
  { data: AuthUpdateUserProfileDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const authControllerUpdate = useAuthControllerUpdateHook()

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useAuthControllerUpdateHook>>>,
    { data: AuthUpdateUserProfileDto }
  > = (props) => {
    const { data } = props ?? {}

    return authControllerUpdate(data)
  }

  return { mutationFn, ...mutationOptions }
}

export type AuthControllerUpdateMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useAuthControllerUpdateHook>>>
>
export type AuthControllerUpdateMutationBody = AuthUpdateUserProfileDto
export type AuthControllerUpdateMutationError = ErrorType<ErrorDto>

/**
 * @summary Update current logged-in user profile information
 */
export const useAuthControllerUpdate = <
  TError = ErrorType<ErrorDto>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAuthControllerUpdateHook>>>,
    TError,
    { data: AuthUpdateUserProfileDto },
    TContext
  >
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useAuthControllerUpdateHook>>>,
  TError,
  { data: AuthUpdateUserProfileDto },
  TContext
> => {
  const mutationOptions = useAuthControllerUpdateMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * @summary Logout and invalid user refresh token
 */
export const useAuthControllerLogoutHook = () => {
  const authControllerLogout = useCustomInstance<void>()

  return useCallback(() => {
    return authControllerLogout({ url: `/auth/logout`, method: 'GET' })
  }, [authControllerLogout])
}

export const getAuthControllerLogoutQueryKey = () => {
  return [`/auth/logout`] as const
}

export const useAuthControllerLogoutInfiniteQueryOptions = <
  TData = InfiniteData<
    Awaited<ReturnType<ReturnType<typeof useAuthControllerLogoutHook>>>
  >,
  TError = ErrorType<ErrorDto>,
>(options?: {
  query?: Partial<
    UseInfiniteQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useAuthControllerLogoutHook>>>,
      TError,
      TData
    >
  >
}) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getAuthControllerLogoutQueryKey()

  const authControllerLogout = useAuthControllerLogoutHook()

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useAuthControllerLogoutHook>>>
  > = () => authControllerLogout()

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useAuthControllerLogoutHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type AuthControllerLogoutInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useAuthControllerLogoutHook>>>
>
export type AuthControllerLogoutInfiniteQueryError = ErrorType<ErrorDto>

/**
 * @summary Logout and invalid user refresh token
 */
export const useAuthControllerLogoutInfinite = <
  TData = InfiniteData<
    Awaited<ReturnType<ReturnType<typeof useAuthControllerLogoutHook>>>
  >,
  TError = ErrorType<ErrorDto>,
>(options?: {
  query?: Partial<
    UseInfiniteQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useAuthControllerLogoutHook>>>,
      TError,
      TData
    >
  >
}): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useAuthControllerLogoutInfiniteQueryOptions(options)

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const useAuthControllerLogoutQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useAuthControllerLogoutHook>>>,
  TError = ErrorType<ErrorDto>,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useAuthControllerLogoutHook>>>,
      TError,
      TData
    >
  >
}) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getAuthControllerLogoutQueryKey()

  const authControllerLogout = useAuthControllerLogoutHook()

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useAuthControllerLogoutHook>>>
  > = () => authControllerLogout()

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useAuthControllerLogoutHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type AuthControllerLogoutQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useAuthControllerLogoutHook>>>
>
export type AuthControllerLogoutQueryError = ErrorType<ErrorDto>

/**
 * @summary Logout and invalid user refresh token
 */
export const useAuthControllerLogout = <
  TData = Awaited<ReturnType<ReturnType<typeof useAuthControllerLogoutHook>>>,
  TError = ErrorType<ErrorDto>,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useAuthControllerLogoutHook>>>,
      TError,
      TData
    >
  >
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useAuthControllerLogoutQueryOptions(options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * @summary Refresh user access token using cookie
 */
export const useAuthControllerRefreshTokensHook = () => {
  const authControllerRefreshTokens = useCustomInstance<LoginResponse>()

  return useCallback(() => {
    return authControllerRefreshTokens({ url: `/auth/refresh`, method: 'POST' })
  }, [authControllerRefreshTokens])
}

export const useAuthControllerRefreshTokensMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAuthControllerRefreshTokensHook>>>,
    TError,
    void,
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useAuthControllerRefreshTokensHook>>>,
  TError,
  void,
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const authControllerRefreshTokens = useAuthControllerRefreshTokensHook()

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useAuthControllerRefreshTokensHook>>>,
    void
  > = () => {
    return authControllerRefreshTokens()
  }

  return { mutationFn, ...mutationOptions }
}

export type AuthControllerRefreshTokensMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useAuthControllerRefreshTokensHook>>>
>

export type AuthControllerRefreshTokensMutationError = ErrorType<void>

/**
 * @summary Refresh user access token using cookie
 */
export const useAuthControllerRefreshTokens = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAuthControllerRefreshTokensHook>>>,
    TError,
    void,
    TContext
  >
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useAuthControllerRefreshTokensHook>>>,
  TError,
  void,
  TContext
> => {
  const mutationOptions = useAuthControllerRefreshTokensMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * @summary Update current logged-in user profile picture image
 */
export const useAuthControllerChangeProfilePictureHook = () => {
  const authControllerChangeProfilePicture = useCustomInstance<User>()

  return useCallback(
    (updateUserProfilePictureDto: UpdateUserProfilePictureDto) => {
      const formData = new FormData()
      formData.append(
        'profilePicture',
        updateUserProfilePictureDto.profilePicture
      )

      return authControllerChangeProfilePicture({
        url: `/auth/change-profile-picture`,
        method: 'PATCH',
        headers: { 'Content-Type': 'multipart/form-data' },
        data: formData,
      })
    },
    [authControllerChangeProfilePicture]
  )
}

export const useAuthControllerChangeProfilePictureMutationOptions = <
  TError = ErrorType<ErrorDto>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<ReturnType<typeof useAuthControllerChangeProfilePictureHook>>
    >,
    TError,
    { data: UpdateUserProfilePictureDto },
    TContext
  >
}): UseMutationOptions<
  Awaited<
    ReturnType<ReturnType<typeof useAuthControllerChangeProfilePictureHook>>
  >,
  TError,
  { data: UpdateUserProfilePictureDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const authControllerChangeProfilePicture =
    useAuthControllerChangeProfilePictureHook()

  const mutationFn: MutationFunction<
    Awaited<
      ReturnType<ReturnType<typeof useAuthControllerChangeProfilePictureHook>>
    >,
    { data: UpdateUserProfilePictureDto }
  > = (props) => {
    const { data } = props ?? {}

    return authControllerChangeProfilePicture(data)
  }

  return { mutationFn, ...mutationOptions }
}

export type AuthControllerChangeProfilePictureMutationResult = NonNullable<
  Awaited<
    ReturnType<ReturnType<typeof useAuthControllerChangeProfilePictureHook>>
  >
>
export type AuthControllerChangeProfilePictureMutationBody =
  UpdateUserProfilePictureDto
export type AuthControllerChangeProfilePictureMutationError =
  ErrorType<ErrorDto>

/**
 * @summary Update current logged-in user profile picture image
 */
export const useAuthControllerChangeProfilePicture = <
  TError = ErrorType<ErrorDto>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<ReturnType<typeof useAuthControllerChangeProfilePictureHook>>
    >,
    TError,
    { data: UpdateUserProfilePictureDto },
    TContext
  >
}): UseMutationResult<
  Awaited<
    ReturnType<ReturnType<typeof useAuthControllerChangeProfilePictureHook>>
  >,
  TError,
  { data: UpdateUserProfilePictureDto },
  TContext
> => {
  const mutationOptions =
    useAuthControllerChangeProfilePictureMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * @summary Set new password using reset password token
 */
export const useAuthControllerResetPasswordHook = () => {
  const authControllerResetPassword = useCustomInstance<void>()

  return useCallback(
    (
      { token }: AuthControllerResetPasswordPathParameters,
      resetPasswordDto: ResetPasswordDto
    ) => {
      return authControllerResetPassword({
        url: `/auth/reset-password/${encodeURIComponent(String(token))}`,
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json' },
        data: resetPasswordDto,
      })
    },
    [authControllerResetPassword]
  )
}

export const useAuthControllerResetPasswordMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAuthControllerResetPasswordHook>>>,
    TError,
    {
      pathParams: AuthControllerResetPasswordPathParameters
      data: ResetPasswordDto
    },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useAuthControllerResetPasswordHook>>>,
  TError,
  {
    pathParams: AuthControllerResetPasswordPathParameters
    data: ResetPasswordDto
  },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const authControllerResetPassword = useAuthControllerResetPasswordHook()

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useAuthControllerResetPasswordHook>>>,
    {
      pathParams: AuthControllerResetPasswordPathParameters
      data: ResetPasswordDto
    }
  > = (props) => {
    const { pathParams, data } = props ?? {}

    return authControllerResetPassword(pathParams, data)
  }

  return { mutationFn, ...mutationOptions }
}

export type AuthControllerResetPasswordMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useAuthControllerResetPasswordHook>>>
>
export type AuthControllerResetPasswordMutationBody = ResetPasswordDto
export type AuthControllerResetPasswordMutationError = ErrorType<void>

/**
 * @summary Set new password using reset password token
 */
export const useAuthControllerResetPassword = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAuthControllerResetPasswordHook>>>,
    TError,
    {
      pathParams: AuthControllerResetPasswordPathParameters
      data: ResetPasswordDto
    },
    TContext
  >
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useAuthControllerResetPasswordHook>>>,
  TError,
  {
    pathParams: AuthControllerResetPasswordPathParameters
    data: ResetPasswordDto
  },
  TContext
> => {
  const mutationOptions = useAuthControllerResetPasswordMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * @summary Validate reset password token is valid
 */
export const useAuthControllerValidateResetPasswordHook = () => {
  const authControllerValidateResetPassword = useCustomInstance<void>()

  return useCallback(
    ({ token }: AuthControllerValidateResetPasswordPathParameters) => {
      return authControllerValidateResetPassword({
        url: `/auth/reset-password/${encodeURIComponent(String(token))}`,
        method: 'HEAD',
      })
    },
    [authControllerValidateResetPassword]
  )
}

export const useAuthControllerValidateResetPasswordMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<ReturnType<typeof useAuthControllerValidateResetPasswordHook>>
    >,
    TError,
    { pathParams: AuthControllerValidateResetPasswordPathParameters },
    TContext
  >
}): UseMutationOptions<
  Awaited<
    ReturnType<ReturnType<typeof useAuthControllerValidateResetPasswordHook>>
  >,
  TError,
  { pathParams: AuthControllerValidateResetPasswordPathParameters },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const authControllerValidateResetPassword =
    useAuthControllerValidateResetPasswordHook()

  const mutationFn: MutationFunction<
    Awaited<
      ReturnType<ReturnType<typeof useAuthControllerValidateResetPasswordHook>>
    >,
    { pathParams: AuthControllerValidateResetPasswordPathParameters }
  > = (props) => {
    const { pathParams } = props ?? {}

    return authControllerValidateResetPassword(pathParams)
  }

  return { mutationFn, ...mutationOptions }
}

export type AuthControllerValidateResetPasswordMutationResult = NonNullable<
  Awaited<
    ReturnType<ReturnType<typeof useAuthControllerValidateResetPasswordHook>>
  >
>

export type AuthControllerValidateResetPasswordMutationError = ErrorType<void>

/**
 * @summary Validate reset password token is valid
 */
export const useAuthControllerValidateResetPassword = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<ReturnType<typeof useAuthControllerValidateResetPasswordHook>>
    >,
    TError,
    { pathParams: AuthControllerValidateResetPasswordPathParameters },
    TContext
  >
}): UseMutationResult<
  Awaited<
    ReturnType<ReturnType<typeof useAuthControllerValidateResetPasswordHook>>
  >,
  TError,
  { pathParams: AuthControllerValidateResetPasswordPathParameters },
  TContext
> => {
  const mutationOptions =
    useAuthControllerValidateResetPasswordMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * @summary Redirect to reset password page in the mobile app to support email clients url
 */
export const useAuthControllerResetPasswordPageHook = () => {
  const authControllerResetPasswordPage = useCustomInstance<void>()

  return useCallback(
    ({ token }: AuthControllerResetPasswordPagePathParameters) => {
      return authControllerResetPasswordPage({
        url: `/auth/redirect-reset-password/${encodeURIComponent(String(token))}`,
        method: 'GET',
      })
    },
    [authControllerResetPasswordPage]
  )
}

export const getAuthControllerResetPasswordPageQueryKey = ({
  token,
}: AuthControllerResetPasswordPagePathParameters) => {
  return [`/auth/redirect-reset-password/${token}`] as const
}

export const useAuthControllerResetPasswordPageInfiniteQueryOptions = <
  TData = InfiniteData<
    Awaited<
      ReturnType<ReturnType<typeof useAuthControllerResetPasswordPageHook>>
    >
  >,
  TError = ErrorType<unknown>,
>(
  { token }: AuthControllerResetPasswordPagePathParameters,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<
          ReturnType<ReturnType<typeof useAuthControllerResetPasswordPageHook>>
        >,
        TError,
        TData
      >
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ??
    getAuthControllerResetPasswordPageQueryKey({ token })

  const authControllerResetPasswordPage =
    useAuthControllerResetPasswordPageHook()

  const queryFn: QueryFunction<
    Awaited<
      ReturnType<ReturnType<typeof useAuthControllerResetPasswordPageHook>>
    >
  > = () => authControllerResetPasswordPage({ token })

  return {
    queryKey,
    queryFn,
    enabled: !!token,
    ...queryOptions,
  } as UseInfiniteQueryOptions<
    Awaited<
      ReturnType<ReturnType<typeof useAuthControllerResetPasswordPageHook>>
    >,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type AuthControllerResetPasswordPageInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useAuthControllerResetPasswordPageHook>>>
>
export type AuthControllerResetPasswordPageInfiniteQueryError =
  ErrorType<unknown>

/**
 * @summary Redirect to reset password page in the mobile app to support email clients url
 */
export const useAuthControllerResetPasswordPageInfinite = <
  TData = InfiniteData<
    Awaited<
      ReturnType<ReturnType<typeof useAuthControllerResetPasswordPageHook>>
    >
  >,
  TError = ErrorType<unknown>,
>(
  { token }: AuthControllerResetPasswordPagePathParameters,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<
          ReturnType<ReturnType<typeof useAuthControllerResetPasswordPageHook>>
        >,
        TError,
        TData
      >
    >
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useAuthControllerResetPasswordPageInfiniteQueryOptions(
    { token },
    options
  )

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const useAuthControllerResetPasswordPageQueryOptions = <
  TData = Awaited<
    ReturnType<ReturnType<typeof useAuthControllerResetPasswordPageHook>>
  >,
  TError = ErrorType<unknown>,
>(
  { token }: AuthControllerResetPasswordPagePathParameters,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<ReturnType<typeof useAuthControllerResetPasswordPageHook>>
        >,
        TError,
        TData
      >
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ??
    getAuthControllerResetPasswordPageQueryKey({ token })

  const authControllerResetPasswordPage =
    useAuthControllerResetPasswordPageHook()

  const queryFn: QueryFunction<
    Awaited<
      ReturnType<ReturnType<typeof useAuthControllerResetPasswordPageHook>>
    >
  > = () => authControllerResetPasswordPage({ token })

  return {
    queryKey,
    queryFn,
    enabled: !!token,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<
      ReturnType<ReturnType<typeof useAuthControllerResetPasswordPageHook>>
    >,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type AuthControllerResetPasswordPageQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useAuthControllerResetPasswordPageHook>>>
>
export type AuthControllerResetPasswordPageQueryError = ErrorType<unknown>

/**
 * @summary Redirect to reset password page in the mobile app to support email clients url
 */
export const useAuthControllerResetPasswordPage = <
  TData = Awaited<
    ReturnType<ReturnType<typeof useAuthControllerResetPasswordPageHook>>
  >,
  TError = ErrorType<unknown>,
>(
  { token }: AuthControllerResetPasswordPagePathParameters,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<ReturnType<typeof useAuthControllerResetPasswordPageHook>>
        >,
        TError,
        TData
      >
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useAuthControllerResetPasswordPageQueryOptions(
    { token },
    options
  )

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}
