import type React from 'react'
import { useEffect } from 'react'
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonPage,
  IonSkeletonText,
  IonText,
  IonThumbnail,
  IonTitle,
  IonToolbar,
  useIonLoading,
  useIonRouter,
} from '@ionic/react'
import { useParams } from 'react-router'
import {
  getTimeTrackingControllerFindAllQueryKey,
  timeTrackingControllerUpdateBody,
  type TimeTrackingDto,
  TimeTrackingType,
  useTimeTrackingControllerFind,
  useTimeTrackingControllerUpdate,
} from '@/api'
import { type SubmitHandler, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { SessionForm } from '@/components/tabs/sessions/_form'
import { UseFormRequestError } from '@/hooks/use-form-request-error'
import { useQueryClient } from '@tanstack/react-query'

const resolver = timeTrackingControllerUpdateBody.refine(
  (data) => {
    return data.startAt < data.endAt
  },
  {
    message: 'End time should after start time',
    path: ['endAt'],
  }
)
export const SessionEdit: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const { data, isLoading, isError } = useTimeTrackingControllerFind({
    id,
  })
  const [loadingPresent, dismiss] = useIonLoading()
  const router = useIonRouter()
  const queryClient = useQueryClient()
  const { mutateAsync: timeTrackingControllerUpdateHook } =
    useTimeTrackingControllerUpdate({
      mutation: {
        onSuccess: () => {
          void queryClient.invalidateQueries({
            queryKey: getTimeTrackingControllerFindAllQueryKey(),
          })
          router.push('/tabs/sessions', 'back')
        },
      },
    })
  const form = useForm<TimeTrackingDto>({
    mode: 'onBlur',
    resolver: zodResolver(resolver),
  })

  const handleFormRequestError = UseFormRequestError({
    setError: form.setError,
  })
  useEffect(() => {
    if (!isLoading) {
      form.reset({
        type: data?.type ?? undefined,
        project: data?.project,
        timeType: data?.timeType ?? TimeTrackingType.work,
        startAt: data?.startAt,
        endAt: data?.endAt,
        isClockOut: data?.isClockOut,
      })
    }
  }, [data, isLoading])
  const onSubmit: SubmitHandler<TimeTrackingDto> = (data) => {
    void loadingPresent()
    timeTrackingControllerUpdateHook({ pathParams: { id }, data })
      .catch(handleFormRequestError)
      .finally(() => {
        void dismiss()
      })
  }
  return (
    <IonPage className="sessions">
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton></IonBackButton>
          </IonButtons>
          <IonTitle className={'ion-text-center'}>Sessions - Edit</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {isLoading && (
          <IonList>
            <IonListHeader>
              <IonSkeletonText
                animated={true}
                style={{ width: '80px' }}
              ></IonSkeletonText>
            </IonListHeader>
            <IonItem>
              <IonThumbnail slot="start">
                <IonSkeletonText animated={true}></IonSkeletonText>
              </IonThumbnail>
              <IonLabel>
                <h3>
                  <IonSkeletonText
                    animated={true}
                    style={{ width: '80%' }}
                  ></IonSkeletonText>
                </h3>
                <p>
                  <IonSkeletonText
                    animated={true}
                    style={{ width: '60%' }}
                  ></IonSkeletonText>
                </p>
                <p>
                  <IonSkeletonText
                    animated={true}
                    style={{ width: '30%' }}
                  ></IonSkeletonText>
                </p>
              </IonLabel>
            </IonItem>
          </IonList>
        )}
        {isError && (
          <>
            <IonText
              color="danger"
              className="ion-margin-top"
              style={{ display: 'block', textAlign: 'center' }}
            >
              Cannot load content.
              <br />
              <IonButton
                className="ion-margin-top"
                routerLink="/tabs/sessions/"
                color="primary"
              >
                Go Back
              </IonButton>
            </IonText>
          </>
        )}
        {data !== undefined && <SessionForm form={form} onSubmit={onSubmit} />}
      </IonContent>
    </IonPage>
  )
}
