import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
} from '@ionic/react'
import { PieChart } from 'react-minimal-pie-chart'
import { useReportControllerWorkProjectInsight } from '@/api'
import { Duration } from 'luxon'
import type React from 'react'
import { useMemo, useState } from 'react'
import type { Data } from 'react-minimal-pie-chart/types/commonTypes'

const colors = [
  'url(#gradient1)',
  'url(#gradient2)',
  'url(#gradient3)',
  'url(#gradient4)',
  'url(#gradient5)',
  'url(#gradient6)',
  'url(#gradient7)',
  'url(#gradient8)',
  'url(#gradient9)',
  'url(#gradient10)',
]

type ProjectDistributionData = {
  title: string
  value: number
  color: string
  labelColor: string
}

const labelColors = [
  '#0CFD8D',
  '#FAC602',
  '#CB07F1',
  '#3E30E4',
  '#ebbe2d',
  '#150f2f',
  '#a1bdd4',
  '#bb967c',
  '#a33118',
  '#6754b6',
]
export const ProjectDistribution: React.FC<{
  filter: { from: string; to: string }
}> = ({ filter }) => {
  const [selected, setSelected] = useState<number | undefined>(0)

  const { data: workProjectInsight } =
    // @ts-ignore
    useReportControllerWorkProjectInsight(filter)

  const data: Data<ProjectDistributionData> = useMemo(() => {
    if (workProjectInsight == null || workProjectInsight.length === 0) {
      return []
    }
    return workProjectInsight.map((i, index) => ({
      title: i.projectName,
      value: Duration.fromObject(i.workDuration).as('minutes'),
      color: colors[index] ?? colors[0],
      labelColor: labelColors[index] ?? labelColors[0],
    }))
  }, [workProjectInsight])
  if (workProjectInsight == null) {
    return
  }
  return (
    <IonCard style={{ width: '100%' }}>
      <IonCardHeader>
        <IonCardTitle>Project Distribution</IonCardTitle>
      </IonCardHeader>
      <IonCardContent>
        {data.length > 0 && (
          <PieChart<ProjectDistributionData>
            data={data}
            lineWidth={20}
            style={{ width: '75%' }}
            rounded
            label={({ x, y, dx, dy, dataEntry, dataIndex }) => {
              if (dataIndex === selected) {
                return (
                  <text
                    x={x}
                    y={y}
                    dx={dx}
                    dy={dy}
                    dominantBaseline="central"
                    textAnchor="middle"
                    style={{
                      fill: dataEntry.color,
                      fontSize: '8px',
                      fontFamily: 'Roboto',
                    }}
                  >
                    <tspan x={x} y={y - 10}>
                      {dataEntry.title}
                    </tspan>
                    <tspan x={x} y={y + 15}>
                      {Math.round(dataEntry.percentage) + '%'}
                    </tspan>
                  </text>
                )
              }
              return ''
            }}
            labelPosition={0}
            animate
            onClick={(_, index) =>
              setSelected((prevState) =>
                index === prevState ? undefined : index
              )
            }
          >
            <defs>
              <linearGradient id="gradient1">
                <stop offset="0%" stopColor="#00FF94" />
                <stop offset="100%" stopColor="#C9D911" />
              </linearGradient>
              <linearGradient id="gradient2">
                <stop offset="0%" stopColor="#D95411" />
                <stop offset="100%" stopColor="#FFD600" />
              </linearGradient>
              <linearGradient id="gradient3">
                <stop offset="0%" stopColor="#C911D9" />
                <stop offset="100%" stopColor="#CC00FF" />
              </linearGradient>
              <linearGradient id="gradient4">
                <stop offset="0%" stopColor="#2111D9" />
                <stop offset="100%" stopColor="#5E51F1" />
              </linearGradient>
              <linearGradient id="gradient5">
                <stop offset="0%" stopColor="#ebbe2d" />
                <stop offset="100%" stopColor="#c1b9d1" />
              </linearGradient>
              <linearGradient id="gradient6">
                <stop offset="0%" stopColor="#150f2f" />
                <stop offset="100%" stopColor="#b1728a" />
              </linearGradient>
              <linearGradient id="gradient7">
                <stop offset="0%" stopColor="#a1bdd4" />
                <stop offset="100%" stopColor="#5fd78e" />
              </linearGradient>
              <linearGradient id="gradient8">
                <stop offset="0%" stopColor="#bb967c" />
                <stop offset="100%" stopColor="#fdcad8" />
              </linearGradient>
              <linearGradient id="gradient9">
                <stop offset="0%" stopColor="#a33118" />
                <stop offset="100%" stopColor="#fea58a" />
              </linearGradient>
              <linearGradient id="gradient10">
                <stop offset="0%" stopColor="#6754b6" />
                <stop offset="100%" stopColor="#6754b6" />
              </linearGradient>
            </defs>
          </PieChart>
        )}
        {data.length === 0 && (
          <div style={{ textAlign: 'center', minHeight: '200px' }}>
            Nothing to show
          </div>
        )}
      </IonCardContent>
    </IonCard>
  )
}
