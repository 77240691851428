import type React from 'react'

const progressCalculator = (
  curValue: number,
  maxValue: number,
  maxStroke: number
) => {
  return maxStroke - (maxStroke * (curValue % maxValue)) / maxValue
}

type ProgressCircleType = {
  strokeDashArray: number
  strokeId: string
  maxValue: number
  curValue: number
}

export const ProgressCircle: React.FC<ProgressCircleType> = ({
  strokeDashArray,
  strokeId,
  maxValue,
  curValue,
}) => {
  /** Rotation degrees found manually,
   * so that the overlapping shadow would stay
   * in the correct position on the circle */
  const rotationOffset = 35
  const hueStep = 180
  const overlap = curValue >= maxValue
  const overlapRotation = overlap
    ? (360 * curValue) / maxValue - rotationOffset
    : 0
  const hueRotate = Math.floor(curValue / maxValue) * hueStep
  return (
    <>
      <circle
        strokeDasharray={strokeDashArray}
        stroke={`url(#${strokeId})`}
        style={{
          transformOrigin: 'center',
          rotate: `${overlapRotation}deg`,
          opacity: curValue !== 0 ? 1 : 0,
          filter: `hue-rotate(${hueRotate}deg)`,
        }}
        strokeDashoffset={
          overlap ? 0 : progressCalculator(curValue, maxValue, strokeDashArray)
        }
      />
      <circle
        style={{
          transformOrigin: 'center',
          rotate: `${overlapRotation}deg`,
          opacity: overlap ? 1 : 0,
          filter: `hue-rotate(${hueRotate}deg) drop-shadow(-6px 8px 6px black)`,
        }}
        strokeDasharray={strokeDashArray}
        stroke={`url(#${strokeId})`}
        strokeDashoffset={0.9 * strokeDashArray}
      />
    </>
  )
}
