import type React from 'react'
import { useCallback, useState } from 'react'
import {
  IonContent,
  IonHeader,
  IonicSlides,
  IonItem,
  IonList,
  IonPage,
  IonSegment,
  IonSegmentButton,
  IonTitle,
  IonToolbar,
  type SegmentCustomEvent,
  type SegmentValue,
  useIonViewWillEnter,
} from '@ionic/react'
import 'swiper/css/pagination'
import { Swiper, SwiperSlide } from 'swiper/react'
import './reports.css'
import { TodayStatistics } from '@/components/tabs/reports/today-statistics'
import { LocationDistribution } from '@/components/tabs/reports/location-distribution'
import { ProjectDistribution } from '@/components/tabs/reports/project-distribution'
import { DateTime } from 'luxon'
import { ClockInClockOutDistribution } from '@/components/tabs/reports/clockin-clockout-distribution'
import { useQueryClient } from '@tanstack/react-query'
import type { ReportControllerWorkVsBreakInsightPathParameters } from '@/api'
import {
  getReportControllerGetWorkHistoryQueryKey,
  getReportControllerWorkLocationInsightQueryKey,
  getReportControllerWorkProjectInsightQueryKey,
  getReportControllerWorkVsBreakInsightQueryKey,
} from '@/api'

const Reports: React.FC = () => {
  const [selectedFilter, setSelectedFilter] = useState<
    SegmentValue | undefined
  >(undefined)
  const [filter, setFilter] = useState({
    from: DateTime.now().startOf('day').toISODate() ?? '',
    to: DateTime.now().endOf('day').toISODate() ?? '',
  })
  const setDateFilter = useCallback((value: SegmentValue) => {
    switch (value) {
      case 'today':
        setFilter({
          from: DateTime.now().startOf('day').toISODate() ?? '',
          to: DateTime.now().endOf('day').toISODate() ?? '',
        })
        break
      case 'week':
        setFilter({
          from:
            DateTime.now().minus({ day: 6 }).startOf('day').toISODate() ?? '',
          to: DateTime.now().endOf('day').toISODate() ?? '',
        })
        break
      case 'month':
        setFilter({
          from:
            DateTime.now().minus({ day: 30 }).startOf('day').toISODate() ?? '',
          to: DateTime.now().endOf('day').toISODate() ?? '',
        })
        break
      case 'all':
        setFilter({
          from:
            DateTime.fromObject({
              year: 2023,
              month: 1,
              day: 1,
            }).toISODate() ?? '',
          to: DateTime.now().endOf('day').toISODate() ?? '',
        })
    }
  }, [])
  const onChangeFilter = useCallback((event: SegmentCustomEvent): void => {
    setSelectedFilter(event.detail.value)
    setDateFilter(event.detail.value ?? '')
  }, [])
  const queryClient = useQueryClient()
  const invalidateQueries = useCallback(() => {
    void queryClient.invalidateQueries({
      queryKey: getReportControllerWorkVsBreakInsightQueryKey(
        filter as unknown as ReportControllerWorkVsBreakInsightPathParameters
      ),
    })
    void queryClient.invalidateQueries({
      queryKey: getReportControllerWorkLocationInsightQueryKey(
        filter as unknown as ReportControllerWorkVsBreakInsightPathParameters
      ),
    })
    void queryClient.invalidateQueries({
      queryKey: getReportControllerWorkProjectInsightQueryKey(
        filter as unknown as ReportControllerWorkVsBreakInsightPathParameters
      ),
    })
    void queryClient.invalidateQueries({
      queryKey: getReportControllerGetWorkHistoryQueryKey(
        filter as unknown as ReportControllerWorkVsBreakInsightPathParameters
      ),
    })
    void queryClient.invalidateQueries({
      queryKey: getReportControllerGetWorkHistoryQueryKey(
        filter as unknown as ReportControllerWorkVsBreakInsightPathParameters
      ),
    })
  }, [filter])
  useIonViewWillEnter(() => {
    setSelectedFilter('today')
    setDateFilter('today')
    invalidateQueries()
  })

  return (
    <IonPage className={'reports'}>
      <IonHeader>
        <IonToolbar>
          <IonTitle className={'ion-text-center'}>Reports</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList lines={'none'}>
          <IonItem>
            <h1 className={'ion-no-margin'}>Statistics</h1>
          </IonItem>
          <IonItem>
            <h2 className={'ion-no-margin'}>Work hours in detail</h2>
          </IonItem>
          <IonItem>
            <IonSegment value={selectedFilter} onIonChange={onChangeFilter}>
              <IonSegmentButton value={'today'}>Today</IonSegmentButton>
              <IonSegmentButton value={'week'}>7 Days</IonSegmentButton>
              <IonSegmentButton value={'month'}>30 Days</IonSegmentButton>
              <IonSegmentButton value={'all'}>All time</IonSegmentButton>
            </IonSegment>
          </IonItem>
          <IonItem>
            <TodayStatistics filter={filter} />
          </IonItem>
          <IonItem>
            <Swiper
              modules={[IonicSlides]}
              centeredSlides={true}
              style={{ width: '100%' }}
              className="ion-margin-bottom"
            >
              <SwiperSlide>
                <LocationDistribution filter={filter} />
              </SwiperSlide>
              <SwiperSlide>
                <ProjectDistribution filter={filter} />
              </SwiperSlide>
            </Swiper>
          </IonItem>
          <IonItem>
            <Swiper
              modules={[IonicSlides]}
              centeredSlides={true}
              style={{ width: '100%' }}
              className="ion-margin-bottom"
            >
              <SwiperSlide>
                <ClockInClockOutDistribution
                  filter={filter}
                  selectedMode="start-time"
                />
              </SwiperSlide>
              <SwiperSlide>
                <ClockInClockOutDistribution
                  filter={filter}
                  selectedMode="end-time"
                />
              </SwiperSlide>
            </Swiper>
          </IonItem>
        </IonList>
      </IonContent>
    </IonPage>
  )
}

export default Reports
